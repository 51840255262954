<template>
  <fieldset
    class="standard-fieldset"
    :class="{
      error: !!errorMessage,
      'no-padding': tight,
    }"
  >
    <legend :class="legendClasses">{{ legend }}</legend>
    <input-error
      v-if="errorMessage && showErrorMsg"
      :error-message="errorMessage"
      :show-icon="false"
    />
    <slot />
  </fieldset>
</template>

<script setup lang="ts">
/**
 * Used for radio and check lists, legend should be the "question" or "label" for the list.
 */
import InputError from './input-error.vue'
import { useFieldError } from 'vee-validate'

/** PROPS **/
const props = withDefaults(
  defineProps<{
    inputName: string // should match slotted input
    legend?: string
    legendClasses?: string
    tight?: boolean
    showErrorMsg?: boolean
  }>(),
  {
    legend: '',
    legendClasses: 'heading -small',
    tight: false,
    showErrorMsg: true,
  },
)
/** STATE **/
const errorMessage = useFieldError(() => props.inputName)
</script>

<template>
  <div
    class="form__group -has-checkbox"
    :class="{
      error: !!errorMessage,
      '-has-helper': hasHelper,
      '-has-remember-checkbox': hasRemember,
    }"
  >
    <input
      :id="inputId"
      :class="{
        large: large,
      }"
      type="radio"
      :name="name || inputId"
      :checked="checked"
      :value="checkedValue"
      v-bind="$attrs"
      v-on="validationListeners"
    />
    <label v-if="showLabel" :for="inputId">
      {{ label }}
      <slot name="label" />
    </label>
    <input-error v-if="errorMessage && showErrorMsg" :error-message="errorMessage" />
    <slot name="note" />
  </div>
</template>

<script setup lang="ts">
/**
 * Supports multiple radio buttons
 * - use with form-fieldset-slot and pass in vee-form errors
 * - inputId (must be unique per label)
 * - name (should be the sanem for all checkboxes in the group)
 * - set showErrorMsg to false (so fieldset can power error message)
 */
import { nextTick, computed } from 'vue'
import { useField } from 'vee-validate'
import InputError from '@/components/forms/input-error.vue'

/** OPTIONS **/
defineOptions({
  // allow $attrs to flow through to the input element
  inheritAttrs: false,
})

/** PROPS **/
const props = withDefaults(
  defineProps<{
    inputId: string
    name: string
    checkedValue: string | number | boolean
    modelValue: string | number | boolean | undefined
    // allow for v-model.number
    modelModifiers?: Record<string, (value: any) => any> | undefined
    large?: boolean
    hasHelper?: boolean
    hasRemember?: boolean
    label?: string
    showErrorMsg?: boolean
    showLabel?: boolean
    rules?: string
    // validation label
    validateAs?: string
  }>(),
  {
    modelModifiers: undefined,
    large: true,
    hasHelper: false,
    hasRemember: false,
    label: '',
    validateAs: '',
    showLabel: true,
    showErrorMsg: true,
    rules: '',
  },
)

/** EMITS **/
// defined, but let vee-validate handle it with syncVModel
const emit = defineEmits(['update:modelValue', 'change'])

/** COMPUTED **/
// couldn't pass props directly in, needed to be reactive
const reactiveRules = computed(() => props.rules)

/** STATE **/
const { checked, errorMessage, handleBlur, handleChange } = useField(
  () => props.name,
  reactiveRules,
  {
    type: 'radio',
    checkedValue: props.checkedValue,
    // allow vee-validate to sync with v-model
    syncVModel: true,
    // if do not allow syncVModel, must set initialValue
    // initialValue: props.modelValue,
    label: props.validateAs || props.label || 'Field',
    validateOnValueUpdate: false,
    validateOnMount: false,
  },
)

/** METHODS **/
const validationListeners = {
  // validate on blur
  blur: (e: Event) => {
    handleBlur(e, true)
  },
  // update value and validate on change
  change: (e: Event) => {
    handleChange(e, true)

    // change event to parent, but after validation
    nextTick(() => emit('change', e))
  },
}
</script>

export const CHILD_LOAD_AMOUNT = 5
export const PARENT_LOAD_AMOUNT = 10
export const HEADER_HEIGHT = 70
export const API_URL = '/api/v1/'

export const bp = {
  headerDefault: 1201,
  headerMedium: 1200,
  headerSmall: 1060,
  headerTablet: 1020,
  headerMobile: 767,
  large: 1110,
  medium: 940,
  small: 768,
  'x-small': 600,
  tiny: 340,
  maxListing: 1340,
  maxNarrow: 1140,
}

<template>
  <modal
    :heading="`Find Similar ${formattedEntityType}`"
    modal-class=""
    :show-footer="true"
    @close="handleCloseClick"
  >
    <p>
      Quickly find workouts similar to <strong>{{ entityTitle }}</strong
      >.
    </p>

    <div class="form-actions flex-justify-center">
      <div class="form-action">
        <a
          class="btn -link"
          :href="`${similarUrl}&time=shorter`"
          data-category="Find Similar"
          :data-action="entityId"
          data-label="shorter"
          @click.once="handleEventTrack"
          >Shorter</a
        >
      </div>
      <div class="form-action">
        <a
          class="btn -main"
          :href="`${similarUrl}&time=same`"
          data-category="Find Similar"
          :data-action="entityId"
          data-label="same"
          @click.once="handleEventTrack"
          >Similar</a
        >
      </div>
      <div class="form-action">
        <a
          class="btn -link"
          :href="`${similarUrl}&time=longer`"
          data-category="Find Similar"
          :data-action="entityId"
          data-label="longer"
          @click.once="handleEventTrack"
          >Longer</a
        >
      </div>
    </div>
  </modal>
</template>

<script>
import modal from './modal.vue'
import { trackLink } from '@/utils/analytics'

/**
 * Modal that shows CTAs for finding similar videos based on time.
 */
export default {
  name: 'ModalSimilar',
  components: { modal },
  props: {
    entityId: {
      required: true,
      type: Number,
    },
    entityType: {
      default: 'workout',
      type: String,
      validator: (val) => ['plan', 'workout', 'custom_workout'].includes(val),
    },
    entityTitle: {
      type: String,
      default: '',
    },
    showShorter: {
      type: Boolean,
      default: true,
    },
    showLonger: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],

  computed: {
    // modal title
    formattedEntityType() {
      let entityType = 'Workouts'

      if (this.entityType === 'custom_workout') {
        entityType = 'Custom Workouts'
      }

      if (this.entityType === 'plan') {
        entityType = 'Programs'
      }

      return entityType
    },

    similarUrl() {
      // TODO: other entity types, currently support just videos
      if (this.entityType === 'workout') {
        return `/videos/similar?similarto=${this.entityId}`
      }

      return ''
    },
  },
  methods: {
    handleCloseClick() {
      this.$emit('close')
    },

    // event tracking
    handleEventTrack(e) {
      trackLink(e)
    },
  },
}
</script>

import { bp } from '@/constants'
import Modernizr from '@/.modernizrrc'

export class Header {
  constructor() {
    // track if drop down nav or mobile nav is open
    this.isNavOpen = false
    this.mobileNavTimeout = null

    this.BODY = document.body || {}
    this.header = document.getElementById('header') || {}
    this.hamburger = document.getElementById('hamburger')
    this.searchInput = document.getElementById('searchInput')
    this.mobileNav = document.querySelector('#jsMobileNav')
    this.dropDownButtons = document.querySelectorAll('.dropdown-button')
    this.blurButtons = document.querySelectorAll('*[data-blur-hovers]')
    this.headerDistanceFromTop = this.header ? this.header.offsetTop : 0
    this.headerHeight = this.header ? this.header.offsetHeight : 0
    this.isHeaderFixed = false

    this.headerMediaQuery = window.matchMedia(`(max-width: ${bp.headerTablet}px)`)
  }

  // On tap ensure all other dropdown menus are removed

  removeHovers(el) {
    this.dropDownButtons.forEach(function (button) {
      if (button.parentElement !== el.parentElement) {
        button.parentElement.classList.remove('hover')
      }
    })
  }

  // Display dropdown on tap or click

  dropdownAction(el) {
    this.removeHovers(el)
    if (!el.parentElement.classList.contains('hover')) {
      this.isNavOpen = true

      // Hover not already active: display  it
      el.parentElement.classList.add('hover')
      if (el.parentElement.dataset.focus === 'search') {
        setTimeout(() => this.searchInput.focus(), 5)
      }
    } else {
      // Hover is already active: remove it and goto anchor link on 'second' tap where applicable (for pointerevents it's sufficient to use remoove preventDefault, for touchvents use window.location)
      if (Modernizr.pointerevents) {
        el.removeEventListener('click', this.preventDefault)
      } else if (Modernizr.touchevents) {
        if ('menulink' in el.dataset) {
          window.location.href = el.href
        }
      }
      if (el.parentElement.dataset.focus === 'search') {
        this.searchInput.blur()
      }
      el.parentElement.classList.remove('hover')
    }
  }

  // Remove dropdown on mouseout of clickable dropdown (search box)

  mouseOut(e) {
    if (!Modernizr.touchevents || Modernizr.pointerevents) {
      const el = e.currentTarget
      if (el.dataset.focus === 'search') {
        this.searchInput.blur()
      }
      el.classList.remove('hover')
      el.removeEventListener('mouseleave', this.mouseOut)
    }
  }

  // Prevent default on top level menulinks, for touch input they should only work on second tap

  preventDefault(e) {
    e.preventDefault()
  }

  /**
   * If clicking outside the nav DOM, close any open desktop or mobile nav
   *
   * older iOS was not listening to mousedown
   * @see https://github.com/airbnb/react-outside-click-handler/issues/11
   * @see https://stackoverflow.com/questions/13655919/how-to-bind-both-mousedown-and-touchstart-but-not-respond-to-both-android-jqu
   */
  handleClickOutside(e) {
    if (typeof window.ontouchstart != 'undefined' && e.type === 'mousedown') {
      return
    }

    // noop if nothing is open or not event target
    if (!this.isNavOpen || !e || !e.target) {
      return
    }

    // do nothing if clicked in header
    if (this.header && this.header.contains(e.target)) {
      return
    }

    // do nothing if in mobile nav
    if (this.mobileNav && this.mobileNav.contains(e.target)) {
      return
    }

    // assume outside click, close both sets of nav
    this.closeMobileNav()

    // remove main drop down hovers
    this.removeHovers(e)

    // remove my fitness menu
  }

  closeMobileNav() {
    if (!this.hamburger) {
      return
    }

    this.BODY.classList.remove('mobile-open')
    this.hamburger.classList.remove('-exed')
    this.hamburger.innerHTML =
      '<i class="material-symbols-outlined fill" data-icon="menu" aria-hidden="true"></i>'
    this.isNavOpen = false

    // clear any pending close timeout if one exists
    if (this.mobileNavTimeout) {
      clearTimeout(this.mobileNavTimeout)
    }

    // delay so animation runs
    this.mobileNavTimeout = setTimeout(() => {
      this.mobileNav.classList.remove('-open')
    }, 250)
  }

  // Implement collapsed site header on scroll

  handleSiteHeader() {
    if (window.pageYOffset - this.headerHeight >= this.headerDistanceFromTop) {
      if (this.isHeaderFixed) {
        return
      }
      this.BODY.classList.add('fixed-header')
      this.isHeaderFixed = true
    } else {
      if (!this.isHeaderFixed) {
        return
      }
      this.BODY.classList.remove('fixed-header')
      this.isHeaderFixed = false
    }
  }

  init() {
    // Apply event to each dropdown button

    this.dropDownButtons.forEach((el) => {
      // Bind keyboard event to enter key
      el.addEventListener('keydown', (e) => {
        if (e.keyCode == 13) {
          e.preventDefault()
          this.dropdownAction(el)
        }
      })

      if (el.dataset.event === 'hover') {
        if (Modernizr.pointerevents) {
          // Hoverable element / pointerevents supported: trigger dropdown action, bind preventDefault to remove later
          el.addEventListener('pointerdown', (e) => {
            e.preventDefault()
            this.dropdownAction(el)
          })
        } else {
          // Hoverable element, touchevents supported: trigger dropdown action, preventDefault on click will use window.location to navigate
          el.addEventListener('click', (e) => {
            e.preventDefault()
            this.dropdownAction(el)
          })
        }
      } else if (el.dataset.event === 'click') {
        // Dropdown element works on click as opposed to hover (specifically search)
        // Bind mouseleave to dismiss for users with mouse input
        this.mouseOut = this.mouseOut.bind(this)
        if (Modernizr.pointerevents) {
          el.addEventListener('pointerdown', (e) => {
            e.preventDefault()
            this.dropdownAction(el)
            if (e.pointerType !== 'touch') {
              el.parentElement.addEventListener('mouseleave', this.mouseOut)
            }
          })
        } else {
          el.addEventListener('click', (e) => {
            e.preventDefault()
            this.dropdownAction(el)
            el.parentElement.addEventListener('mouseleave', this.mouseOut)
          })
        }
      }
    })

    // Remove hovers when specific buttons gain focus

    this.blurButtons.forEach((el) => {
      el.addEventListener('focus', (e) => {
        this.removeHovers(el)
      })
    })

    // Toggle Hamburger menu
    if (this.hamburger) {
      this.hamburger.addEventListener('click', () => {
        this.BODY.classList.toggle('mobile-open')
        this.hamburger.classList.toggle('-exed')

        // replace inner html with close icon
        if (this.hamburger.classList.contains('-exed')) {
          // clear any pending close timeout
          if (this.mobileNavTimeout) {
            clearTimeout(this.mobileNavTimeout)
          }

          this.mobileNav.classList.add('-open')
          this.hamburger.innerHTML =
            '<i class="material-symbols-outlined fill" data-icon="close" aria-hidden="true"></i>'
          this.isNavOpen = true
        } else {
          this.hamburger.innerHTML =
            '<i class="material-symbols-outlined fill" data-icon="menu" aria-hidden="true"></i>'
          this.isNavOpen = false

          // delay so animation runs
          this.mobileNavTimeout = setTimeout(() => {
            this.mobileNav.classList.remove('-open')
          }, 250)
        }
      })
    }

    // Dismiss Hamburger menu if user switches between tablet/mobile view and desktop view
    this.headerMediaQuery.addListener(this.closeMobileNav.bind(this))

    // add outside click event listener to close desktop and mobile nav
    document.addEventListener('touchstart', this.handleClickOutside.bind(this))
    document.addEventListener('mousedown', this.handleClickOutside.bind(this))

    // On scroll determine when to display fixed collapsed nav
    // @TD Suggestion: Implement with Intersection observer and fallback to scroll for browsers that don't support

    this.handleSiteHeader = this.handleSiteHeader.bind(this)
    window.addEventListener('scroll', this.handleSiteHeader)
  }
}

<template>
  <span v-if="notificationUnreadCount" class="notification-count">{{
    notificationUnreadCount
  }}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
/**
 * Powers the unread notification count in User Nav dropdown
 *
 */
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'

export default defineComponent({
  name: 'NotificationCount',
  props: {},
  data() {
    return {}
  },

  computed: {
    ...mapState(useUserAuthStore, ['user']),

    notificationUnreadCount() {
      if (
        this.user &&
        this.user.notificationUnreadCount &&
        this.user.notificationUnreadCount >= 0
      ) {
        return this.user.notificationUnreadCount >= 99 ? '99+' : this.user.notificationUnreadCount
      }

      return ''
    },
  },
})
</script>

<template>
  <div class="content-group -center">
    <div class="group -flex -xwide page-toggle-container" :class="classes">
      <div class="flex flex-full flex-justify-center flex-align-items-center">
        <div class="flex flex-align-items-center" data-tour="plus-free-access-dashboard">
          <a class="toggle-text" :class="active === 'content' ? 'active' : ''" :href="contentUrl">{{
            contentTitle
          }}</a>

          <!-- switch -->
          <div
            class="page-toggle"
            role="button"
            tabindex="0"
            :aria-label="active === 'content' ? `View ${dashboardTitle}` : `View ${contentTitle}`"
            @click.prevent.once="toggleSwitch"
          >
            <span class="toggle-tab" :class="active === 'content' ? 'active' : ''">
              <i class="material-symbols-outlined" data-icon="subject"></i>
            </span>
            <span class="toggle-tab" :class="active === 'dashboard' ? 'active' : ''">
              <i class="material-symbols-outlined" data-icon="dashboard"></i>
            </span>
          </div>

          <!-- show link if on the "content" page, otherwise show SPA router link -->
          <a v-if="active === 'content'" class="toggle-text -new" :href="dashboardUrl">
            {{ dashboardTitle }}
          </a>
          <router-link
            v-else
            class="toggle-text -new"
            :class="active === 'dashboard' ? 'active' : ''"
            :to="dashboardUrl"
          >
            {{ dashboardTitle }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DashboardToggleBar',
  components: {},
  props: {
    classes: {
      type: String,
      default: '',
    },
    initActive: {
      type: String, // content | dashboard
      default: 'content',
    },
    contentUrl: {
      type: String,
      required: true,
    },
    contentTitle: {
      type: String,
      default: 'Details',
    },
    dashboardUrl: {
      type: String,
      required: true,
    },
    dashboardTitle: {
      type: String,
      default: 'Dashboard',
    },
  },

  // component state
  data() {
    return {
      active: this.initActive,
    }
  },

  // lifecycle methods

  // component methods
  methods: {
    toggleSwitch() {
      if (this.active === 'dashboard') {
        return (window.location.href = this.contentUrl)
      }

      return (window.location.href = this.dashboardUrl)
    },
  },
})
</script>

<template>
  <modal heading="Sign In" modal-class="-login" body-class="login-box" @close="$emit('close')">
    <div class="login-block">
      <h2 class="heading -x-small light">
        You must be a member to access all of the benefits of Fitness Blender.
      </h2>
      <a :href="`/login?from=${currentPath}`" class="btn -main -block">Sign In</a>
    </div>

    <div class="login-divider">
      <span class="small-caps light">or</span>
    </div>
    <div class="login-block">
      <h2 class="heading -x-small light">Not a member yet? Join for free!</h2>
      <a :href="`/join?from=${currentPath}`" class="btn -main -block">Join</a>
    </div>
  </modal>
</template>

<script>
import { defineComponent } from 'vue'
import Modal from './modal.vue'
export default defineComponent({
  name: 'ModalLogin',
  components: { Modal },
  emits: ['close'],
  computed: {
    currentPath() {
      return this.$route.path
    },
  },
})
</script>

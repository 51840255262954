<template>
  <div v-show="hasLikeReact" class="comment-reactions">
    <div
      v-for="emoteStats in likeEmoteStats"
      :key="emoteStats.reactTypeId"
      :class="{
        reaction: emoteStats.total > 0,
        'reaction-selected': selectedReactTypeId === emoteStats.reactTypeId,
      }"
    >
      <span v-if="emoteStats.total > 0" class="reaction-emoji">{{
        emoteStats.reactTypeEmoji
      }}</span>
      <span v-if="emoteStats.total > 0" class="reaction-count">{{ emoteStats.total }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { LikeEmoteStat } from '@/types/comment-types'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'LikeReactStats',
  props: {
    selectedReactTypeId: {
      type: Number,
      default: null,
    },
    likeEmoteStats: {
      type: Object as PropType<Record<number, LikeEmoteStat>>,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    hasLikeReact() {
      return Object.values(this.likeEmoteStats).some((emoteStats: any) => {
        return emoteStats.total > 0
      })
    },
  },
  methods: {},
})
</script>

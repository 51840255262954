<template>
  <div>
    <div :class="modalClass" class="modal" role="dialog" aria-modal="true" tabindex="-1">
      <header v-if="showHeader" class="modal__header">
        <h3 class="heading -medium">
          {{ heading }}
        </h3>
        <p v-if="subheading">
          {{ subheading }}
        </p>
        <div class="close-container">
          <icon-button
            icon="close"
            classes="-ico-only -no-container"
            label="Close"
            @click-action="emitClose"
          />
        </div>
      </header>

      <div :class="bodyClass">
        <slot />
      </div>

      <slot name="footer">
        <footer v-if="showFooter" class="modal__footer">
          <button @click.prevent="emitClose">
            {{ buttonText }}
          </button>
        </footer>
      </slot>
    </div>
    <div class="overlay" @click.prevent="emitClose" />
  </div>
</template>

<script>
/*
 * Modal sub types:
 * calendar - shown scheduling workouts and plans
 * confirm - on scheduled instances of plans, start over on reschedule, notification delete all
 * error - 500 error from ajax api calls
 * flag - on comments and notifications
 * login - shown on comments, trying to favorite or add to calendar
 * custom workout complete - for custom workout "completes" that have user specified minutes
 */
import { defineComponent } from 'vue'
import IconButton from '@/components/buttons/icon-button.vue'

export default defineComponent({
  components: { IconButton },
  props: {
    modalClass: {
      default: '',
      type: String,
    },
    heading: {
      default: 'Modal Heading',
      type: String,
    },
    subheading: {
      default: null,
      type: String,
    },
    bodyClass: {
      default: 'modal__body',
      type: String,
    },
    showHeader: {
      default: true,
      type: Boolean,
    },
    showFooter: {
      default: false,
      type: Boolean,
    },
    buttonText: {
      type: String,
      default: 'Close Window',
    },
  },
  emits: ['close'],

  mounted() {
    this.$nextTick(() => {
      // disable background scrolling
      if (document) {
        document.querySelector('body').classList.add('no-scroll')
      }
    })
  },

  beforeUnmount() {
    // undo and allow scrolling
    if (document) {
      document.querySelector('body').classList.remove('no-scroll')
    }
  },

  methods: {
    emitClose() {
      this.$emit('close')
    },
  },
})
</script>

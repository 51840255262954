<template>
  <div class="modals">
    <modal-login v-if="modalsStore.loginModal.active" @close="hideLoginModal" />
    <modal-error
      v-if="modalsStore.errorModal.active"
      :type="modalsStore.errorModal.type"
      @close="hideErrorModal"
    />

    <modal
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'STANDARD'"
      :id="modalsStore.simpleModal.id"
      :heading="modalsStore.simpleModal.props.heading"
      :subheading="modalsStore.simpleModal.props.subheading"
      :modal-class="modalsStore.simpleModal.props.modalClass || '-confirm-remove'"
      :show-footer="modalsStore.simpleModal.props.showFooter"
      @close="closeSimpleModal"
    >
      <p v-if="modalsStore.simpleModal.props.bodySlot" class="light">
        {{ modalsStore.simpleModal.props.bodySlot }}
      </p>
    </modal>

    <modal-confirm
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'CONFIRM'"
      :id="modalsStore.simpleModal.id"
      :heading="modalsStore.simpleModal.props.heading"
      :subheading="modalsStore.simpleModal.props.subheading"
      :button-text="modalsStore.simpleModal.props.buttonText"
      :cancel-text="modalsStore.simpleModal.props.cancelText"
      :url="modalsStore.simpleModal.props.url"
      @confirm="closeSimpleModalWithAction('confirm')"
      @close="closeSimpleModalWithAction('close')"
    />

    <modal-calendar
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'CALENDAR_ADD'"
      :id="modalsStore.simpleModal.id"
      :entity-id="modalsStore.simpleModal.props.entityId"
      :entity-type="modalsStore.simpleModal.props.entityType"
      :entity-title="modalsStore.simpleModal.props.entityTitle"
      :entity-category="modalsStore.simpleModal.props.entityCategory"
      @schedule-success="calendarStore.setRefreshScheduledInstances(true)"
      @close="closeSimpleModal"
    />

    <modal-routine
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'ROUTINE_ADD'"
      :id="modalsStore.simpleModal.id"
      :entity-id="modalsStore.simpleModal.props.entityId"
      :entity-type="modalsStore.simpleModal.props.entityType"
      :entity-title="modalsStore.simpleModal.props.entityTitle"
      @close="closeSimpleModal"
    />

    <modal-flag
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'FLAG'"
      :id="modalsStore.simpleModal.id"
      :flaggable-id="modalsStore.simpleModal.props.flaggableId"
      :flaggable-type="modalsStore.simpleModal.props.flaggableType"
      @close="closeFlagModal"
      @flag-updated="setSimpleModalAction('flagged')"
    />

    <modal-similar
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'SIMILAR'"
      :id="modalsStore.simpleModal.id"
      :entity-id="modalsStore.simpleModal.props.entityId"
      :entity-type="modalsStore.simpleModal.props.entityType"
      :entity-title="modalsStore.simpleModal.props.entityTitle"
      @close="closeSimpleModal"
    />

    <modal-tags
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'TAGS'"
      :id="modalsStore.simpleModal.id"
      :entity-id="modalsStore.simpleModal.props.entityId"
      :entity-type="modalsStore.simpleModal.props.entityType"
      :entity-title="modalsStore.simpleModal.props.entityTitle"
      :entity-category="modalsStore.simpleModal.props.entityCategory"
      @close="closeSimpleModal"
    />

    <!-- modal powered by plan-card-add-to-cart-button and plan-detail-add-to-cart-button emit -->
    <modal-plan-purchase
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'PLAN_PURCHASE'"
      :id="modalsStore.simpleModal.id"
      :plan="modalsStore.simpleModal.props.plan"
      :plus-pass="modalsStore.simpleModal.props.plusPass"
      :image-url-prefix="modalsStore.simpleModal.props.imageUrlPrefix"
      @close="closeSimpleModal"
    />

    <modal-plus-pass-purchase
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'PASS_PURCHASE'"
      :id="modalsStore.simpleModal.id"
      :plus-pass="modalsStore.simpleModal.props.plusPass"
      :cheapest-monthly-price="modalsStore.simpleModal.props.cheapestMonthlyPrice"
      :image-url-prefix="modalsStore.simpleModal.props.imageUrlPrefix"
      :image="modalsStore.simpleModal.props.image"
      :entity-id="modalsStore.simpleModal.props.entityId"
      :entity-type="modalsStore.simpleModal.props.entityType"
      :entity-category="modalsStore.simpleModal.props.entityCategory"
      @close="closeSimpleModal"
    />

    <modal-plan-copy
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'PLAN_COPY'"
      :id="modalsStore.simpleModal.id"
      :plan-id="modalsStore.simpleModal.props.planId"
      :grouping-id="modalsStore.simpleModal.props.groupingId"
      :plan-short-name="modalsStore.simpleModal.props.planShortName"
      :copy-type="modalsStore.simpleModal.props.copyType"
      @close="closeSimpleModal"
    />

    <modal-stat-widget-add
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'STAT_WIDGET_ADD'"
      :id="modalsStore.simpleModal.id"
      :stat-widgets="modalsStore.simpleModal.props.statWidgets"
      @add="closeSimpleModalWithAction('add', $event)"
      @close="closeSimpleModal"
    />

    <modal-filter-collection
      v-if="modalsStore.simpleModal.active && modalsStore.simpleModal.type === 'FILTER_COLLECTION'"
      :id="modalsStore.simpleModal.id"
      :screen="modalsStore.simpleModal.props.screen ?? 'form'"
      :filter-collection="modalsStore.simpleModal.props.filterCollection"
      @update="setSimpleModalAction('update', $event)"
      @copy="setSimpleModalAction('copy', $event)"
      @close="closeSimpleModal"
    />

    <!-- powered by separate pinia modal -->
    <complete-sliders v-if="userAuthStore.user.isLoggedIn" />

    <!--  guided tours -->
    <guided-tours v-if="userAuthStore.user.isLoggedIn" />
  </div>
</template>

<script setup>
import { useUserAuthStore } from '@/stores/user-auth'
import { useModalsStore } from '@/stores/modals'
import { useCalendarStore } from '@/stores/calendar'
import ModalError from '@/components/modals/modal-error.vue'
import ModalLogin from '@/components/modals/modal-login.vue'
import ModalCalendar from '@/components/modals/modal-calendar.vue'
import ModalRoutine from '@/components/modals/modal-routine.vue'
import Modal from '@/components/modals/modal.vue'
import ModalConfirm from '@/components/modals/modal-confirm.vue'
import ModalFlag from '@/components/modals/modal-flag.vue'
import ModalSimilar from '@/components/modals/modal-similar.vue'
import ModalTags from '@/components/modals/modal-tags.vue'
import CompleteSliders from '@/components/modals/sliders/complete-sliders.vue'
import ModalPlanPurchase from '@/components/modals/modal-plan-purchase.vue'
import ModalPlusPassPurchase from '@/components/modals/modal-plus-pass-purchase.vue'
import ModalPlanCopy from '@/components/modals/modal-plan-copy.vue'
import GuidedTours from '@/components/tours/guided-tours.vue'
import ModalStatWidgetAdd from '@/components/modals/modal-stat-widget-add.vue'
import ModalFilterCollection from '@/components/modals/modal-filter-collection.vue'

/** OPTIONS **/
// app name for dev tools
defineOptions({
  name: 'HeaderModalsApp',
})

/** PROPS **/

/** STATE **/
const userAuthStore = useUserAuthStore()
const calendarStore = useCalendarStore()
const modalsStore = useModalsStore()

// destructure methods only, they are bound to store
const {
  hideErrorModal,
  hideLoginModal,
  closeSimpleModal,
  closeSimpleModalWithAction,
  setSimpleModalAction,
} = modalsStore

/** METHODS **/
function closeFlagModal() {
  if (modalsStore.simpleModal.action) {
    closeSimpleModalWithAction(modalsStore.simpleModal.action)
    return
  }

  closeSimpleModal()
}
</script>

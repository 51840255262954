<template>
  <div
    class="day"
    :class="{ [activeClass]: isActive }"
    role="button"
    tabindex="0"
    :aria-label="`${isActive ? 'Deselect' : 'Select'} week ${weekNumber} day ${weekDayNumber}`"
    @click.prevent="toggleSelected"
    @keyup.enter="toggleSelected"
  >
    <div class="day-number">
      {{ weekDayNumber }}
    </div>
    <div v-if="stat" class="day-info">
      <div class="workout-count">
        {{ stat }}
      </div>
    </div>
    <div v-else class="day-info -none"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * Day box / grid item for use within routine schedule / modal views. Represents
 * a single day.
 *
 * - Parent component should handle @toggle-selected
 * - Parent component should handle isActive management
 */
export default defineComponent({
  name: 'DayGridButton',
  components: {},
  props: {
    activeClass: {
      type: String,
      default: '-selected',
    },
    weekNumber: {
      type: Number,
      required: true,
    },
    // 1 - 7
    weekDayNumber: {
      type: Number,
      required: true,
    },
    // 1 - last day of plan. EX: 1-28 for 4 week plan
    dayNumber: {
      type: Number,
      required: true,
    },
    stat: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle-selected'],

  computed: {},

  methods: {
    // pass up to parent
    toggleSelected() {
      this.$emit('toggle-selected', {
        weekNumber: this.weekNumber,
        weekDayNumber: this.weekDayNumber,
        dayNumber: this.dayNumber,
      })
    },
  },
})
</script>

<template>
  <button
    :disabled="submitting || disabled ? true : undefined"
    :type="type"
    class="btn -std -tag"
    :class="classes"
    @click.prevent="handleClick"
  >
    <span class="btn-txt">{{ label }}</span>
    <i v-show="icon" class="material-symbols-outlined btn-ico" aria-hidden="true">{{ icon }}</i>
  </button>
</template>

<script setup lang="ts">
/** PROPS **/
withDefaults(
  defineProps<{
    submitting?: boolean
    disabled?: boolean
    label?: string
    type?: 'button' | 'submit' | 'reset' | undefined
    submittingLabel?: string
    icon?: string
    classes?: string
  }>(),
  {
    submitting: false,
    disabled: false,
    label: '',
    type: 'button',
    submittingLabel: 'Saving...',
    icon: 'close',
    classes: '',
  },
)

/** EMITS **/
const emit = defineEmits(['click-action'])

/** METHODS **/
function handleClick(event: Event) {
  emit('click-action', event)
}
</script>

import { defineStore } from 'pinia'
import type { ShelvesState, ShelfCard, ShevlableAction, ShelfItems } from '@/types/shelf-types'

export const useShelvesStore = defineStore('shelves', {
  state: (): ShelvesState => ({
    // the loaded / focused shelf (items)
    shelfCards: [],

    // used for cards and and simple key value map
    shelfItems: {},
  }),

  getters: {
    // check if single item is on shelf
    isOnShelf:
      (state: ShelvesState) =>
      ({ shelvableType, shelvableId }: ShevlableAction) => {
        const key = `${shelvableType}_${shelvableId}`
        return !!state.shelfItems[key]
      },
  },

  actions: {
    setShelfCards(shelf: Array<ShelfCard>) {
      this.shelfCards = shelf
    },

    setShelfItems(shelfItems: ShelfItems) {
      this.shelfItems = shelfItems
    },

    addShelfItem({ shelvableType, shelvableId }: ShevlableAction) {
      const key = `${shelvableType}_${shelvableId}`

      this.shelfItems = {
        ...this.shelfItems,
        [key]: true,
      }
    },

    // remove item from shelf by type and id
    removeShelfItem({ shelvableType, shelvableId }: ShevlableAction) {
      // remove from shelf
      this.shelfCards = this.shelfCards.filter((item) => {
        return !(item.shelvable_type === shelvableType && item.shelvable_id === shelvableId)
      })

      // remove from card icons map
      const key = `${shelvableType}_${shelvableId}`
      this.shelfItems[key] = false
    },
  },
})

<template>
  <icon-button
    :type="type"
    :show-icon="showIcon"
    :icon="icon"
    :classes="classes"
    :icons-outlined="iconsOutlined"
    :label="label"
    :submitting="submitting"
    :submitting-label="submittingLabel"
    :disabled="disabled"
    aria-live="polite"
    @click-action="openConfirmModal"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useModalsStore } from '@/stores/modals'
import IconButton from '@/components/buttons/icon-button.vue'

export default defineComponent({
  name: 'DeleteButton',
  components: { IconButton },
  props: {
    submitting: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'button' | 'submit' | 'reset'>,
      default: 'button',
    },
    submittingLabel: {
      type: String,
      default: 'Deleting...',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'delete',
    },
    classes: {
      type: String,
      default: '',
    },

    // default to outlined font, see materialFontClasses for computed toggle
    iconsOutlined: {
      type: Boolean,
      default: true,
    },

    // modal specific props
    modalId: {
      type: String,
      default: 'simple-modal',
    },
    showModal: {
      type: Boolean,
      default: true,
    },
    modalHeading: {
      type: String,
      default: 'Delete Item?',
    },
    modalText: {
      type: String,
      default: 'Are you sure you want to delete this item? This cannot be undone.',
    },
    modalButtonText: {
      type: String,
      default: 'Delete',
    },
  },
  emits: ['delete-action'],

  //  state
  data() {
    return {}
  },

  computed: {
    ...mapState(useModalsStore, ['simpleModal']),
  },

  watch: {
    'simpleModal.action'(newAction: string, prevAction: string) {
      if (
        this.simpleModal.id === this.modalId &&
        newAction !== prevAction &&
        newAction === 'confirm'
      ) {
        this.$emit('delete-action')
      }
    },
  },

  // instance methods
  methods: {
    ...mapActions(useModalsStore, ['openSimpleModal']),

    openConfirmModal() {
      this.openSimpleModal({
        type: 'CONFIRM',
        id: this.modalId,
        props: {
          heading: this.modalHeading,
          subheading: this.modalText,
          buttonText: this.modalButtonText,
          cancelText: 'Cancel',
        },
      })
    },
  },
})
</script>

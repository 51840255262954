<template>
  <div class="complete-customization">
    <!-- error state -->
    <callout v-if="error" type="error">
      <div>{{ error }}</div>
    </callout>

    <h3 id="notes-header" class="heading">
      Add Note

      <icon-button
        icon="arrow_back_ios"
        classes="-ico-only -no-container help"
        label="Return to Menu"
        @click-action="$emit('close-action')"
      />
    </h3>
    <div class="extras-form">
      <note-form
        :notable-type="entityType"
        :notable-id="entityId"
        :note="note"
        :parent-component="'schedulable-complete-slider'"
        @note-cancel-action="$emit('close-action', $event)"
        @note-saved-action="$emit('saved-action', $event)"
        @note-error-action="setError"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { useNotesStore } from '@/stores/notes'
import Callout from '@/components/notifications/callout.vue'
import IconButton from '@/components/buttons/icon-button.vue'
import NoteForm from '@/components/notes/note-form.vue'

/**
 * Container component for Note-Form component for the Schedulable Complete Slider
 */
export default defineComponent({
  name: 'SliderNoteForm',

  components: {
    Callout,
    IconButton,
    NoteForm,
  },
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    entityType: {
      default: 'workout',
      type: String,
      validator: (val: string) =>
        ['workout', 'custom_workout', 'article', 'wellness_video'].includes(val),
    },
    entityCategory: {
      default: '',
      validator: (val: string) => ['', 'recipe'].includes(val),
    },

    // used for edit
    note: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close-action', 'saved-action'],

  // component data
  data() {
    return {
      error: '' as string | Error,
    }
  },

  computed: {
    ...mapState(useUserAuthStore, ['features']),
    ...mapState(useNotesStore, ['isExpanded']),
  },

  // component methods
  methods: {
    ...mapActions(useNotesStore, ['addNote', 'editNote', 'deleteNote']),

    setError(err: Error) {
      this.error = err
    },
  },
})
</script>

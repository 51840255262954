import _debounce from 'lodash/debounce'
import { useUserAuthStore } from '@/stores/user-auth'
import type { DeviceObj } from '@/types/user-auth-types'

/**
 * @see https://www.smashingmagazine.com/2022/03/guide-hover-pointer-media-queries/
 */
export const pointerType = (): DeviceObj['pointerType'] => {
  // touch, smart tv, game consoles
  if (typeof window !== 'undefined') {
    if (window.matchMedia('(pointer:coarse)').matches) {
      return 'coarse'
    }

    // mouse, trackpads, desktops
    if (window.matchMedia('(pointer:fine)').matches) {
      return 'fine'
    }
  }

  return ''
}

/**
 * This is mostly for DX, but if device resizes, check the pointer type again
 * and update vue store.
 *
 * Helps when switching to responsive vs desktop in chrome debug.
 */
export const resizePointerType = () => {
  if (typeof window === 'undefined') {
    return
  }

  const userAuthStore = useUserAuthStore()

  const resizer = _debounce(function () {
    if (!window || !userAuthStore) {
      return
    }

    userAuthStore.updateDevice({
      pointerType: pointerType(),
    })
  }, 150)

  window.addEventListener('resize', resizer)
}

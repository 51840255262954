import { defineAsyncComponent } from 'vue'
import type { App } from 'vue'

/**
 * Require and code split / lazy load buttons / action components
 */
const miscCodeSplit = (app: App) => {
  /* DETAIL VIEWS */
  app.component(
    'SocialShareButtons',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "misc-ctas" */ '@/components/buttons/social-share-buttons.vue'),
    ),
  )

  app.component(
    'ContextualHelp',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "misc-ctas" */ '@/components/tool-tips/contextual-help.vue'),
    ),
  )

  app.component(
    'VideoExpandableCallout',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "misc-ctas" */ '@/components/videos/video-expandable-callout.vue'
        ),
    ),
  )

  app.component(
    'YoutubeEmbed',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '@/components/videos/youtube-embed.vue'),
    ),
  )

  app.component(
    'FixedFooterCta',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "misc-ctas" */ '../components/buttons/fixed-footer-cta.vue'),
    ),
  )

  app.component(
    'ContentActionsButton',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "misc-ctas" */ '../components/buttons/content-actions-button.vue'
        ),
    ),
  )

  app.component(
    'CalendarButton',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '../components/buttons/calendar-button.vue'),
    ),
  )

  app.component(
    'DifficultyRating',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "misc-ctas" */ '../components/videos/difficulty-rating.vue'),
    ),
  )

  app.component(
    'BurnEstimate',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '../components/videos/burn-estimate.vue'),
    ),
  )

  app.component(
    'StatsCommunity',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '../components/cards/stats-community.vue'),
    ),
  )

  app.component(
    'StatsMember',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '../components/cards/stats-member.vue'),
    ),
  )

  app.component(
    'Unit',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '@/components/units/unit.vue'),
    ),
  )

  app.component(
    'CardGridUnit',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '@/components/units/card-grid-unit.vue'),
    ),
  )

  /* CONTENT SECTIONS */
  app.component(
    'ScrollChecklist',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "misc-ctas" */ '@/components/content-sections/scroll-checklist.vue'
        ),
    ),
  )

  app.component(
    'IngredientList',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "misc-ctas" */ '../components/content-sections/ingredient-list.vue'
        ),
    ),
  )

  /* PLANS */
  app.component(
    'PlansScheduledInstances',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "misc-ctas" */ '../components/plans/plans-scheduled-instances.vue'
        ),
    ),
  )

  app.component(
    'ProgramActions',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '../components/buttons/program-actions.vue'),
    ),
  )

  app.component(
    'PlanCardAddToCartButton',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "misc-ctas" */ '../components/plans/plan-card-add-to-cart-button.vue'
        ),
    ),
  )

  app.component(
    'AddToCartButton',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '../components/cart/add-to-cart-button.vue'),
    ),
  )

  app.component(
    'PlanDetailAddToCartButton',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "misc-ctas" */ '../components/plans/plan-detail-add-to-cart-button.vue'
        ),
    ),
  )

  /* CALENDAR */
  app.component(
    'ScheduledInstanceActions',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "misc-ctas" */ '../components/buttons/scheduled-instance-actions.vue'
        ),
    ),
  )

  app.component(
    'DetailCompleteButton',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "misc-ctas" */ '../components/buttons/completes/detail-complete-button.vue'
        ),
    ),
  )

  /* TOURS */
  app.component(
    'TourCard',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '../components/tours/tour-card.vue'),
    ),
  )

  /* CONFETTI */
  app.component(
    'Confetti',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "misc-ctas" */ '../components/confetti/confetti.vue'),
    ),
  )
}

export default miscCodeSplit

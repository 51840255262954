<template>
  <div class="complete-customization">
    <h2 class="heading">Quick Stats</h2>
    <div class="extras-stats-list">
      <ul class="stats-list">
        <li class="stat-item">
          <span class="stat-title">Number of times completed</span>
          <span class="stat-result">{{ stats.programCompletes }}</span>
        </li>
        <li class="stat-item">
          <span class="stat-title">Total activities completed</span>
          <span class="stat-result">{{ stats.scheduleCompletes.totalCount }}</span>
        </li>
        <!-- workout plan stats -->
        <li v-if="plan.isWorkoutPlan" class="stat-item">
          <span class="stat-title">Total minutes</span>
          <span class="stat-result">
            {{ stats.minutes.totalMinutes }}
          </span>
        </li>
        <li v-if="plan.isWorkoutPlan" class="stat-item">
          <span class="stat-title">
            <contextual-help
              id="avgDifficulty"
              title="Average difficulty / minute"
              classes="-flush"
            >
              <p>
                Average is based on the number of minutes completed at each workout difficulty
                level.
              </p>
            </contextual-help>
          </span>
          <span class="stat-result">{{ stats.avgDifficulty }}</span>
        </li>
        <li v-if="plan.isWorkoutPlan" class="stat-item">
          <span class="stat-title">
            <contextual-help id="avgMinutes" title="Average minutes / day" classes="-flush">
              <p>
                Average is based on workouts completed within <strong>active days</strong>, meaning
                the day is only counted if a workout was completed.
              </p>
            </contextual-help>
          </span>
          <span class="stat-result">
            {{ stats.minutes.avgMinutesPerActiveDay }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import ContextualHelp from '@/components/tool-tips/contextual-help.vue'
import type { ProgramCompletePlan, ProgramCompleteStats } from '@/types/calendar-types'

/**
 * Read only stats for program complete slider.
 */
export default defineComponent({
  name: 'ProgramStats',
  components: { ContextualHelp },
  props: {
    plan: {
      type: Object as PropType<ProgramCompletePlan>,
      required: true,
    },
    stats: {
      type: Object as PropType<ProgramCompleteStats>,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(useUserAuthStore, ['cdn']),

    imageUrlPrefix() {
      // get siteassets url from page load, allows CDN url to be added
      const siteAssetsPrefix = this.cdn.siteAssetsPrefix || '/siteassets'
      return `${siteAssetsPrefix}/${this.plan.imgDir}`
    },
  },
})
</script>

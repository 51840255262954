// polyfills
// .babelrc "useBuiltIns": "usage" looks in code to find ones that are needed
// but we still are getting issues so we need to manually add a couple

// import 'core-js/features/array/from'
// import 'core-js/features/array/includes'
// import 'core-js/features/object/assign'
import 'core-js/features/dom-collections/iterator'
// import 'core-js/stable/promise'
import 'core-js/modules/es.array.iterator'
// import 'core-js/features/number'
// import 'core-js/features/string/includes'
// import 'core-js/features/symbol'
import 'core-js/features/symbol/async-iterator'
import 'core-js/web/dom-collections'

// needed for ky fetch
import 'core-js/stable/global-this'

// ky fetch also uses new globalThis.ReadableStream() which breaks edge18
// with "Function expected". This is a workaround as edge has it defined, but
// doesn't work.
try {
  new window.ReadableStream()
} catch (e) {
  window.ReadableStream = undefined
}

// polyfills option #2: add all IE 11 polyfills
// in .bablerc set "useBuiltIns": "entry" and uncomment next line
// import 'core-js/stable'

<template>
  <div class="week">
    <div class="schedule-title">Week {{ weekNumber }}</div>
    <div class="schedule-actions">
      <div class="action-button">
        <!-- TODO: hook up for list view -->
        <i v-if="expandable" class="material-symbols-outlined fill" aria-hidden="true"
          >expand_less</i
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * Header row for week with optional expand / collapse actions
 */
export default defineComponent({
  name: 'WeekRowHeader',
  components: {},
  props: {
    weekNumber: {
      type: Number,
      required: true,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

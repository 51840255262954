export const fbAnnouncement = () => {
  const closeAnnouncement = document.getElementById('closeAnnouncement')
  const announcementBar = document.getElementById('announcement')
  const BODY = document.body || {}

  // TD Pass Cookie to user
  const announcementBarCookie = function(cookieName) {
    const date = new Date()

    // Get unix millisecond for 14 days
    date.setTime(+date + 14 * 60 * 60 * 24 * 1000)

    document.cookie = cookieName + '=y; expires=' + date.toGMTString() + ';path=/;SameSite=Lax'
  }
  closeAnnouncement.addEventListener('click', () => {
    announcementBar.classList.add('announcement-hidden')
    announcementBarCookie(announcementBar.dataset.cookie)
    BODY.classList.remove('announcement_body')
  })
}

<template>
  <modal
    :show-header="false"
    :show-footer="showFixedFooterButton"
    modal-class="-calendar"
    body-class=""
    @close="handleCloseClick"
  >
    <div class="mini-calendar">
      <button class="close-mini-calendar" @click="handleCloseClick">
        <span class="iconfont-menu-close" />
      </button>

      <!-- init loading message -->
      <div v-if="initLoading" class="calendar-holder">
        <h2 class="heading -medium">Loading Routines</h2>
        <skeleton-plan-schedule-loader :plan-weeks="2" />
      </div>

      <!-- error state -->
      <div v-if="error" class="calendar-holder">
        <h2 class="heading -medium">Uh oh, something went wrong</h2>
        <callout type="error">
          <div>{{ error }}</div>
        </callout>

        <div class="modal__body">
          <p>There was a problem handling the request. Please close the window and try again.</p>
        </div>
      </div>

      <!-- no routines message / guide -->
      <div v-if="screen === 'intro'" class="calendar-holder">
        <header class="modal__header">
          <h2 class="heading -medium">Welcome to Routines!</h2>
        </header>

        <div class="modal__body">
          <p>
            It looks like you have not created a routine yet. Ever wanted to create your own
            challenge or program? That is exactly what FB Plus Routines is all about.
          </p>
          <p>
            To get started, simply create a routine, find and add workouts (psst. it is the button
            you just clicked), then save and publish!
          </p>

          <ul class="modal__link-list">
            <li>
              <a href="/my/routines/create" @click="handleCloseClick">Create your first routine</a>
            </li>
            <li>
              <a href="/my/routines" @click="handleCloseClick">View "My Routines"</a>
            </li>
            <li>
              <a href="/page/routines" @click="handleCloseClick">Learn more about routines?</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- success message screen -->
      <div v-if="screen === 'success'" class="calendar-holder">
        <header class="modal__header">
          <h2 class="heading -medium">Successfully Updated Routine!</h2>
        </header>

        <div class="modal__body">
          <p v-if="hasSelectedPlanDays">
            You have added <strong>{{ entityTitle }}</strong> to {{ selectedPlanDaysTotal }} day(s)
            on ths routine.
          </p>
          <p v-else>
            You have removed <strong>{{ entityTitle }}</strong> to your routine.
          </p>

          <ul class="modal__link-list">
            <li>
              <button @click.prevent="scheduleAgain">
                Add this {{ humanEntityType }} to another routine
              </button>
            </li>
            <li>
              <a :href="`/my/routines/${selectedRoutineId}/edit-schedule`" @click="handleCloseClick"
                >Edit this routine</a
              >
            </li>
            <li>
              <a href="/my/routines" @click="handleCloseClick">View "My Routines"</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- modal schedule body -->
      <div v-if="screen === 'schedule'" class="calendar-holder">
        <h2 class="heading -medium">Add to Routine</h2>

        <div class="schedule-view -modal">
          <div class="form__group">
            <div class="select-style">
              <select
                name="schedule_view"
                aria-label="Select draft routine"
                @change="selectRoutine"
              >
                <option v-for="routine in routines" :key="routine.id" :value="routine.id">
                  {{ routine.title }}
                </option>
              </select>
            </div>
          </div>

          <!-- inline loading -->
          <skeleton-plan-schedule-loader v-if="loading" :plan-weeks="2" />

          <div v-if="!loading" class="schedule-grid">
            <template v-for="planWeek in planWeeks" :key="`week-${planWeek.week}`">
              <!-- week header -->
              <week-row-header :week-number="planWeek.week" />

              <!-- week days -->
              <div class="week-group">
                <day-grid-button
                  v-for="day in planWeek.days"
                  :key="day.weekDay"
                  :week-number="planWeek.week"
                  :week-day-number="day.weekDay"
                  :day-number="day.day"
                  :stat="day.planDaySchedule.length"
                  :is-active="isSelected(day.day)"
                  @toggle-selected="toggleSection"
                />
              </div>
            </template>
          </div>

          <div v-if="!loading" class="form-actions">
            <div class="form-action">
              <submit-button
                :submitting="saving"
                :label="`Update Routine`"
                submitting-label="Saving…"
                classes="-main -large-medium-only"
                @submit-action="savePlanDays(selectedRoutineId)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { defineComponent } from 'vue'
import { fbApi } from '@/utils/http-api'
import Modal from './modal.vue'
import Callout from '@/components/notifications/callout.vue'
import SubmitButton from '@/components/buttons/submit-button.vue'
import WeekRowHeader from '@/components/plans/plan-schedule/week-row-header.vue'
import DayGridButton from '@/components/plans/plan-schedule/day-grid-button.vue'
import SkeletonPlanScheduleLoader from '@/components/loaders/skeleton-plan-schedule-loader.vue'

/**
 * Modal handles adding plan day schedule to a plan (routine).
 * - select draft routine to add schedule too
 * - once selected, click week day boxes (plan days)
 * - submit to save the plan day schedule
 *
 * It also includes the following screens / states:
 * - intro, success, schedule
 *
 */
export default defineComponent({
  name: 'ModalRoutine',
  components: {
    SkeletonPlanScheduleLoader,
    Modal,
    Callout,
    SubmitButton,
    WeekRowHeader,
    DayGridButton,
  },
  props: {
    entityId: {
      required: true,
      type: Number,
    },
    entityType: {
      default: 'workout',
      type: String,
      validator: (val) => ['workout', 'custom_workout'].includes(val),
    },
    entityTitle: {
      // currently only used by workout and custom_workout on success
      default: '',
      type: String,
    },
  },
  emits: ['close'],

  // component state
  data() {
    return {
      // toggles which "view" is showing in the modal:
      // intro, success, schedule
      screen: '',

      // initial XHR flow (full screen loader)
      initLoading: false,

      // secondary XHR flow (plan day changes)
      loading: false,
      error: '',
      routines: [],

      // select routine XHR
      selectedRoutineId: null,
      planWeeks: [],

      // saving / adding to routine
      saving: false,

      // map for easy access / no iteration
      // actual planDay.day (1 - however many days in plan).  EX: 4 weeks = 28 days
      // { planDanNumber: boolean } EX ( 1: true, 28: true }
      // when removing plan days, remove the the object key (do not set to false)
      selectedPlanDays: {},
    }
  },

  computed: {
    humanEntityType() {
      if (this.entityType === 'custom_workout') {
        return 'custom workout'
      }

      return this.entityType
    },

    selectedPlanDaysTotal() {
      return Object.keys(this.selectedPlanDays).length
    },

    hasSelectedPlanDays() {
      return !!Object.keys(this.selectedPlanDays).length
    },

    showFixedFooterButton() {
      return !!(this.error || this.screen === 'intro' || this.screen === 'success')
    },
  },

  // lifecycle methods
  async created() {
    try {
      this.initLoading = true
      await this.fetchDrafts()

      // load schedule for first routine
      if (this.routines && this.routines[0]) {
        this.selectedRoutineId = this.routines[0].id
        await this.fetchPlanWeeks(this.selectedRoutineId)
      }
    } catch (e) {
      // noop, errors handled in individual fetch methods
    }

    this.initLoading = false

    // uncomment to test screens

    // intro - no drafs
    // this.screen = 'intro';

    // success - added
    // this.screen = 'success';

    // success - removed all
    // this.selectedPlanDays = {};
    // this.screen = 'success';

    // error
    // this.error = 'failed to fetch data';
    // this.screen = '';
  },

  methods: {
    handleCloseClick() {
      this.$emit('close')
    },

    // get user draft routines for drop down
    async fetchDrafts() {
      // reset state
      this.error = ''
      this.screen = ''
      this.loading = true

      try {
        // since already in modal, skip showing a second modal on error
        // as this component shows an accessible error inline
        const res = await fbApi.get('/routines/drafts', {
          skipErrorModal: true,
        })

        if (!res || !res.body || !res.body.data) {
          throw Error('Missing routine response')
        }

        this.routines = res.body.data

        if (Array.isArray(this.routines) && !this.routines.length) {
          // show intro message to the user
          this.screen = 'intro'
        }
      } catch (e) {
        this.error = 'Error fetching routines'
        this.screen = ''
      }

      // cancel loading
      this.loading = false
    },

    // get the schedule builder (plan_days and plan_day_schedule by week)
    async fetchPlanWeeks(routineId) {
      // reset state
      this.error = ''
      this.loading = true

      try {
        // since already in modal, skip showing a second modal on error
        // as this component shows an accessible error inline
        const res = await fbApi.get(`/plans/${routineId}/plan-weeks`, {
          skipErrorModal: true,
        })

        if (!res || !res.body || !res.body.data || !res.body.data.planWeeks) {
          throw Error('Missing plan week response')
        }

        this.planWeeks = res.body.data.planWeeks

        // auto select plan days if the entity is already selected
        this.autoSelectPlanDays()

        // update screen
        this.screen = 'schedule'
      } catch (e) {
        this.error = 'Error fetching routine plan week'
        this.screen = ''
      }

      // cancel loading
      this.loading = false
    },

    // save entity to plan days
    async savePlanDays(routineId, showSuccessScreen = true) {
      if (this.saving) {
        return
      }

      // reset state
      this.error = ''
      this.saving = true

      try {
        const formData = {
          entityType: this.entityType,
          entityId: this.entityId,
          days: Object.keys(this.selectedPlanDays),
        }

        // since already in modal, skip showing a second modal on error
        // as this component shows an accessible error inline
        const res = await fbApi.post(`/routines/${routineId}/plan-days`, {
          skipErrorModal: true,
          json: formData,
        })

        if (!res || !res.body || !res.body.data) {
          throw Error('Missing plan day save response')
        }

        // show success message screen
        if (showSuccessScreen) {
          this.screen = 'success'
        }
      } catch (e) {
        this.error = 'Error saving routine plan days'
        this.screen = ''
      }

      // cancel loading
      this.saving = false
    },

    /**
     * add to selection map
     */
    toggleSection({ dayNumber }) {
      // delete from map if key exists
      if (this.selectedPlanDays[dayNumber]) {
        delete this.selectedPlanDays[dayNumber]
        return
      }

      // add to map
      this.selectedPlanDays[dayNumber] = this.entityId
    },

    /**
     * check if selected by looking at map keys
     * dayNumber should be 1 - end of plan.  EX: 1-28 for 4 week plan.
     */
    isSelected(dayNumber) {
      return !!this.selectedPlanDays[dayNumber]
    },

    // on fetching plan days, if the workout is already scheduled, built a new
    // selectedPlanDays object
    autoSelectPlanDays() {
      // iterate through weeks / plan days to build new selected object
      this.selectedPlanDays = this.planWeeks.reduce((acc, week) => {
        // day iteration, if schedulable item found in planDaySchedule, then add the actual
        // plan day to the selectedPlanDays obj
        // TODO: only works for FB workouts at this time
        week.days.forEach((day) => {
          const planDaySchedule = day.planDaySchedule.find(
            (scheduleItem) =>
              scheduleItem.schedulableId === this.entityId &&
              scheduleItem.schedulableType === this.entityType,
          )

          if (planDaySchedule) {
            acc[day.planDay.day] = true
          }
        })

        return acc
      }, {})
    },

    async selectRoutine(e) {
      // save any existing work (and call loading faster)
      // force loading a bit earlier to cover the saving
      if (this.hasSelectedPlanDays) {
        this.loading = true
        const showSuccessScreen = false
        await this.savePlanDays(this.selectedRoutineId, showSuccessScreen)
      }

      // update routine id and fetch plan draft if there was no error on saving
      // above
      if (!this.error && e.target instanceof HTMLSelectElement) {
        this.selectedRoutineId = e.target.value
        await this.fetchPlanWeeks(this.selectedRoutineId)
      }
    },

    async scheduleAgain() {
      // load schedule for first routine
      if (this.routines && this.routines[0]) {
        this.screen = 'schedule'
        this.selectedRoutineId = this.routines[0].id
        await this.fetchPlanWeeks(this.routines[0].id)
      }
    },
  },
})
</script>

<template>
  <section
    class="content-group complete-flow"
    :class="{ '-open': openClass }"
    @click.self="handleClose"
  >
    <div
      ref="sliderContent"
      class="group -flex -column -vs-top -complete-group"
      tabindex="-1"
      :aria-label="completeText"
      role="dialog"
      :aria-modal="true"
    >
      <!-- modal header -->
      <div
        class="complete-hero"
        :class="{
          '-wc': entityType !== 'plan',
          '-pc': entityType === 'plan',
        }"
      >
        <div class="close-flow">
          <icon-button
            icon="close"
            classes="-ico-only -no-container"
            aria-label="Close"
            @click-action="handleClose"
          />
        </div>
        <a
          v-if="completeIconVal === 'redeem'"
          href="/my/notifications"
          class="complete-message no-hover message-in"
        >
          <div class="complete-img reward">
            <i class="material-symbols-outlined" aria-hidden="true">{{ completeIconVal }}</i>
          </div>
          <div class="complete-text">You've Earned a Reward</div>
          <div class="complete-text-info">Check Your Notifications</div>
        </a>
        <div
          v-else
          class="complete-message"
          :class="{
            'message-out': planReward,
          }"
        >
          <div class="complete-img">
            <i class="material-symbols-outlined" aria-hidden="true">{{ completeIconVal }}</i>
          </div>
          <div class="complete-text">
            {{ completeText }}
          </div>
        </div>

        <canvas ref="confetti" class="complete-confetti"></canvas>
      </div>

      <!-- modal body -->
      <slot />
    </div>
  </section>
</template>

<script>
import confetti from 'canvas-confetti'
import IconButton from '@/components/buttons/icon-button.vue'
import {
  BRAND_BLUE_100,
  LIGHT_BLUE,
  RED,
  ORANGE,
  PURPLE,
  DARK_PURPLE,
  DARK_WHITE,
} from '@/utils/charts'

/**
 * The container for complete modals, includes header and event emitters and
 * animations
 *
 * Contains slot for modal body
 *
 * $emits the following actions
 * - close-start
 * - close-end
 */
export default {
  name: 'CompleteSliderHero',
  components: { IconButton },
  props: {
    entityType: {
      default: 'workout',
      type: String,
      validator: (val) =>
        ['plan', 'workout', 'custom_workout', 'article', 'wellness_video'].includes(val),
    },

    entityCategory: {
      default: '',
      validator: (val) => ['', 'challenge', 'routine', 'program'].includes(val),
    },

    // should mirror *-complete-button.vue
    completeText: {
      type: String,
      default: '',
    },
    showConfetti: {
      type: Boolean,
      default: true,
    },
    rewardEarned: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-start', 'close-end'],
  data() {
    return {
      // toggles to open after component renders so animation is visible
      openClass: false,
      activeCanvas: null, // used for stopping animations
      rewardTimeout: null,
      completeIconVal: 'check_circle',
    }
  },

  computed: {
    planReward() {
      return this.entityType === 'plan' && this.rewardEarned
    },
  },

  mounted() {
    this.$nextTick(() => {
      // disable background scrolling
      if (document) {
        document.querySelector('body').classList.add('no-scroll')
      }

      this.animateOpen()
    })
  },

  beforeUnmount() {
    // undo and allow scrolling
    if (document) {
      document.querySelector('body').classList.remove('no-scroll')
    }
  },

  methods: {
    /**
     * allows css animation to run after initial render
     */
    animateOpen() {
      // triggers open, which takes 400ms via css transition
      setTimeout(() => {
        this.openClass = true
      }, 5)

      // focus for accessibility
      // mirrors css transition for visibility
      setTimeout(() => {
        this.$refs.sliderContent.focus()
      }, 300)

      // show animations if user has confetti enabled
      if (this.showConfetti) {
        this.activeCanvas = null

        setTimeout(() => {
          // programs
          if (this.entityType === 'plan') {
            this.showConfettiStream()
            return
          }

          // default
          this.showConfettiPop()
        }, 300)
      }

      // Change to reward icon and text if Plan Reward was earned
      if (this.planReward) {
        this.rewardTimeout = setTimeout(() => {
          this.completeIconVal = 'redeem'

          // special star pop if confetti is enabled
          if (this.showConfetti) {
            this.showConfettiStar()
          }
        }, 2000)
      }
    },

    /**
     * Remove open class for animation then emit close
     * css animation is currently 250ms
     *
     * Emits start and end events lifecycle events when animation
     * starts and ends.
     */
    handleClose() {
      this.openClass = false

      // stop reward animation if closed early
      if (this.rewardTimeout) {
        clearTimeout(this.rewardTimeout)
      }

      // two events for animation
      this.$emit('close-start')

      setTimeout(() => {
        this.$emit('close-end')
      }, 275)
    },

    /**
     * Small confetti pop for independent scheduled items
     *
     * @see https://github.com/catdad/canvas-confetti
     * @see https://catdad.github.io/canvas-confetti/
     */
    showConfettiPop() {
      // stop any existing animation before loading a new one
      if (this.activeCanvas) {
        this.activeCanvas.reset()
      }

      const customCanvas = this.$refs.confetti
      const customCanvasConfetti = confetti.create(customCanvas, { resize: true })
      this.activeCanvas = customCanvasConfetti

      // get width and lower confetti pop for mobile devices
      // desktop can have more closer to middle
      const originY = window.innerWidth <= 415 ? 0.75 : 0.75

      // animation keyframe request stop
      const end = Date.now() + 1 * 1000

      // toggle colors between workout and custom workout
      let colors = [LIGHT_BLUE, BRAND_BLUE_100, DARK_WHITE]

      if (this.entityType === 'custom_workout') {
        colors = [ORANGE, RED, DARK_WHITE]
      }

      if (this.entityType === 'wellness_video') {
        colors = [DARK_PURPLE, PURPLE, DARK_WHITE]
      }
      // end toggle colors

      // self executing function
      customCanvasConfetti({
        particleCount: 50,
        spread: 70,
        origin: {
          x: 0.5,
          y: originY,
        },
        colors,
        disableForReducedMotion: true,
      })

      // needed for iOS to keep animations from lagging
      // keep going until we are out of time
      const frame = function () {
        if (Date.now() < end) {
          requestAnimationFrame(frame)
        }
      }

      // invoke
      frame()
    },

    /**
     * Two confetti streams for programs
     *
     * @see https://github.com/catdad/canvas-confetti
     * @see https://catdad.github.io/canvas-confetti/
     */
    showConfettiStream() {
      // stop any existing animation before loading a new one
      if (this.activeCanvas) {
        this.activeCanvas.reset()
      }

      const customCanvas = this.$refs.confetti
      const customCanvasConfetti = confetti.create(customCanvas, { resize: true })
      this.activeCanvas = customCanvasConfetti

      // get width and lower confetti pop for mobile devices
      // desktop can have more closer to middle
      const originY = window.innerWidth <= 415 ? 0.75 : 0.75

      // animation keyframe request stop
      const end = Date.now() + 1 * 1000

      // toggle colors based on program typel
      let colorLeft = [PURPLE, DARK_WHITE]
      let colorRight = [PURPLE, DARK_WHITE]

      if (this.entityCategory === 'challenge') {
        colorLeft = [ORANGE, DARK_WHITE]
        colorRight = [RED, DARK_WHITE]
      } else if (this.entityCategory === 'routine') {
        colorLeft = [ORANGE, DARK_WHITE]
        colorRight = [PURPLE, DARK_WHITE]
      }
      // end color toggle;

      const frame = () => {
        // self executing function

        // left blast
        customCanvasConfetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0, y: originY },
          colors: colorLeft,
          disableForReducedMotion: true,
        })
        // right blast
        customCanvasConfetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1, y: originY },
          colors: colorRight,
          disableForReducedMotion: true,
        })

        // needed for iOS to keep animations from lagging
        // keep going until we are out of time
        // allow stopping if a reward animation starts
        if (Date.now() < end) {
          requestAnimationFrame(frame)
        }
      }

      //invoke
      frame()
    },

    /**
     * Star pop for reward
     *
     * @see https://github.com/catdad/canvas-confetti
     * @see https://catdad.github.io/canvas-confetti/
     */
    showConfettiStar() {
      // stop any existing animation before loading a new one
      if (this.activeCanvas) {
        this.activeCanvas.reset()
      }

      const customCanvas = this.$refs.confetti
      const customCanvasConfetti = confetti.create(customCanvas, { resize: true })
      this.activeCanvas = customCanvasConfetti

      // animation keyframe request stop
      const end = Date.now() + 0 * 1000

      // custom colors for stars
      let colors = ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']

      let defaultOption = {
        particleCount: 50,
        spread: 360,
        ticks: 50,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        shapes: ['star'],
        colors,
        disableForReducedMotion: true,
      }

      const shootStar = () => {
        // self executing function

        customCanvasConfetti({
          ...defaultOption,
          particleCount: 40,
          scalar: 1.2,
          shapes: ['star'],
        })

        customCanvasConfetti({
          ...defaultOption,
          particleCount: 10,
          scalar: 0.75,
          shapes: ['circle'],
        })
      }

      // invoke 3x
      shootStar()
      setTimeout(shootStar, 100)
      setTimeout(shootStar, 200)

      // needed for iOS to keep animations from lagging
      // keep going until we are out of time
      const frame = function () {
        if (Date.now() < end) {
          requestAnimationFrame(frame)
        }
      }

      // invoke
      frame()
    },
  },
}
</script>

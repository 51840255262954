import { createRouter, createWebHistory } from 'vue-router'
import { routeScrollBehavior, spaMetaUpdateAndTrack } from '@/utils/head'

/*
CODE SPLITTING
 */

/* ANALYTICS-TRACKERS ROUTES */
// NOTE: apexcharts and vue-apexcharts are part of the vendor chunk for analytics
const DashboardStatsPage = () =>
  import(
    /* webpackChunkName: "analytics-trackers" */ './components/analytics/dashboard-stats-page.vue'
  )
const DashboardSchedulablePage = () =>
  import(
    /* webpackChunkName: "analytics-trackers" */ './components/analytics/dashboard-schedulable-page.vue'
  )
const DashboardCommunityPage = () =>
  import(
    /* webpackChunkName: "analytics-trackers" */ './components/analytics/dashboard-community-page.vue'
  )
const WorkoutDetailDashboardPage = () =>
  import(
    /* webpackChunkName: "analytics-trackers" */ './components/analytics/details/workout-dashboard-page.vue'
  )
const SchedulableCompletesPage = () =>
  import(
    /* webpackChunkName: "analytics-trackers" */ './components/analytics/details/schedulable-completes-page.vue'
  )
const DashboardNotesPage = () =>
  import(/* webpackChunkName: "analytics-trackers" */ './components/notes/dashboard-notes-page.vue')

const ArticleDetailDashboardPage = () =>
  import(
    /* webpackChunkName: "analytics-trackers" */ './components/analytics/details/article-dashboard-page.vue'
  )
const WellnessVideoDetailDashboardPage = () =>
  import(
    /* webpackChunkName: "analytics-trackers" */ './components/analytics/details/wellness-video-dashboard-page.vue'
  )
const OrganizationDashboardPage = () =>
  import(
    /* webpackChunkName: "analytics-trackers" */ './components/organizations/dashboard-page.vue'
  )
const OrganizationUsersPage = () =>
  import(
    /* webpackChunkName: "analytics-trackers" */ './components/organizations/dashboard-users-page.vue'
  )

/* ACHIEVEMENTS ROUTES */
const AchievementsPage = () =>
  import(/* webpackChunkName: "user" */ './components/achievements/achievements-page.vue')

/* NOTIFICATION ROUTES */
const NotificationsPage = () =>
  import(/* webpackChunkName: "user" */ './components/notifications/notifications-page.vue')

const EnergyPage = () =>
  import(/* webpackChunkName: "user" */ './components/users/settings/energy/energy-page.vue')

const ReferralsPage = () =>
  import(/* webpackChunkName: "user" */ './components/users/referrals/referrals-page.vue')

/* SEARCH ROUTES */
const VideosPage = () =>
  import(/* webpackChunkName: "search" */ './components/videos/videos-page.vue')

const VideosBrowsePage = () =>
  import(/* webpackChunkName: "search" */ './components/videos/videos-browse-page.vue')

const VideosCollectionsPage = () =>
  import(/* webpackChunkName: "search" */ './components/videos/videos-collections-page.vue')

const SimilarVideosPage = () =>
  import(/* webpackChunkName: "search" */ './components/videos/similar-videos-page.vue')

const WellnessVideosPage = () =>
  import(/* webpackChunkName: "search" */ './components/wellness-videos/wellness-videos-page.vue')

const PlansPage = () => import(/* webpackChunkName: "search" */ './components/plans/plans-page.vue')

const PlansBrowsePage = () =>
  import(/* webpackChunkName: "search" */ './components/plans/plans-browse-page.vue')

const MealPlansPage = () =>
  import(/* webpackChunkName: "search" */ './components/plans/meal-plans-page.vue')

const PilotProgramsPage = () =>
  import(/* webpackChunkName: "search" */ './components/plans/pilot-programs-page.vue')

const ArticlesPage = () =>
  import(/* webpackChunkName: "search" */ './components/articles/articles-page.vue')

const RecipesPage = () =>
  import(/* webpackChunkName: "search" */ './components/articles/recipes-page.vue')

/* ROUTINES ROUTES */
// using search chunk as routine building happens to use video / favorites search
const RoutinesPage = () =>
  import(/* webpackChunkName: "search" */ './components/routines/routines-page.vue')

/* CUSTOM WORKOUTS ROUTES */
const CustomWorkoutsPage = () =>
  import(/* webpackChunkName: "search" */ './components/custom-workouts/custom-workouts-page.vue')

/* CALENDAR ROUTES */
const CalendarYearPage = () =>
  import(/* webpackChunkName: "calendar" */ './components/calendar/calendar-year-page.vue')
const CalendarMonthPage = () =>
  import(/* webpackChunkName: "calendar" */ './components/calendar/calendar-month-page.vue')
const CalendarDayPage = () =>
  import(/* webpackChunkName: "calendar" */ './components/calendar/calendar-day-page.vue')
const CalendarNotesPage = () =>
  import(/* webpackChunkName: "calendar" */ './components/notes/calendar-notes-page.vue')
const ReschedulePage = () =>
  import(/* webpackChunkName: "calendar" */ './components/calendar/reschedule-page.vue')

export const createSpaRoutes = () => {
  const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: routeScrollBehavior,
    routes: [
      /* workout videos */
      {
        name: 'Videos',
        meta: {
          title: 'Workout Videos',
          analyticsTrack: true,
          focusTitle: false, // managed on videos-page.vue
        },
        path: '/videos',
        component: VideosPage,
      },
      {
        name: 'VideoBrowse',
        meta: {
          title: 'Browse Workout Videos',
          analyticsTrack: true,
          focusTitle: false, // managed on videos-browse-page.vue
        },
        path: '/videos/browse',
        component: VideosBrowsePage,
      },
      {
        name: 'VideoCollections',
        meta: {
          title: 'Browse Workout Videos',
          analyticsTrack: true,
          focusTitle: false, // managed on videos-collections-page.vue
        },
        path: '/videos/collections/:id/:slug?',
        component: VideosCollectionsPage,
      },
      {
        name: 'SimilarVideos',
        meta: {
          title: 'Similar Workout Videos',
          analyticsTrack: true,
          focusTitle: false, // managed on similar-videos-page.vue
        },
        path: '/videos/similar',
        component: SimilarVideosPage,
      },
      {
        name: 'FavoriteVideos',
        meta: {
          title: 'Favorite Workouts',
          analyticsTrack: true,
          focusTitle: false, // managed on videos-page.vue
        },
        path: '/my/favorite/videos',
        component: VideosPage,
      },

      /* wellness videos */
      {
        name: 'WellnessVideos',
        meta: {
          title: 'Wellness Videos',
          analyticsTrack: true,
          focusTitle: false,
        },
        path: '/wellness-videos',
        component: WellnessVideosPage,
      },
      {
        name: 'FavoriteWellnessVideos',
        meta: {
          title: 'Favorite Wellness Videos',
          analyticsTrack: true,
          focusTitle: false,
        },
        path: '/my/favorite/wellness-videos',
        component: WellnessVideosPage,
      },

      {
        name: 'Plans',
        meta: {
          title: 'Workout Programs',
          analyticsTrack: true,
          focusTitle: false,
        },
        path: '/plans',
        component: PlansPage,
      },
      {
        name: 'MyPrograms',
        meta: {
          title: 'My Workout Programs',
          analyticsTrack: true,
          focusTitle: false,
        },
        path: '/my/programs',
        component: PlansPage,
      },
      {
        name: 'WorkoutPlanBrowse',
        meta: {
          title: 'Browse Workout Programs',
          analyticsTrack: true,
          focusTitle: false,
        },
        path: '/plans/browse',
        component: PlansBrowsePage,
      },
      {
        name: 'MyProgramsBrowse',
        meta: {
          title: 'Browse My Workout Programs',
          analyticsTrack: true,
          focusTitle: false, // managed on plans-browse-page.vue
        },
        path: '/my/programs/browse',
        component: PlansBrowsePage,
      },
      {
        name: 'MealPlans',
        meta: {
          title: 'Meal Plans',
          analyticsTrack: true,
          focusTitle: false, // managed on meal-plans-page.vue
        },
        path: '/meal-plans',
        component: MealPlansPage,
      },
      {
        name: 'MyMealPlans',
        meta: {
          title: 'My Meal Plans',
          analyticsTrack: true,
          focusTitle: false, // managed on meal-plans-page.vue
        },
        path: '/my/meal-plans',
        component: MealPlansPage,
      },

      /* pilot programs */
      {
        name: 'PilotPrograms',
        meta: {
          title: 'Pilot Programs',
          analyticsTrack: true,
          focusTitle: false, // managed on pilot-programs-plans-page.vue
        },
        path: '/pilot-programs',
        component: PilotProgramsPage,
      },
      {
        name: 'MyPilotPrograms',
        meta: {
          title: 'My Pilot Programs',
          analyticsTrack: true,
          focusTitle: false, // managed on pilot-programs-plans-page.vue
        },
        path: '/my/pilot-programs',
        component: PilotProgramsPage,
      },

      /* achievements */
      {
        name: 'Achievements',
        meta: {
          title: 'Achievements',
          analyticsTrack: true,
        },
        path: '/my/achievements/:type',
        component: AchievementsPage,
      },

      /* notifications */
      {
        name: 'Notifications',
        meta: {
          title: 'Notifications',
          analyticsTrack: true,
          focusTitle: false, // managed on notifications-page.vue
        },
        path: '/my/notifications',
        component: NotificationsPage,
      },

      /* energy settings, multiple routes handled by single SPA */
      {
        name: 'EnergySettings',
        meta: {
          title: 'Energy Settings',
          analyticsTrack: true,
        },
        path: '/my/settings/energy/:section(dietary|calorie)?/:energyMethod(range|goal|calculator)?',
        component: EnergyPage,
      },

      /* referrals */
      {
        name: 'Referrals',
        meta: {
          title: 'Referrals',
          analyticsTrack: true,
          scrollTo: '#referral-card-top',
        },
        path: '/my/referrals',
        component: ReferralsPage,
      },

      /* article-search (only recipes currently, see ArticlesController->categoryIndex) */
      {
        name: 'Articles',
        meta: {
          title: 'Articles',
          analyticsTrack: true,
          focusTitle: false, // managed on articles-page.vue
        },
        path: '/articles',
        component: ArticlesPage,
      },
      {
        name: 'FavoriteArticles',
        meta: {
          title: 'Favorite Articles',
          analyticsTrack: true,
          focusTitle: false, // managed on articles-page.vue
        },
        path: '/my/favorite/articles',
        component: ArticlesPage,
      },
      {
        name: 'Recipes',
        meta: {
          title: 'Recipes',
          analyticsTrack: true,
          focusTitle: false, // managed on recipes-page.vue
        },
        path: '/healthy-living/healthy-recipes',
        component: RecipesPage,
      },
      {
        name: 'FavoriteRecipes',
        meta: {
          title: 'Favorite Recipes',
          analyticsTrack: true,
          focusTitle: false, // managed on recipes-page.vue
        },
        path: '/my/favorite/recipes',
        component: RecipesPage,
      },

      /* custom-workouts */
      {
        name: 'CustomWorkouts',
        meta: {
          title: 'My Custom Workouts',
          analyticsTrack: true,
          focusTitle: false, // managed on custom-workouts-page.vue
        },
        path: '/my/custom-workouts',
        component: CustomWorkoutsPage,
      },

      /* routines */
      {
        name: 'Routines',
        meta: {
          title: 'My Routines',
          analyticsTrack: true,
          focusTitle: false, // managed on routines-page.vue
        },
        path: '/my/routines',
        component: RoutinesPage,
      },

      /* calendar SPA routes */
      {
        name: 'CalendarReschedule',
        meta: {
          title: 'Reschedule',
          analyticsTrack: true,
        },
        path: '/my/calendar/reschedule/:date?',
        component: ReschedulePage,
      },
      {
        name: 'CalendarDay',
        meta: {
          title: '', // title updates handled within component
          analyticsTrack: true,
          analyticsSkipFromPaths: ['/my/calendar/day'],
        },
        path: '/my/calendar/day/:date?',
        component: CalendarDayPage,
      },
      {
        name: 'CalendarDayNotes',
        meta: {
          title: 'Calendar Day Notes',
          analyticsTrack: true,
        },
        path: '/my/calendar/day/:date/notes',
        component: CalendarNotesPage,
      },
      {
        name: 'CalendarYear',
        meta: {
          title: '', // title updates handled within component
          analyticsTrack: true,
          analyticsSkipFromPaths: ['/my/calendar/years'],
        },
        path: '/my/calendar/years/:year?',
        component: CalendarYearPage,
      },
      // month must be lower than both year and days
      // due to matching rules
      {
        name: 'CalendarMonth',
        meta: {
          title: '', // title updates handled within component
          analyticsTrack: true,
          analyticsSkipFromPaths: ['/my/calendar'],
        },
        path: '/my/calendar/:month?/:year?',
        component: CalendarMonthPage,
      },

      /* analytics SPA routes, title should mirror what is in MyDashboardController->analyticsSPA */
      {
        name: 'DashboardStats',
        meta: {
          title: 'Dashboard Stats',
          analyticsTrack: true,
        },
        path: '/my/dashboard/stats',
        component: DashboardStatsPage,
      },
      {
        name: 'DashboardWorkouts',
        meta: {
          title: 'Dashboard Completed History',
          analyticsTrack: true,
        },
        path: '/my/dashboard/:schedulableType(workouts|custom-workouts|wellness-videos|articles|recipes)/:grouping(top)?',
        component: DashboardSchedulablePage,
      },
      {
        name: 'DashboardCommunity',
        meta: {
          title: 'Dashboard Community',
          analyticsTrack: true,
        },
        path: '/my/dashboard/:postType(comments|discussions)',
        component: DashboardCommunityPage,
      },

      /*
        analytics + tracker SPA routes
        - workout: title should mirror what is in VideoController->show
        - custom_workout: title should mirror what is in CustomWorkouts->show
        - article: title should mirror what is in Article->show
       */
      // video workout dashboards
      {
        name: 'WorkoutDetailDashboard',
        meta: {
          title: 'Workout Dashboard',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/videos/:slug/dashboard',
        component: WorkoutDetailDashboardPage,
      },
      {
        name: 'WorkoutDetailCompletes',
        meta: {
          title: 'Workout Completes',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/videos/:slug/dashboard/completes',
        component: SchedulableCompletesPage,
      },
      {
        name: 'WorkoutDetailNotes',
        meta: {
          title: 'Workout Notes',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/videos/:slug/dashboard/notes',
        component: DashboardNotesPage,
      },
      // custom workouts dashboards
      {
        name: 'CustomWorkoutDetailDashboard',
        meta: {
          title: 'Workout Dashboard',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/my/custom-workouts/:id/:slug/dashboard',
        component: WorkoutDetailDashboardPage,
      },
      {
        name: 'CustomWorkoutDetailCompletes',
        meta: {
          title: 'Workout Completes',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/my/custom-workouts/:id/:slug/dashboard/completes',
        component: SchedulableCompletesPage,
      },
      {
        name: 'CustomWorkoutDetailNotes',
        meta: {
          title: 'Workout Notes',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/my/custom-workouts/:id/:slug/dashboard/notes',
        component: DashboardNotesPage,
      },
      // articles dashboards
      {
        name: 'ArticleDetailDashboard',
        meta: {
          title: 'Article Dashboard',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/articles/:slug/dashboard',
        component: ArticleDetailDashboardPage,
      },
      {
        name: 'ArticleDetailNotes',
        meta: {
          title: 'Article Notes',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/articles/:slug/dashboard/notes',
        component: DashboardNotesPage,
      },
      {
        name: 'ArticleDetailCompletes',
        meta: {
          title: 'Article Completes',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/articles/:slug/dashboard/completes',
        component: SchedulableCompletesPage,
      },

      // wellness video dashboards
      {
        name: 'WellnessVideoDetailDashboard',
        meta: {
          title: 'Wellness Dashboard',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/wellness-videos/:slug/dashboard',
        component: WellnessVideoDetailDashboardPage,
      },
      {
        name: 'WellnessVideoDetailNotes',
        meta: {
          title: 'Wellness Notes',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/wellness-videos/:slug/dashboard/notes',
        component: DashboardNotesPage,
      },
      {
        name: 'WellnessVideoDetailCompletes',
        meta: {
          title: 'Wellness Completes',
          analyticsTrack: true,
          scrollTo: '#spa-top',
        },
        path: '/wellness-videos/:slug/dashboard/completes',
        component: SchedulableCompletesPage,
      },

      // organization dashboards
      {
        name: 'OrganizationDashboard',
        meta: {
          title: 'Organization Dashboard',
          analyticsTrack: true,
        },
        path: '/organizations/:slug/dashboard',
        component: OrganizationDashboardPage,
      },
      {
        name: 'OrganizationUsers',
        meta: {
          title: 'Organization Members',
          analyticsTrack: true,
        },
        path: '/organizations/:slug/users',
        component: OrganizationUsersPage,
      },
    ],
  })

  // auto scroll, focus, and track pageviews in analytics
  router.afterEach((to, from) => {
    // if route is actually in our SPA routes, remove map-ssr-cls from #app
    // to avoid layout shifts, doing it after route loads, otherwise footer was
    // CLS-ing upwards after SSR DOM removed
    if (to.name) {
      const app = document.getElementById('app')

      if (app && app.classList.contains('mpa-ssr-cls')) {
        app.classList.remove('mpa-ssr-cls')
      }
    }

    // if not coming from an SPA route OR initial SPA page load (no name, not in routes)
    // do not do anything... do not want to double track in GA as
    // it was already tracked in initial blade load
    if (!from.name) {
      return
    }

    let analyticsTrack = !!to.meta.analyticsTrack

    // some routes have really quick router.replace redirect
    // and we do not want accidently double track those in analytics
    // compare to from.path and skip if match
    if (
      Array.isArray(to.meta.analyticsSkipFromPaths) &&
      to.meta.analyticsSkipFromPaths.length &&
      to.meta.analyticsSkipFromPaths.includes(from.path)
    ) {
      analyticsTrack = false
    }

    if (to.meta) {
      spaMetaUpdateAndTrack({
        ...to.meta,
        analyticsTrack,
      })
    }
  })

  return router
}

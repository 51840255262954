<template>
  <div class="content-actions -comments">
    <icon-button
      type="button"
      class="-ico-only -no-container"
      icon="more_vert"
      :label="isMenuOpen ? 'Action Menu Expanded' : 'Action Menu Collapsed'"
      :aria-expanded="isMenuOpen ? 'true' : 'false'"
      :aria-controls="menuId"
      @click-action="handleMenuOpen"
    />
    <outside-click :disabled="!isMenuOpen" @outside-click-action="handleMenuClose">
      <div
        :id="menuId"
        class="content-actions-group"
        :class="{ '-open': isMenuOpen }"
        :aria-hidden="isMenuOpen ? 'false' : 'true'"
      >
        <div class="content-actions-close">
          <icon-button
            type="button"
            class="-ico-only -no-container"
            icon="close"
            label="Close Menu"
            @click-action="handleMenuClose"
          />
        </div>
        <ul class="content-actions-list">
          <li class="content-action">
            <button class="btn" type="button" @click="handleEditComment">
              <i class="material-symbols-outlined" aria-hidden="true">edit</i>
              Edit Comment
            </button>
          </li>
          <li class="content-action">
            <button class="btn" type="button" @click="handleDeleteComment">
              <i class="material-symbols-outlined" aria-hidden="true">delete</i>
              Delete Comment
            </button>
          </li>
        </ul>
      </div>
    </outside-click>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import OutsideClick from '@/components/tool-tips/outside-click.vue'
import IconButton from '@/components/buttons/icon-button.vue'

export default defineComponent({
  name: 'CommentActionsMenu',
  components: {
    IconButton,
    OutsideClick,
  },
  props: {
    commentId: {
      type: Number,
      required: true,
    },
  },

  emits: ['edit-comment', 'delete-comment'],

  data() {
    return {
      isMenuOpen: false,
    }
  },

  computed: {
    menuId() {
      return `comments-actions-menu-${this.commentId}`
    },
  },

  methods: {
    // main menu toggle
    handleMenuOpen() {
      this.isMenuOpen = true
    },

    handleMenuClose() {
      this.isMenuOpen = false
    },

    handleEditComment() {
      this.handleMenuClose()
      this.$emit('edit-comment')
    },

    handleDeleteComment() {
      this.handleMenuClose()
      this.$emit('delete-comment')
    },
  },
})
</script>

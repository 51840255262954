/**
 * Plan utils
 *
 * These are helper methods for shared functionality on plan conmponents
 * that DO NOT introduce non-side-effect, mutate params that are passed in
 * and are pure functions.
 *
 * I went this route instead of mixins as mixins are not a thing in vu3
 * and needed some computed functionality shared between a few plan
 * components (as the markup template was very different and could not make
 * generic enough to work with both).
 *
 * COMPONENTS
 * - plan-card-add-to-card.vue
 * - plan-detail-add-to-card.vue
 *
 * TESTS
 * tests/js/plans.test.js
 */
import { compareFloats } from '@/utils/helpers'

/**
 * Determine if the plan is schedulable for the user
 */
export const isSchedulable = ({ user, isSubscriber, plan, isPurchased }) => {
  // anon, show but will prompt for sign upo
  if (!user.isLoggedIn) {
    return false
  }

  // plan is free, all have access
  if (plan.is_free) {
    return true
  }

  // user has purchased
  if (isPurchased) {
    return true
  }

  // exclusive access check
  if (plan.is_exclusive && isSubscriber) {
    return true
  }

  // default to false
  return false
}

/**
 * Determine if the plan can be purchased to owned or with an fb
 * plus pass.
 *
 * own = user gets to keep it, normal purchase
 * pass = user is really buying fb plus access to access the exclusive plan
 */
export const isPurchasable = ({ plan, plusPass, isPurchased, isSubscriber }) => {
  // helper for if statements
  const isPaid = !!plan.is_paid
  const isExclusive = !!plan.is_exclusive
  const hasPlusPass = !!(plusPass && plusPass.id)

  // user already bought plan
  if (isPurchased) {
    return false
  }

  // if the plan somehow is not set to paid or exclusive
  // this shouldn't happen
  if (!isPaid && !isExclusive) {
    return false
  }

  // if plan is exclusive, have to check user access to the plan
  if (isExclusive) {
    // anonymous / free users cannot buy plan if it isn't paid and
    // has no associated plus pass
    if (!isSubscriber && !isPaid && !hasPlusPass) {
      return false
    }

    // if exclusive and fb plus access of any kind (pass or auto-renew)
    // cannot purchase
    if (isSubscriber) {
      return false
    }
  }
  // end exclusive checks

  // default assumes plan can be purchased to own or with plus pass
  return true
}

/**
 * Human-readable purchasable message
 */
export const purchasableMessage = ({ plan, plusPass, isSubscriber }) => {
  // helper for if statements
  const isPaid = !!plan.is_paid
  const isExclusive = !!plan.is_exclusive
  const hasPlusPass = !!(plusPass && plusPass.id)

  if (isExclusive) {
    // plan is both purchasable and has associated plus pass
    if (!isSubscriber && isPaid && hasPlusPass) {
      return 'Available for Purchase or with FB Plus as low as:'
    }

    // plan only has associated plus pass
    if (!isSubscriber && !isPaid && hasPlusPass) {
      return 'Available with FB Plus as low as:'
    }
  }

  // plan is only purchasable, no plus pass
  if (isPaid && !hasPlusPass) {
    return 'Available for Purchase as low as:'
  }

  // default message, should never display due to user having access
  return ''
}

/**
 * Human-readable reason for why plan is not purchasable
 *
 * - Shouldn't show if schedulable due to component(s) show logic
 * - Unique cases such as landing pages + sidebars where we aren't providing these cards
 *   with any is_paid or pricing data as it would be multiple queries per page due to each card.
 *   Only these cases will show the default message.
 */
export const notPurchasableMessage = ({ plan }) => {
  // helper for if statements
  const isFree = !!plan.is_free
  const isExclusive = !!plan.is_exclusive

  // plan is free
  if (isFree) {
    return 'Available with a Free Membership'
  }

  if (isExclusive) {
    return 'Available with FB Plus'
  }

  // default message
  return 'Available for Purchase'
}

/**
 * Determine the lowest price to display by looking at the plan pricing and
 * related plus pass pricing.
 *
 * Assumes the plan isPurchasable and that is not re-checked here.
 */
export const lowestPrice = ({ plan, plusPass }) => {
  // helper for if statements
  const isPaid = !!plan.is_paid
  const hasPlusPass = !!(plusPass && plusPass.id)

  // init with plan pricing
  const planPrice = {
    price: plan.price,
    is_sale: plan.is_sale,
    sale_price: plan.sale_price,
  }

  // if no plus pass, stop
  if (isPaid && !hasPlusPass) {
    return planPrice
  }

  // pluck out pass pricing
  const plusPassPrice = {
    price: plusPass.price,
    is_sale: plusPass.is_sale,
    sale_price: plusPass.sale_price,
  }

  // compare pricing between plan price and pass price
  // most of the time pass should be cheaper
  if (isPaid && hasPlusPass) {
    // if plan is on sale and plus pass is not, keep plan pricing regardless
    // so it can show strike-through pricing
    if (plan.is_sale && !plusPass.is_sale) {
      return planPrice
    }

    // if both are on sale, set the lowest sales price
    if (
      plan.is_sale &&
      plusPass.is_sale &&
      compareFloats(plan.sale_price, '>=', plusPass.sale_price)
    ) {
      return plusPassPrice
    }

    // if only the pass is on sale, make sure pass sales price is still cheaper (should be)
    if (!plan.is_sale && plusPass.is_sale && compareFloats(plan.price, '>=', plusPass.sale_price)) {
      return plusPassPrice
    }

    // if neither on sale, confirm pass is still cheaper (should be)
    if (!plan.is_sale && !plusPass.is_sale && compareFloats(plan.price, '>=', plusPass.price)) {
      return plusPassPrice
    }
  } else if (!isPaid && hasPlusPass) {
    // no option to own, only can buy the plus pass
    return plusPassPrice
  }

  // default to showing plan price
  return planPrice
}

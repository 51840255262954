<template>
  <form ref="formRef" method="post" action="/store/cart" novalidate>
    <csrf-input />
    <input type="hidden" name="itemType" :value="itemType" />
    <input type="hidden" name="addToCart" :value="itemId" />
    <input
      v-if="metaItemFromType"
      type="hidden"
      name="metaItemFromType"
      :value="metaItemFromType"
    />
    <input v-if="metaItemFromId" type="hidden" name="metaItemFromId" :value="metaItemFromId" />
    <icon-button
      type="submit"
      icon="shopping_bag"
      :icons-outlined="true"
      :reduce="false"
      :submitting="processing"
      submitting-label="Adding..."
      :classes="buttonClasses"
      :label="buttonLabel"
      aria-live="polite"
      @click-action="handleSubmit"
    />
  </form>
</template>

<script setup lang="ts">
import { nextTick, useTemplateRef, ref } from 'vue'
import CsrfInput from '@/components/forms/csrf-input.vue'
import IconButton from '@/components/buttons/icon-button.vue'
import { trackEvent as analyticsTrackEvent } from '@/utils/analytics'

/** PROPS */
const props = withDefaults(
  defineProps<{
    itemType?: 'plan' | 'product'
    itemId: number
    metaItemFromType?: 'plan' | 'workout' | 'wellness_video' | 'article' | ''
    metaItemFromId?: number
    buttonClasses?: string
    buttonLabel?: string

    // used for GA add to cart event tracking
    trackEvent?: boolean
    eventCategory?: string
    eventAction?: string
    eventLabel?: string
  }>(),
  {
    itemType: 'plan',
    metaItemFromType: '',
    metaItemFromId: 0,
    buttonClasses: '-ico-txt -no-hover -p-01',
    buttonLabel: 'Add To Bag',

    // used for GA add to cart event tracking
    trackEvent: false,
    eventCategory: '',
    eventAction: '',
    eventLabel: '',
  },
)

/** DATA */
const processing = ref(false)
const formRef = useTemplateRef('formRef')

/** METHODS */
async function handleSubmit() {
  if (processing.value) {
    return
  }
  // disable submit
  processing.value = true

  try {
    // wrapper for GA event hitCallback so only invoked once
    let eventSubmitting = false
    const submitForm = () => {
      if (eventSubmitting) {
        return
      }

      if (formRef.value) {
        eventSubmitting = true
        formRef.value.submit()
      }
    }

    // ga event tracking
    if (props.trackEvent) {
      analyticsTrackEvent({
        category: props.eventCategory,
        action: props.eventAction,
        label: props.eventLabel,
        transport: 'beacon',

        // @ts-expect-error analytics.js is not a typescript file
        callback: submitForm,
      })

      // if hitCallback is not called for whatever reason, redirect w/o waiting
      setTimeout(submitForm, 1000)
    } else {
      // no tracking
      nextTick(submitForm)
    }
  } catch (e) {
    console.error(e)
    processing.value = false
  }
}
</script>

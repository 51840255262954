import { defineStore } from 'pinia'
import { fbApi, objToSearchParams } from '@/utils/http-api'
import { scrollToElemTop } from '@/utils/scroll-to'
import type { NoteState, NoteFetch, Note } from '@/types/note-types'

export const useNotesStore = defineStore('notes', {
  state: (): NoteState => ({
    loading: false,
    error: '',
    paginatedResults: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      perPage: 25,
      notes: [],
    },

    // map -> { id: bool }
    expanded: {},
  }),

  getters: {
    isExpanded: (state: NoteState) => (id: number) => {
      return !!(state.expanded && state.expanded[id])
    },
  },

  actions: {
    /**
     * Fetch paginated results for notes
     * - loading and error tracked here as it needs to be triggered from multiple places
     * @returns {Promise<void>}
     */
    async fetchNotes({
      notableType,
      notableId,
      page = 1,
      expandFirst = false,
      noteDate = '',
    }: NoteFetch) {
      this.setLoading()

      try {
        const params = {
          notableType,
          notableId,
          page,
          noteDate,
        }

        const url = '/notes'

        const res = await fbApi.get(url, {
          searchParams: objToSearchParams(params),
        })

        if (!res || !res.body || !res.body.data) {
          throw Error('Missing notes on success')
        }

        this.setPagination(res.body.data)
        const { notes } = res.body.data

        // set expand state for first note
        if (expandFirst && notes && notes.length) {
          this.expand(notes[0].id)
        }
      } catch (err) {
        this.setError(`Unable to fetch notes, please try again`)
      }
    },

    /**
     * Assumes component handles landing / error states, not tracked here
     * - if no pagination results, will force a full re-fetch
     * - if pagination results, will add below pinned
     *
     * @returns {Promise<void>}
     */
    async addNote(formData: Note) {
      const url = '/notes'

      const res = await fbApi.post(url, {
        json: formData,
      })

      if (!res || !res.body || !res.body.data) {
        throw Error('Missing notes on success')
      }

      // no existing notes
      if (
        !this.paginatedResults ||
        (this.paginatedResults &&
          this.paginatedResults.notes &&
          !this.paginatedResults.notes.length)
      ) {
        // no results, insert first
        this.insertNote({
          index: 0,
          note: res.body.data,
        })
        this.expand(res.body.data.id)

        return res.body.data
      } else {
        // results exist, add note as the top below pinned

        if (this.paginatedResults.notes.some((note: any) => note.isPinned)) {
          // insert below pinned notes
          const lastPinnedIndex = this.paginatedResults.notes.reduce((acc, note: any, index) => {
            if (note.isPinned) {
              return index
            }

            return acc
          }, 0)

          this.insertNote({
            index: lastPinnedIndex + 1,
            note: res.body.data,
          })
          this.expand(res.body.data.id)
        } else {
          // insert to top of results
          this.insertNote({
            index: 0,
            note: res.body.data,
          })
          this.expand(res.body.data.id)
        }

        // return note to component, allows to focus from component and NOT action
        return res.body.data
      }
    },

    /**
     * Assumes component handles landing / error states, not tracked here
     *
     * @returns {Promise<void>}
     */
    async editNote(formData: Note) {
      if (!formData || !formData.id) {
        throw Error('Missing note id')
      }

      const url = '/notes'

      const res = await fbApi(`${url}/${formData.id}`, {
        method: 'put',
        json: formData,
      })

      if (!res || !res.body || !res.body.data) {
        throw Error('Missing notes on success')
      }

      // replace note in list and set expanded
      if (this.paginatedResults && Array.isArray(this.paginatedResults.notes)) {
        const { notes } = this.paginatedResults
        const foundIndex = notes.findIndex((note) => note.id === res.body.data.id)

        if (foundIndex !== -1) {
          this.replaceNote({
            index: foundIndex,
            note: res.body.data,
          })
          this.expand(res.body.data.id)
        }
      }

      // return note to component, allows to focus from component and NOT action
      return res.body.data
    },

    /**
     * Assumes component handles landing / error states, not tracked here
     * - reload results and return to page 1
     *
     * @returns {Promise<void>}
     */
    async togglePinned(formData: Note) {
      if (!formData || !formData.id) {
        throw Error('Missing note id')
      }

      const res = await fbApi.put(`/notes/${formData.id}`, {
        json: {
          isPinned: formData.isPinned ? 0 : 1,
        },
      })

      if (!res || !res.body || !res.body.data) {
        throw Error('Missing notes on success')
      }

      // return note to component, allows to focus from component and NOT action
      return res.body.data
    },

    /**
     * Assumes component handles landing / error states, not tracked here
     *
     * @returns {Promise<void>}
     */
    async deleteNote({ id }: Note) {
      if (!id) {
        throw Error('Missing note id')
      }
      // remove note in list and set expanded
      const deleteSuccess = () => {
        if (this.paginatedResults && Array.isArray(this.paginatedResults.notes)) {
          const foundIndex = this.paginatedResults.notes.findIndex((note) => note.id === id)

          if (foundIndex !== -1) {
            this.removeNote({
              index: foundIndex,
            })
          }
        }
      }

      const url = '/notes'

      // res.body.data will be empty on delete requests, not checking
      try {
        await fbApi.delete(`${url}/${id}`)

        deleteSuccess()
      } catch (err: any) {
        // if not 404, re-throw for front end to handle
        if (err && err.status !== 404) {
          throw err
        }

        // 404 error, still delete in UI
        deleteSuccess()
      }

      return true
    },

    // reusable scroll for add / edit note form
    // no unit test due to UI interaction / DOM
    scrollToNote({ id }: Note) {
      if (id) {
        const noteEl = document.querySelector(`#note-item-${id}`) as any

        if (noteEl) {
          scrollToElemTop({
            elem: noteEl,
            ms: 350,
          })
        }
      }
    },

    // only used when pinning notes to make sure loader is on screen
    // no unit test due to UI interaction / DOM
    focusHeader() {
      const headerEl = document.querySelector(`#notes-header`) as any

      if (headerEl) {
        // focus title for accessibility
        // will force user to scroll instantly
        headerEl.style.outline = 'none'
        headerEl.setAttribute('tabindex', '-1')
        headerEl.focus()
      }
    },

    reset() {
      this.loading = false
      this.error = ''
      this.paginatedResults = {
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        perPage: 25,
        notes: [],
      }
    },

    setLoading() {
      this.loading = true
      this.error = ''
      this.paginatedResults = {
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        perPage: 25,
        notes: [],
      }
    },

    setError(error: string) {
      this.loading = false
      this.error = error
    },

    setPagination(results: any) {
      this.loading = false
      this.error = ''
      this.paginatedResults = results
    },

    // mutate note within existing results
    replaceNote({ index, note }: { index: number; note: Note }) {
      this.paginatedResults.notes.splice(index, 1, note)
    },

    // mutate note within existing results
    insertNote({ index, note }: { index: number; note: Note }) {
      this.paginatedResults.notes.splice(index, 0, note)
    },

    // mutate note within existing results
    removeNote({ index }: { index: number }) {
      this.paginatedResults.notes.splice(index, 1)
    },

    toggleExpanded(id: number) {
      this.expanded = {
        ...this.expanded,
        [id]: !this.expanded[id],
      }
    },

    expand(id: number) {
      this.expanded = {
        ...this.expanded,
        [id]: true,
      }
    },

    expandAll() {
      if (this.paginatedResults && Array.isArray(this.paginatedResults.notes)) {
        this.expanded = this.paginatedResults.notes.reduce((acc: any, note: any) => {
          acc[note.id] = true
          return acc
        }, {})
      }
    },

    // clear out all ids (will be falsy)
    collapseAll() {
      this.expanded = {}
    },
  },
})

<template>
  <modal
    heading="Add Tags"
    :subheading="entityTitle"
    modal-class=""
    :show-footer="false"
    @close="handleCloseClick"
  >
    <!-- tag form -->
    <manage-tags
      :entity-id="entityId"
      :entity-type="entityType"
      :entity-category="entityCategory"
      :show-header="false"
      :start-add-open="true"
      parent-component="modal"
    />
  </modal>
</template>

<script>
import Modal from './modal.vue'
import ManageTags from '@/components/tags/manage-tags.vue'

/**
 * Modal that holds Manage Tags component.
 */
export default {
  name: 'ModalTags',
  components: { Modal, ManageTags },
  props: {
    entityId: {
      required: true,
      type: Number,
    },
    entityType: {
      default: 'workout',
      type: String,
      validator: (val) => ['workout', 'article', 'wellness_video'].includes(val),
    },
    // only used with articles currently
    entityCategory: {
      default: '',
      type: String,
      validator: (val) => ['', 'recipe'].includes(val),
    },
    entityTitle: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  computed: {},
  methods: {
    handleCloseClick() {
      this.$emit('close')
    },
  },
}
</script>

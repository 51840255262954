import { defineRule, configure } from 'vee-validate'
import {
  required,
  email,
  min,
  max,
  image,
  size,
  url,
  max_value,
  min_value,
  numeric,
} from '@vee-validate/rules'

/**
 * Global vee-validate configuration and globally loaded rules.
 * @see https://vee-validate.logaretm.com/v4/api/configuration#config-options
 *
 * For custom fitness blender rules: validation-rules, which are not loaded globally.
 */
export function initVeeValidate() {
  // global validator rules, be sure to add message in generateMessage
  defineRule('required', required)
  defineRule('email', email)
  defineRule('url', url)
  defineRule('min', min)
  defineRule('max', max)
  defineRule('image', image)
  defineRule('size', size)
  defineRule('max_value', max_value)
  defineRule('min_value', min_value)
  defineRule('numeric', numeric)

  configure({
    // default to blur / change only, only effects <Field />,
    // for useField, see input components
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: false,
    validateOnModelUpdate: false,

    // validation messaging, not using locales, just inline english
    generateMessage: (context): string => {
      if (context?.rule?.name === 'email') {
        return 'Must be a valid email'
      }

      if (context?.rule?.name === 'url') {
        return 'Must be a valid url'
      }

      if (context?.rule?.name === 'numeric') {
        return 'Must be a number'
      }

      if (
        context?.rule?.name === 'min' &&
        Array.isArray(context?.rule?.params) &&
        context.rule.params.length
      ) {
        return `Must be at least ${context.rule.params[0]} characters`
      }

      if (
        context?.rule?.name === 'max' &&
        Array.isArray(context?.rule?.params) &&
        context.rule.params.length
      ) {
        return `Must not be longer than ${context.rule.params[0]} characters`
      }

      if (
        context?.rule?.name === 'min_value' &&
        Array.isArray(context?.rule?.params) &&
        context.rule.params.length
      ) {
        return `Must be more than ${context.rule.params[0]}`
      }

      if (
        context?.rule?.name === 'max_value' &&
        Array.isArray(context?.rule?.params) &&
        context.rule.params.length
      ) {
        return `Must be less than ${context.rule.params[0]}`
      }

      if (context?.rule?.name === 'image') {
        return 'Must be a valid image'
      }

      if (
        context?.rule?.name === 'size' &&
        Array.isArray(context?.rule?.params) &&
        context.rule.params.length
      ) {
        const mb = parseInt(`${context.rule.params[0]}`) / 1024 // convert kb to mb

        return `File must be under ${mb.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })}MB`
      }

      if (
        context?.rule?.name === 'confirmed' &&
        Array.isArray(context?.rule?.params) &&
        context.rule.params.length
      ) {
        // assume second parameter is the name of the other field,
        // vee-validate passes in value as first param
        const otherFieldName = context.rule.params.length > 1 ? context.rule.params[1] : ''
        return `Does not match ${otherFieldName}`
      }

      if (context?.rule?.name === 'alpha_dash') {
        return 'May only contain letters, numbers, and dashes'
      }

      // default / required
      return `${context.field} is required`
    },
  })
}

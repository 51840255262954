<template>
  <div
    role="button"
    tabindex="0"
    :class="['mailcheck-status', { 'has-suggestions': mailCheckSuggestion }]"
    @click="handleCorrection"
    @keydown.enter="handleCorrection"
  >
    Did you mean <span>{{ mailCheckSuggestion }}</span>
  </div>
</template>

<script setup lang="ts">
/**
 * Helper to check if an email address was spelled correctly
 */
import { computed } from 'vue'
import emailSpellChecker from '@zootools/email-spell-checker'

/** PROPS **/
const props = withDefaults(
  defineProps<{
    email: string
  }>(),
  {
    // no defaults
  },
)

/** NON REACTIVE **/
// remove .co as .con should be .com recommendation, not .co
const customPopularTLDS = emailSpellChecker.POPULAR_TLDS.filter((tld) => tld !== 'co')

/** COMPUTED **/
const mailCheckSuggestion = computed((): string => {
  const suggestedEmail = emailSpellChecker.run({
    email: props.email,
    topLevelDomains: customPopularTLDS,
  })

  if (suggestedEmail) {
    return suggestedEmail.full
  }

  return ''
})

/** EMITS **/
const emit = defineEmits(['updateEmail'])

/** METHODS **/
// pass to parent to handle
function handleCorrection() {
  emit('updateEmail', mailCheckSuggestion.value)
}
</script>

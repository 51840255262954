<template>
  <section class="-card-view">
    <div class="card-content -with-extras">
      <a :id="`plan-${plan.id}`" :href="detailViewUrl">
        <div class="content-group -content-asset">
          <div class="group responsive-media">
            <card-src-set-image
              type="card-640"
              :img-prefix="imageUrlPrefix"
              :image="plan.image"
              :title="plan.title"
            />
          </div>
          <plan-card-icon-bar
            :is-exclusive="!!plan.is_exclusive"
            :is-free="!!plan.is_free"
            :entity-type="entityType"
            :entity-id="plan.id"
          />
        </div>
      </a>

      <div class="content-group -content-summary">
        <div class="group">
          <div class="summary-group">
            <a :href="detailViewUrl">
              <h1 class="content-title">
                {{ plan.title }}
                <span v-if="plan.subtitle" class="sub-title"> <br />{{ plan.subtitle }} </span>
              </h1>
              <div class="primary-detail">
                {{ minutesAndWeeksMessaging }}
              </div>
            </a>
          </div>
        </div>
      </div>
      <div v-if="!isSchedulable" class="content-group -content-extras">
        <plan-card-add-to-cart-button
          :plan="plan"
          :plus-pass="plan.plus_pass"
          :image-url-prefix="imageUrlPrefix"
        />
      </div>
    </div>

    <!-- action buttons -->
    <div v-if="isSchedulable" class="card-actions">
      <div class="action-options">
        <div class="schedule-action" :data-tour="isSchedulable ? 'add-to-calendar' : false">
          <calendar-button
            :entity-id="plan.id"
            :entity-type="entityType"
            :entity-title="plan.title"
            :is-schedulable="isSchedulable"
            :is-exclusive="isExclusive"
            :plus-pass="plan.plus_pass"
            :image-url-prefix="imageUrlPrefix"
            :image="plan.image"
          ></calendar-button>
        </div>

        <content-actions-button
          :entity-id="plan.id"
          :entity-type="entityType"
          :entity-title="plan.short_name"
          :shelf-button="isShelfable"
          :view-program-guide-button="true"
          :view-scheduled-button="isSchedulable"
          :view-shopping-list-button="isMealPlan"
          :plan-copy-button="isCopyable"
          data-tour="plan-actions"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import * as planUtils from '@/utils/plans'
import { useUserAuthStore } from '@/stores/user-auth'
import CardSrcSetImage from '@/components/images/card-src-set-image.vue'
import PlanCardIconBar from '@/components/plans/plan-card-icon-bar.vue'
import PlanCardAddToCartButton from '@/components/plans/plan-card-add-to-cart-button.vue'

/** PROPS **/
const props = defineProps({
  plan: {
    type: Object,
    required: true,
  },
  showMinutes: {
    type: Boolean,
    default: true,
  },
  detailView: {
    type: String,
    default: 'sales',
    validator(val) {
      return ['sales', 'myPrograms'].includes(val)
    },
  },
})

/** STATE **/
// pinia stores
const userAuthStore = useUserAuthStore()

/** COMPUTED **/
const isPurchased = computed(() => userAuthStore.isPlanPurchased(props.plan.id))

const isSchedulable = computed(() => {
  const utilParams = {
    user: userAuthStore.user,
    plan: props.plan,
    plusPass: props.plan.plus_pass,
    isPurchased: isPurchased.value,
    isSubscriber: !!userAuthStore.features.EXCLUSIVE_PLANS,
  }

  return planUtils.isSchedulable(utilParams)
})

const detailViewUrl = computed(() => {
  if (isSchedulable.value && props.detailView === 'myPrograms') {
    return `/my/programs/${props.plan.id}`
  }

  return `/plans/${props.plan.seo_url}`
})

// only enabled for workout programs currently
const isShelfable = computed(() => {
  // free users do not have access
  if (!userAuthStore.features.SHELVES) {
    return false
  }

  // if missing taxonomy relationship, cannot check if workout program only
  // so exit
  if (!Array.isArray(props.plan.taxonomy)) {
    return false
  }

  // exclude routines
  if (props.plan.taxonomy.some((tax) => tax.id === 45)) {
    return false
  }

  // workout programs only
  return props.plan.taxonomy.some((tax) => tax.id === 72)
})

// only enabled for workout programs currently
const isCopyable = computed(() => {
  // free users do not have access
  if (!userAuthStore.features.PLAN_COPY) {
    return false
  }

  // if missing taxonomy relationship, cannot check if workout program only
  // so exit
  if (!Array.isArray(props.plan.taxonomy)) {
    return false
  }

  // exclude meal plans but allow workout and pilot programs
  return !props.plan.taxonomy.some((tax) => tax.id === 73)
})

const isMealPlan = computed(() => {
  if (!Array.isArray(props.plan.taxonomy)) {
    return false
  }

  // is meal plan, but not legacy
  return props.plan.taxonomy.some((tax) => tax.id === 73) && !props.plan.is_legacy
})

const minutesAndWeeksMessaging = computed(() => {
  let messagingStr = props.plan.weeks > 1 ? `${props.plan.weeks} Weeks` : `${props.plan.weeks} Week`

  if (props.showMinutes) {
    messagingStr = `${props.plan.minutes_avg} Min/Day • ` + messagingStr
  }

  return messagingStr
})

const entityType = computed(() => {
  return 'plan'
})

const isExclusive = computed(() => {
  return !!props.plan.is_exclusive
})

const imageUrlPrefix = computed(() => {
  const siteAssetsPrefix = userAuthStore.cdn.siteAssetsPrefix || '/siteassets'
  return `${siteAssetsPrefix}/${props.plan.img_dir}`
})
</script>

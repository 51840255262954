<template>
  <div class="fb-plus-free-countdown">
    <span class="fbplus">
      <span class="fb">FB</span>
      <span class="plus">Plus</span>
      <span class="free-access">Free Days</span>
    </span>
    <span v-if="showCountdown && !isLastHour" class="countdown">
      <span class="days">
        <span class="count">{{ remainingDays }}</span>
        <span class="label">{{ remainingDays === 1 ? 'Day' : 'Days' }}</span>
      </span>
      <span class="hours">
        <span class="count">{{ remainingHours }}</span>
        <span class="label">{{ remainingHours === 1 ? 'Hour' : 'Hours' }}</span>
      </span>
    </span>
    <span v-else-if="showCountdown && isLastHour" class="countdown">
      <span class="hours">
        <span class="count">&lt; 1</span>
        <span class="label">Hour</span>
      </span>
    </span>
  </div>
</template>

<script setup lang="ts">
/**
 * Logo with countdown for when the site is in plus free access mode.
 */
import { computed } from 'vue'

/** PROPS **/
const props = withDefaults(
  defineProps<{
    showCountdown: boolean
    remainingDays?: number
    remainingHours?: number
  }>(),
  {
    showCountdown: true,
    remainingDays: 0,
    remainingHours: 0,
  },
)

/** COMPUTED **/
const isLastHour = computed(() => props.remainingDays === 0 && props.remainingHours == 0)
</script>

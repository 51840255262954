<template>
  <modal
    :show-header="false"
    :show-footer="false"
    modal-class="-purchase"
    body-class=""
    @close="handleCloseClick"
  >
    <div class="purchase-body">
      <!-- closer -->
      <button class="close-modal-edge" aria-label="Close" @click="handleCloseClick">
        <span class="material-symbols-outlined" aria-hidden="true">close</span>
      </button>

      <!-- image -->
      <div class="purchase-image">
        <card-src-set-image
          type="browser-select-max-800"
          :img-prefix="imageUrlPrefix"
          :image="plan.image"
          :title="plan.title"
          lazy-loading="eager"
        />
      </div>
      <div
        class="purchase-options"
        :class="{
          '-one': (isPaid && !hasPlusPass) || (!isPaid && hasPlusPass),
          '-two': isPaid && hasPlusPass,
        }"
      >
        <div v-if="isPaid" class="purchase-option">
          <div class="purchase-info">
            <h2>Purchase</h2>

            <ul class="purchase-features">
              <li>Yours to keep indefinitely</li>
              <li>Repeatable with or without a Plus membership</li>
              <li v-if="purchaseIncludesPlusAccessDays">
                Includes {{ plan.access_days }} days of FB Plus Access
              </li>
            </ul>
          </div>
          <div class="purchase-action">
            <div class="purchase-btn">
              <add-to-cart-button
                button-classes="-txt-only -no-hover -p-03 -modal-btn"
                button-label="Buy Program"
                item-type="plan"
                :item-id="plan.id"
              />
            </div>
            <div class="purchase-price">
              <span v-if="plan.is_sale" class="sale-price"> ${{ plan.sale_price }} </span>
              <span
                class="normal-price"
                :class="{
                  '-strike': plan.is_sale,
                }"
              >
                ${{ plan.price }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="hasPlusPass" class="purchase-option">
          <div class="purchase-info">
            <h2 class="heading -secondary">
              <span class="fbplus">
                <span class="fb">FB</span> <span class="plus">Plus</span>
                <span class="aa">Pass</span>
              </span>
            </h2>

            <ul class="purchase-features">
              <li>
                {{ plusPass.access_days }} days of access to FB Plus to complete the selected
                program
              </li>
              <li>Includes access to all FB Plus programs, content and features</li>
            </ul>
          </div>

          <div class="purchase-action">
            <div class="purchase-btn">
              <add-to-cart-button
                button-classes="-txt-only -no-hover -p-01 -modal-btn"
                button-label="Buy Pass"
                item-type="product"
                :item-id="plusPass.id"
                meta-item-from-type="plan"
                :meta-item-from-id="plan.id"
              />
            </div>
            <div class="purchase-price">
              <span v-if="plusPass.is_sale" class="sale-price"> ${{ plusPass.sale_price }} </span>
              <span
                class="normal-price"
                :class="{
                  '-strike': plusPass.is_sale,
                }"
              >
                ${{ plusPass.price }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { useUserAuthStore } from '@/stores/user-auth'
import { mapState } from 'pinia'

import Modal from './modal.vue'
import CardSrcSetImage from '@/components/images/card-src-set-image.vue'
import AddToCartButton from '@/components/cart/add-to-cart-button.vue'

/**
 * Custom modal to show purchase vs fb plus pass.
 */
export default {
  name: 'ModalPlanPurchase',
  components: { CardSrcSetImage, Modal, AddToCartButton },
  props: {
    imageUrlPrefix: {
      type: String,
      default: '',
    },
    plan: {
      type: Object,
      required: true,
    },
    plusPass: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],

  // component state
  data() {
    return {}
  },

  computed: {
    ...mapState(useUserAuthStore, ['features', 'user']),
    isPaid() {
      return !!this.plan.is_paid
    },

    hasPlusPass() {
      return !!(this.plusPass && this.plusPass.id)
    },

    purchaseIncludesPlusAccessDays() {
      // if user has access to any FB Plus only feature, then they do not
      // get a pass.  Checking ads free to determine if subscriber.
      if (this.features.ADS_FREE) {
        return false
      }

      return !!(this.isPaid && this.plan.access_days > 0)
    },
  },

  methods: {
    handleCloseClick() {
      this.$emit('close')
    },
  },
}
</script>

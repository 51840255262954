<template>
  <modal
    :heading="heading"
    modal-class="-confirm-remove"
    :show-footer="false"
    @close="$emit('close')"
  >
    <!-- eslint-disable vue/no-v-html -->
    <p v-if="subheading" class="light" v-html="subheading" />

    <div class="modal-actions">
      <a v-if="url" :href="url" class="btn -main" @click="preventDoubleClick">{{ buttonText }}</a>

      <button v-else href="#" class="btn -main -block" @click.prevent.once="$emit('confirm')">
        {{ buttonText }}
      </button>
      <button type="button" class="btn -link" @click="$emit('close')">{{ cancelText }}</button>
    </div>
  </modal>
</template>

<script>
import { defineComponent } from 'vue'
import modal from './modal.vue'
export default defineComponent({
  name: 'ModalConfirm',
  components: { modal },
  props: {
    heading: {
      type: String,
      default: '',
    },
    subheading: {
      type: String,
      default: '',
    },
    buttonText: {
      default: 'Remove',
      type: String,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },

    // TODO: switch to emitter
    // url will only be used if
    url: {
      type: String,
      default: '',
    },
  },
  emits: ['confirm', 'close'],
  data() {
    return {
      clicked: false,
    }
  },
  methods: {
    // can't figure out how to setup @click.once on a link, so go with this
    preventDoubleClick(e) {
      if (!this.clicked) {
        this.clicked = true
      } else {
        e.preventDefault()
      }
    },
  },
})
</script>

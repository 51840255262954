<template>
  <div
    v-show="!closed"
    class="callout"
    :class="`${type} ${closable ? 'closable' : ''}`"
    aria-live="polite"
    role="status"
  >
    <slot />
    <button
      v-show="closable"
      class="close-callout"
      type="button"
      @click.prevent="toggleClosed"
      aria-label="Close"
    >
      <span class="iconfont-menu-close"></span>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
/**
 * Inline messages, for styling see partials/_modules.scss
 */
export default defineComponent({
  name: 'Callout',
  props: {
    type: {
      type: String,
      required: true,
      validator: (val: string) =>
        ['error', 'warning', 'success', 'info', 'standard', 'dark'].includes(val),
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close-action'],

  // component state
  data() {
    return {
      closed: false,
    }
  },

  // component methods
  methods: {
    toggleClosed() {
      this.closed = true
      this.$emit('close-action')
    },
  },
})
</script>

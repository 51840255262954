<template>
  <div class="complete-day-progress">
    <div class="complete-day-progress-img -has-cover-info">
      <img :src="dayImage" class="videothumb" alt="" />
      <image-icon
        image-icon-set="material-symbols-outlined"
        image-icon="today"
        title=""
        sub-title=""
      />
    </div>
    <div class="complete-day-progress-content">
      <div class="title">{{ dayComplete.scheduleDateFormatted }}</div>
      <div class="day">
        {{ dayComplete.completedCount }} / {{ dayComplete.scheduledCount }} Complete
      </div>
      <div v-if="error" class="ready-to-complete -error" aria-live="polite">Error Saving</div>
      <div v-else-if="!readyToComplete && !completeStatus" class="ready-to-complete">
        {{ dayComplete.dayPercentComplete }}% Complete
      </div>
      <div v-else-if="readyToComplete" class="ready-to-complete">Ready to complete!</div>
      <div v-else-if="completeStatus" class="ready-to-complete" aria-live="polite" role="status">
        {{ selectedType.text }}
      </div>
    </div>
    <div class="complete-day-progress-action">
      <div class="complete-button">
        <icon-button
          :icon="selectedType.icon"
          :classes="`-ico-only -no-container ${readyToComplete ? '-ready' : ''} ${
            error ? '-error' : ''
          }`"
          :label="selectedType.text"
          aria-live="polite"
          @click-action="toggleDayComplete"
        />
      </div>
    </div>
    <div class="progress-container">
      <div
        class="current-progress"
        :class="{
          '-ready': readyToComplete,
        }"
        :style="`width: ${dayComplete.dayPercentComplete}%;`"
      ></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { fbApi } from '@/utils/http-api'
import IconButton from '@/components/buttons/icon-button.vue'
import ImageIcon from '@/components/images/image-icon.vue'

/**
 * Quick day complete on the program slider.  Updates pinia for main day complete
 * buton on calendar day view.
 */
export default defineComponent({
  name: 'DayStatus',
  components: { IconButton, ImageIcon },
  props: {
    // should mirror pinia
    dayComplete: {
      type: Object,
      required: true,
    },
  },
  emits: ['day-complete-updated'],
  data() {
    return {
      loading: false,
      error: '',
      nextCompleteType: null,
    }
  },
  computed: {
    ...mapState(useUserAuthStore, ['features', 'cdn']),

    dayImage() {
      return `${this.cdn.prefix}/assets/img/calendar/day-complete-bg.jpg`
      //return 'https://d1dqye1ps75iz1.cloudfront.net/plan/320/320-1494-fb-plus-short-and-sweet-challenge-under-20-minutes-a-day-b349.jpg';
    },

    completeTypes() {
      // free users
      const types = [
        {
          type: null,
          text: 'Incomplete',
          icon: 'circle',
        },
        {
          type: 0,
          text: 'Day Complete',
          icon: 'check_circle',
        },
      ]

      // plus users get recovery days complete
      if (this.features.REST_DAY_COMPLETE) {
        return [
          ...types,
          {
            type: 1,
            text: 'Recovery Day Complete',
            icon: 'charger',
          },
          {
            type: 2,
            text: 'Active Recovery Day Complete',
            icon: 'charger',
          },
        ]
      }

      return types
    },

    // if processing, instantly flip status in UI
    // while XHR is processing, then revert to prop
    completeStatus() {
      let isCompleteNow = !!this.dayComplete?.isComplete

      if (this.loading) {
        const nextCompleteType = this.getNextType(this.dayComplete.completeType)
        return nextCompleteType !== null
      }

      return isCompleteNow
    },

    // if processing, instantly flip status in UI
    // while XHR is processing, then revert to prop
    selectedType() {
      let completeTypeNow = this.completeTypes.find(
        (completeType) => completeType.type === this.dayComplete.completeType,
      )

      if (this.loading) {
        const nextCompleteType = this.getNextType(this.dayComplete.completeType)
        completeTypeNow = this.completeTypes.find(
          (completeType) => completeType.type === nextCompleteType,
        )
      }

      return completeTypeNow
    },

    readyToComplete() {
      if (
        this.dayComplete.scheduledCount === this.dayComplete.completedCount &&
        !this.completeStatus
      ) {
        return true
      }

      return false
    },
  },
  methods: {
    // this is not intelligent, just returns a hard coded next type
    getNextType(completeTypeId) {
      // if day complete, return incomplete for free, recovery day complete for plus
      if (completeTypeId === 0) {
        return this.features.REST_DAY_COMPLETE ? 1 : null
      }

      // if recovery day complete, return active recovery day
      if (completeTypeId === 1) {
        return this.features.REST_DAY_COMPLETE ? 2 : null
      }

      // if active recovery day complete, return incomplete
      if (completeTypeId === 2) {
        return null
      }

      // day complete
      return 0
    },

    /**
     * Toggles day complete
     */
    async toggleDayComplete() {
      // disable double submit
      if (this.loading) {
        return
      }

      // set loading and instantly toggle state (assuming API will be success)
      // for quick UI.  This will undo if error
      this.loading = true
      this.error = ''

      // get next type_id for plan day schedule
      const nextCompleteType = this.getNextType(this.dayComplete.completeType)

      try {
        await fbApi.post(
          `/calendar/days/${nextCompleteType !== null ? 'complete' : 'incomplete'}`,
          {
            skipErrorModal: true,
            json: {
              scheduleDate: this.dayComplete.scheduleDate,
              completeType: nextCompleteType,
            },
          },
        )

        // only emit on success
        this.$emit('day-complete-updated', {
          completeType: nextCompleteType,
          isComplete: this.completeStatus,
        })
      } catch (e) {
        this.error = 'Error completing day'
      } finally {
        // re-enable button action
        this.loading = false
      }
    },
  },
})
</script>

<template>
  <modal
    :heading="state.heading"
    subheading=""
    modal-class=""
    body-class="modal__body -tight"
    :show-footer="false"
    @close="emit('close')"
  >
    <template #default>
      <div v-if="state.screen === 'copy'">
        <callout v-if="state.error" type="error" :closable="false">
          <div>{{ state.error }}</div>
        </callout>

        <p v-if="copyType === 'grouping'" class="light">
          You swapped workouts for "{{ planShortName }}." Would you like to save a copy so you can
          complete this personalized program again?
        </p>
        <p v-if="copyType === 'plan'" class="light">
          Would you like to save a copy of "{{ planShortName }}" as a draft routine to customize
          with your own workout options?
        </p>

        <div class="modal-actions">
          <button
            class="btn -main -block"
            :disabled="state.loading ? true : undefined"
            aria-live="polite"
            @click="handleCopy"
          >
            {{ state.loading ? 'Saving...' : 'Copy to Routines' }}
          </button>

          <button
            class="btn -link"
            :disabled="state.loading ? true : undefined"
            @click="emit('close')"
          >
            Cancel
          </button>
        </div>
      </div>
      <div v-if="state.screen === 'success'">
        <p class="light">
          Great! You now have a new draft routine called "{{ state.data.title }}." You can continue
          customizing it, and when you are ready, select publish to start using it.
        </p>
        <ul class="modal__link-list">
          <li>
            <a :href="`/my/routines/${state.data.id}/edit-schedule`" @click="emit('close')">
              Edit Routine
            </a>
          </li>
          <li>
            <a href="/videos" @click="emit('close')"> Add FB Workouts to Routine </a>
          </li>
          <li>
            <a href="/my/custom-workouts" @click="emit('close')">
              Add Custom Workouts to Routine
            </a>
          </li>
          <li>
            <a href="/my/routines" @click="emit('close')">View "My Routines"</a>
          </li>
        </ul>
      </div>
    </template>
  </modal>
</template>

<script setup>
import { ref } from 'vue'
import { fbApi } from '@/utils/http-api'
import Callout from '@/components/notifications/callout.vue'
import Modal from './modal.vue'

/** PROPS **/
const props = defineProps({
  // plan, grouping
  copyType: {
    type: String,
    default: 'plan',
    validator: (val) => ['plan', 'grouping'].includes(val),
  },
  groupingId: {
    type: Number,
    default: null,
  },
  planId: {
    type: Number,
    required: true,
  },
  planShortName: {
    type: String,
    required: true,
  },
})

/** STATE **/
const state = ref({
  screen: 'copy',
  heading: 'Save a Copy of this Program?',
  loading: false,
  error: '',
  data: null,
})

/** EMITS **/
const emit = defineEmits(['close'])

/** METHODS **/
async function handleCopy() {
  // no double submit
  if (state.value.loading) {
    return false
  }

  // reset
  state.value.error = ''
  state.value.loading = true

  try {
    // save a copy of a grouping / scheduled instance
    let body = {}

    if (props.groupingId) {
      body.grouping_id = props.groupingId
    }

    const res = await fbApi.post(`/plans/${props.planId}/copy`, {
      json: body,

      // self handles errors
      skipErrorModal: true,
    })

    // missing data, shouldn't happen
    if (!res || !res.body || !res.body.data) {
      throw new Error(`Missing response data`)
    }

    state.value.heading = 'Successfully Saved Copy!'
    state.value.screen = 'success'
    state.value.data = res.body.data
  } catch (err) {
    state.value.error = 'Error saving copy, please refresh the page and try again'
  } finally {
    state.value.loading = false
  }
}
</script>

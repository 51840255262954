<template>
  <button
    v-show="showButton"
    class="cky-banner-element"
    :class="{
      'btn -anchor-link': location === 'footer',
      'btn -main': location === 'account-data',
      'btn -dark': location === 'embed',
    }"
  >
    Cookie Preferences
  </button>
</template>

<script setup lang="ts">
/**
 * Button to open the CookieYes.com cookie preferences modal
 * - only will show to EU users (if banner was shown, cookieyes does geoip check)
 * - will be hidden to US users (even if enabled, doesn't work due to geoip)
 *
 * EVENTS
 * @see https://www.cookieyes.com/documentation/events-on-cookie-banner-load/
 * @see https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
 *
 * USE CASES
 * [x] - disabled cookieyes in local dev env
 * [x] - browser extension blocking cookieyes
 * - enabled for US user
 * - enabled for EU user
 */
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import { useUserAuthStore } from '@/stores/user-auth'
import { fbApi } from '@/utils/http-api'

/** PROPS **/
const props = withDefaults(
  defineProps<{
    location?: 'footer' | 'account-data' | 'embed'

    // turn off if you have two cookie-consent-button components on the same page
    // as it would invoke the handler for 'cookieyes_consent_update' twice
    // EX: account-data.blade.php
    handleEvents?: boolean
  }>(),
  {
    location: 'footer',
    handleEvents: true,
  },
)

/** STATE **/
const showButton = ref(false)
const intervalId = ref<number | ReturnType<typeof setInterval>>(0)
const userAuthStore = useUserAuthStore()

/** LIFECYCLE **/
onBeforeMount(() => {
  if (props.handleEvents) {
    document.addEventListener('cookieyes_banner_load', handleBannerLoaded)
    document.addEventListener('cookieyes_consent_update', handleConsentUpdate)
  }
})

onMounted(() => {
  handleCookieyesDefaults()
})

onBeforeUnmount(() => {
  if (props.handleEvents) {
    document.removeEventListener('cookieyes_banner_load', handleBannerLoaded)
    document.removeEventListener('cookieyes_consent_update', handleConsentUpdate)
  }

  if (intervalId.value) {
    clearInterval(Number(intervalId.value))
  }
})

/** METHODS **/
function syncCkyConsent() {
  const { getCkyConsent } = window

  if (getCkyConsent) {
    const currentConsents = getCkyConsent()
    const syncConsent = {
      STORAGE_NECESSARY: true,
      STORAGE_FUNCTIONALITY: !!currentConsents.categories.functional,
      STORAGE_ANALYTICS: !!currentConsents.categories.analytics,
      STORAGE_ADS: !!currentConsents.categories.advertisement,
      STORAGE_OTHER: !!currentConsents.categories.other,
    }

    // console.log('syncCkyConsent updateConsents', syncConsent)
    userAuthStore.updateConsents(syncConsent)
  }
}

/**
 * Cookieyes may be blocked by adblockers... in this case we tried to do
 * it "the right way". Fallback to enabling necessary and functional
 * cookies so videos load so US users do not get stuck unable to view YouTube embeds.
 */
function handleCookieyesDefaults() {
  // use this version for production
  const limitedConsent = {
    STORAGE_NECESSARY: true,
    STORAGE_FUNCTIONALITY: true,
    STORAGE_ANALYTICS: false,
    STORAGE_ADS: false,
    STORAGE_OTHER: false,
  }

  // use this version if you want to temporarily test local dev
  // third party "market units" that requires STORAGE_ADS to be true
  // const limitedConsent = {
  //   STORAGE_NECESSARY: true,
  //   STORAGE_FUNCTIONALITY: true,
  //   STORAGE_ANALYTICS: true,
  //   STORAGE_ADS: true,
  //   STORAGE_OTHER: true,
  // }

  // entire script tag is missing, should only happen in local dev
  // env as id="cookieyes" will attach to window
  if (!window || !window.cookieyes) {
    console.warn('cookieyes missing, using defaults')
    userAuthStore.updateConsents(limitedConsent)
    return
  }

  // cookieyes creates this function, if it is set, we are good to sync
  if (window.getCkyConsent) {
    syncCkyConsent()
    return
  }

  // if we are here, window.cookieyess exists because the DOM node was created (id="cookieyes)
  // but the getCkyConsent function is undefined, try to wait for it before
  // falling back to US defaults
  const maxAttempts = 18 // 18 * 25ms = 500ms
  let attempts = 0

  intervalId.value = setInterval(() => {
    // console.log('handleCookieyesMissing attempt', attempts)
    attempts += 1

    // cookieyes loaded
    if (window.getCkyConsent) {
      clearInterval(Number(intervalId.value))
      syncCkyConsent()
      return
    }

    // stop trying
    if (maxAttempts === attempts) {
      clearInterval(Number(intervalId.value))
      console.warn('cookieyes set, but getCkyConsent missing, using defaults')
      userAuthStore.updateConsents(limitedConsent)
      return
    }
  }, 25)
}

/**
 * @see https://www.cookieyes.com/documentation/events-on-cookie-banner-load/
 */
function handleBannerLoaded(consentEvent: any) {
  // console.log('handleBannerLoaded', consentEvent)

  // shouldn't happen unless the script tag is missing as it has id="cookieyes"
  // which attaches to window
  if (!window || !window.cookieyes) {
    return
  }

  const consent = {
    STORAGE_NECESSARY: true,
    STORAGE_FUNCTIONALITY: !!consentEvent.detail.categories.functional,
    STORAGE_ANALYTICS: !!consentEvent.detail.categories.analytics,
    STORAGE_ADS: !!consentEvent.detail.categories.advertisement,
    STORAGE_OTHER: !!consentEvent.detail.categories.other,
  }

  // console.log('handleBannerLoaded updateConsents', consent)

  // assume payload data is set
  userAuthStore.updateConsents(consent)

  // enable button if activeLaw is anything except ''
  // ex: 'gdpr', 'ccpa', ''
  showButton.value = !!consentEvent.detail.activeLaw
}

/**
 * Track consent setting changes
 * @see https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
 *
 * initial state for all users
 * cookieyes._ckyStore._bannerDisplayState === 'none'
 *
 * US Users
 * - fires on initial page load always
 * - user cannot change settings (cookie consent button and banner not shown)
 * - fires on secondary page loads
 * - never logs to db
 *
 * EU Users
 * - does NOT fire on initial load
 * - fires when user changes settings
 * - does NOT fire on secondary page load
 * - logs to db
 */
async function handleConsentUpdate(consentEvent: any) {
  // console.log('invoked handleConsentUpdate', consentEvent)
  // console.log(
  //   'handleConsentUpdate _ckyStore',
  //   JSON.parse(JSON.stringify(window.cookieyes._ckyStore)),
  // )
  // console.log('handleConsentUpdate _ckyConsentStore', Array.from(window.cookieyes._ckyConsentStore))

  // shouldn't happen unless the script tag is missing as it has id="cookieyes"
  // which attaches to window
  if (!window || !window.cookieyes) {
    return
  }

  const { cookieyes, getCkyConsent } = window
  const { accepted } = consentEvent.detail

  const consent = {
    STORAGE_NECESSARY: true,
    STORAGE_FUNCTIONALITY: accepted.includes('functional'),
    STORAGE_ANALYTICS: accepted.includes('analytics'),
    STORAGE_ADS: accepted.includes('advertisement'),
    STORAGE_OTHER: accepted.includes('other'),
  }

  // console.log('handleConsentUpdate updateConsents', consent)
  userAuthStore.updateConsents(consent)

  // if not logged in, do not fire XHR
  if (!userAuthStore.user.isLoggedIn) {
    return
  }

  if (!getCkyConsent) {
    console.warn('handleConsentUpdate getCkyConsent() missing, skip tracking')
    return
  }

  const currentConsent = getCkyConsent()
  // console.log('handleConsentUpdate getCkyConsent()', currentConsent)

  // US users, check active consent law and do not fire XHR if activeLaw is ''
  if (!currentConsent.activeLaw) {
    // console.log('handleConsentUpdate no activeLaw, skip tracking')
    return
  }

  // EU users, log to db
  const reqBody = {
    consent,

    // log data
    consentId: currentConsent.consentID || '',
    activeLaw: currentConsent.activeLaw || '',
    _bannerDisplayState: cookieyes?._ckyStore?._bannerDisplayState || '',
  }

  try {
    await fbApi.post('/my/consents/storage', {
      json: reqBody,
      skipErrorModal: true,
    })
  } catch (err) {
    console.error(err)
  }
}
</script>

import { defineAsyncComponent } from 'vue'
import type { App } from 'vue'

/**
 * Discussion components tied to code splitting / user chunk
 */
const discussionsCodeSplit = (app: App) => {
  app.component(
    'DiscussionFooter',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '@/components/discussions/discussion-footer.vue'),
    ),
  )

  app.component(
    'DiscussionControls',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "user" */ '@/components/discussions/discussions-controls.vue'),
    ),
  )

  app.component(
    'DiscussionForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '@/components/discussions/discussion-form.vue'),
    ),
  )
}

export default discussionsCodeSplit

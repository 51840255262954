<template>
  <div v-if="!makingApiCall" class="reaction-options">
    <visual-input
      v-for="likeEmote in likeEmotes"
      :key="likeEmote.reactTypeId"
      v-model.number="selectedReact"
      type="radio"
      :input-id="`react_radio_${likeableId}_${likeEmote.reactTypeId}`"
      :name="`react_type_id_${likeableId}`"
      :checked-value="likeEmote.reactTypeId"
      :label="likeEmote.reactTypeEmoji"
      validate-as="React type"
      @change="handleSaveLikeReact"
    >
      <span class="btn -material -ico -plain">
        {{ likeEmote.reactTypeEmoji }}
      </span>
    </visual-input>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { useModalsStore } from '@/stores/modals'
import { fbApi } from '@/utils/http-api'
import VisualInput from '@/components/forms/visual-input.vue'
import type { CommentLiked, LikeEmoteStat } from '@/types/comment-types'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'LikeReactForm',
  components: { VisualInput },
  props: {
    liked: {
      type: Object as PropType<CommentLiked | null>,
      default: null,
    },
    likeableId: {
      type: Number,
      required: true,
    },
    likeableType: {
      type: String,
      required: true,
    },
    likeEmotes: {
      type: Object as PropType<Record<number, LikeEmoteStat>>,
      required: true,
    },
  },
  emits: ['like-decremented', 'like-incremented', 'updated-like', 'close-react-form'],
  data() {
    return {
      selectedReact: this.liked?.reactTypeId ? this.liked.reactTypeId : 0,
      makingApiCall: false,
    }
  },

  computed: {
    ...mapState(useUserAuthStore, ['user']),
  },

  methods: {
    ...mapActions(useModalsStore, ['showLoginModal']),

    handleSaveLikeReact() {
      if (!this.user.isLoggedIn) {
        this.showLoginModal()
        return
      }

      this.$emit('close-react-form')

      if (this.makingApiCall) {
        return
      }

      // no current Like React for user on Likeable Id + Type, create new one
      if (this.liked === null) {
        this.addLike()
        return
      }

      // previously selected react was selected again, remove the like react
      if (this.selectedReact === this.liked.reactTypeId) {
        this.removeLike()
        return
      }

      // previously selected like react differs from currently selected, update the like's
      // react to the currently selected react
      if (this.liked.reactTypeId !== this.selectedReact) {
        this.updateLike()
        return
      }
    },

    async addLike() {
      this.makingApiCall = true

      this.$emit('like-incremented', this.selectedReact)

      try {
        const res = await fbApi.post('/like', {
          json: {
            likeable_type: this.likeableType,
            likeable_id: this.likeableId,
            react_type_id: this.selectedReact,
          },
        })

        if (!res || !res.body || !res.body.data) {
          throw new Error(`Unable to Add Like, please try again`)
        }

        this.$emit('updated-like', res.body.data)
      } catch (err) {
        this.$emit('like-decremented', this.selectedReact)
        this.$emit('updated-like', null)
      } finally {
        this.makingApiCall = false
      }
    },

    async removeLike() {
      if (!this.liked) {
        return
      }

      this.makingApiCall = true

      this.$emit('like-decremented', this.liked.reactTypeId)

      try {
        await fbApi.delete(`/like/${this.liked.id}`, {
          json: {
            likeable_type: this.likeableType,
            likeable_id: this.likeableId,
            react_type_id: this.selectedReact,
          },
        })

        this.$emit('updated-like', null)
      } catch (err) {
        this.$emit('like-incremented', this.selectedReact)
        this.$emit('updated-like', this.liked)
      } finally {
        this.makingApiCall = false
      }
    },

    async updateLike() {
      if (!this.liked) {
        return
      }

      this.makingApiCall = true

      this.$emit('like-decremented', this.liked.reactTypeId)
      this.$emit('like-incremented', this.selectedReact)

      try {
        const res = await fbApi.put(`/like/${this.liked.id}`, {
          json: {
            likeable_type: this.likeableType,
            likeable_id: this.likeableId,
            react_type_id: this.selectedReact,
          },
        })

        if (!res || !res.body || !res.body.data) {
          throw new Error(`Unable to Update Like, please try again`)
        }

        this.$emit('updated-like', res.body.data)
      } catch (err) {
        this.$emit('like-decremented', this.selectedReact)
        this.$emit('like-incremented', this.liked.reactTypeId)
        this.$emit('updated-like', this.liked)
      } finally {
        this.makingApiCall = false
      }
    },
  },
})
</script>

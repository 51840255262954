<template>
  <modal
    heading="Report"
    subheading="Help us understand what's wrong."
    modal-class="-flag"
    body-class=""
    @close="handleClose"
  >
    <form v-if="step === 'form'" class="modal__form" @submit.prevent="submit($event)">
      <div v-for="radio in radios" :key="radio.key" class="radio">
        <input
          :id="radio.key"
          v-model.lazy="picked"
          :value="radio.key"
          :name="`${flaggableType}_radio`"
          type="radio"
        />
        <label :for="radio.key"
          ><span>{{ radio.val }}</span></label
        >
      </div>
      <div class="form__group">
        <label for="reason" class="sr-only">Reason</label>
        <textarea
          id="reason"
          v-model.lazy.trim="reason"
          name="reason"
          placeholder="Reason (optional)"
          rows="2"
        />
      </div>
      <div class="action__group form-actions -flex -center">
        <div class="form-action">
          <button :disabled="makingApiCall ? true : undefined" class="btn -main">Report</button>
        </div>
        <div class="form-action">
          <button class="btn -link" @click.prevent="handleClose">Cancel</button>
        </div>
      </div>
    </form>

    <div v-if="step === 'end'" class="modal__body">
      <p>
        You’ve selected <strong>{{ pickedText }}</strong
        >. We will look into this and resolve any issues based on your report. Thank you!
      </p>
      <button @click="step = 'form'">Change Selection</button>
    </div>

    <footer v-if="step === 'end'" class="modal__footer">
      <button @click.prevent="handleClose">Close Window</button>
    </footer>
  </modal>
</template>

<script>
import { defineComponent } from 'vue'
import modal from './modal.vue'
import { fbApi } from '@/utils/http-api'

export default defineComponent({
  name: 'ModalFlag',
  components: { modal },
  props: {
    flaggableId: {
      type: Number,
      required: true,
    },
    flaggableType: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'flag-updated'],
  data() {
    return {
      picked: 'spam',
      reason: '',
      step: 'form',
      pickedText: '',
      makingApiCall: false,
      radios: [
        {
          id: 1,
          key: 'spam',
          val: 'Unwanted commercial content or spam',
        },
        {
          id: 2,
          key: 'pornography',
          val: 'Pornography or sexually explicit material',
        },
        {
          id: 3,
          key: 'hate_speech',
          val: 'Hate speech or graphic violence',
        },
        {
          id: 4,
          key: 'bullying',
          val: 'Harassment or bullying',
        },
      ],
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },

    async submit() {
      // disable double submit
      if (this.makingApiCall) {
        return
      }

      const flag = this.radios.filter((radio) => this.picked === radio.key)[0]
      const reason = this.reason || flag.val

      this.pickedText = flag.val

      this.makingApiCall = true

      try {
        await fbApi.post('/flag/add', {
          json: {
            flaggable_type: this.flaggableType,
            flaggable_id: this.flaggableId,
            reason,
            report_type_id: flag.id,
          },
        })

        this.makingApiCall = false
        this.step = 'end'

        this.$emit('flag-updated')
      } catch (err) {
        this.makingApiCall = false
        this.handleClose()
      }
    },
  },
})
</script>

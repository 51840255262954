import smoothScroll from './smoothscroll-fb-forked'
import { HEADER_HEIGHT } from '@/constants'

/**
 * Internal helper for anchor links smooth scrolling
 */
function anchorLinkHandler(clickEvent: MouseEvent) {
  if (clickEvent.defaultPrevented) {
    return
  }
  const anchorLink = clickEvent.currentTarget

  if (!(anchorLink instanceof HTMLAnchorElement)) {
    return
  }

  clickEvent.preventDefault()

  if (location.hash !== anchorLink.hash) {
    // @ts-ignore
    window.history.pushState(null, null, anchorLink.hash)
  }
  // using the history api to solve issue #1 - back doesn't work
  // most browser don't update :target when the history api is used:
  // THIS IS A BUG FROM THE BROWSERS.
  // change the scrolling duration in this call
  const node = document.getElementById(anchorLink.hash.substring(1))
  if (!node) {
    return
  } // Do not scroll to non-existing node

  scrollToElemTop({
    elem: node,
    ms: 400,
    // this will cause the :target to be activated.
    _smoothScrollCallback: () => location.replace('#' + node.id),
  })
}

/**
 * Helper to scroll to the top of an element.
 */
export function scrollToElemTop({
  elem,
  includeHeaderOffsetY = true,
  extraOffsetY = 0,
  ms = 500,
  _smoothScrollCallback = null,
}: {
  elem: HTMLElement
  includeHeaderOffsetY?: boolean
  extraOffsetY?: number
  ms?: number
  _smoothScrollCallback?: Function | null
}) {
  // bounding box offset top
  if (elem) {
    const elemTop = elem.getBoundingClientRect().top

    // scroll offset
    let offset = window.scrollY || window.pageYOffset

    if (includeHeaderOffsetY) {
      offset -= HEADER_HEIGHT
    }

    if (extraOffsetY) {
      offset -= extraOffsetY
    }

    const scrollTo = elemTop + offset

    // @ts-ignore
    smoothScroll(scrollTo, ms, _smoothScrollCallback)
  }
}

/**
 * attached smooth scrolling to .go-to on dom load
 * can be the following formats (and accounts for header):
 * <a href="#" data-target="comments" class="comment-button go-to"></a>
 *
 * Note - that any <a href="#rea-anchor">link</a> is automatically picked up by smoothscroll
 * Note 2 -use class="scroll-dest" on any anchor id destination element.  It will handle header padding.
 */
export function scrollToInit() {
  const anchorLinks = document.querySelectorAll('a[href^="#"]:not([href="#"])')

  for (let i = 0; i < anchorLinks.length; i++) {
    const anchorLink = anchorLinks[i]
    // @ts-ignore
    anchorLink.addEventListener('click', anchorLinkHandler, false)
  }
}

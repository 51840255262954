import { defineStore } from 'pinia'
import { fbApi, objToSearchParams } from '@/utils/http-api'
import dayjs from 'dayjs'
import type {
  ToursState,
  QueuedTourItem,
  ExitTourPayload,
  TourScript,
  TourScriptItem,
  MyToursResponse,
  TourStepCompleteStatus,
} from '@/types/tour-types'

export const useToursStore = defineStore('tours', {
  state: (): ToursState => ({
    availableTourIds:
      window.Laravel && window.Laravel.tours && window.Laravel.tours.availableTourIds
        ? window.Laravel.tours.availableTourIds
        : [],
    fetched: false,
    loading: false,
    error: '',
    queuedTours: [],
    activeTourId: '',
    tourScripts: null,
  }),

  getters: {
    activeTourScript: (state: ToursState) => {
      if (!state.fetched) {
        return null
      }

      if (!state.activeTourId || !state.tourScripts || !state.tourScripts[state.activeTourId]) {
        return null
      }

      return state.tourScripts[state.activeTourId]
    },
  },

  actions: {
    /**
     * Fetch tour scripts and map into state
     */
    async initAvailableTours(availableTourIds: Array<string>) {
      // force array
      const tourIds = Array.isArray(availableTourIds) ? availableTourIds : [availableTourIds]

      // no XHR if no availableTourIds
      if (!availableTourIds.length) {
        this.setTourScripts(null)
        return
      }

      this.fetching(tourIds)

      try {
        const res = await fbApi.get('/my/tours', {
          searchParams: objToSearchParams({
            tourId: tourIds,
          }),
          skipErrorModal: true,
        })

        const { tours, active } = res.body.data as MyToursResponse

        // load tour scripts async in separate chunk so it doesn't get
        // bundled with misc-ctas chunk
        return import(/* webpackChunkName: "tour-scripts" */ '@/tours').then((toursModule) => {
          const tourScripts = toursModule.default as unknown as TourScript

          // update userSeenTour property
          if (tours) {
            tours.forEach((tour: TourStepCompleteStatus) => {
              if (!tour.tourId) {
                return
              }

              // even if not complete, still track as seen, user may have
              // exited and do not want to show tour twice automatically
              if (tourScripts[tour.tourId]) {
                tourScripts[tour.tourId].userSeenTour = true
              }
            })
          }

          this.setTourScripts(tourScripts)
          //end userSeenTour property

          // for multi-page guided tours, force load the next tourId step
          // regardless if user has seen tour or not
          if (active && active.tourId && tourScripts[active.tourId]) {
            const isActiveTourAvailable = this.availableTourIds.find(
              (availableTourId) => availableTourId === active.tourId,
            )

            if (isActiveTourAvailable) {
              this.loadTour({
                guidedTourId: active.guidedTourId,
                tourId: active.tourId,
                force: true,
              })
            }
          }
          // end multi-page tour load

          // attempt to load ONE queued tour, priority is forced tour, then unseen tour
          if (this.queuedTours.length) {
            const { queuedTours } = this

            const forceTour = queuedTours.find((queuedTour) => {
              return queuedTour.force && tourScripts[queuedTour.tourId]
            })

            const tourNotSeen = queuedTours.find((queuedTour) => {
              return tourScripts[queuedTour.tourId] && !tourScripts[queuedTour.tourId].userSeenTour
            })

            this.clearQueuedTours()

            if (forceTour) {
              this.loadTour(forceTour)
              return
            }

            if (tourNotSeen) {
              this.loadTour(tourNotSeen)
            }
          }
        })
      } catch (e) {
        this.setError('Error loading tours')
      }
    },

    /**
     * Load with full checks, this should be the main method to use to load a tour.
     */
    async loadTour(tour: QueuedTourItem) {
      // if initAvailableTours XHR is still loading, queue to load after
      // XHR is complete...
      if (!this.fetched) {
        this.queueTour(tour)
        return
      }

      // existence checks
      if (!tour.tourId || !this.tourScripts || !this.tourScripts[tour.tourId]) {
        return
      }

      const tourScript = this.tourScripts[tour.tourId]

      // seen check, only show tour to user once unless force loading tour
      // which shouldn't happen unless user clicked CTA to manually load tour
      if (!tour.force && tourScript.userSeenTour) {
        return
      }

      // expires check
      if (tourScript.expDate) {
        const userNow = dayjs()
        const expiresAt = dayjs(tourScript.expDate, 'YYYY-MM-DD')

        if (userNow.isAfter(expiresAt)) {
          return
        }
      }
      // end checks

      // delay load, do not wait for XHR to finish
      if (tourScript.loadDelayMs) {
        setTimeout(() => {
          this.setActiveTourId(tour.tourId)
          this.progressGuidedTourToNextStep(tourScript)
        }, tourScript.loadDelayMs)
        return
      }

      // no delay load, do not wait for XHR to finish
      this.setActiveTourId(tour.tourId)
      this.progressGuidedTourToNextStep(tourScript)
    },

    // traverse each guided tour list of tour ids
    async progressGuidedTourToNextStep(tourScript: TourScriptItem) {
      // not a guided tour
      if (!tourScript.guidedTourId) {
        return
      }

      // if condition and not met, no change, keep active tour on the same tour id
      if (tourScript.loadCondition?.selectorExists) {
        const conditionElem = document.querySelector(tourScript.loadCondition.selectorExists)

        if (!conditionElem) {
          return
        }
      }

      // update active tour to the next tour id in the guided tour
      if (tourScript.nextTourId) {
        await fbApi.post('/my/tours/active', {
          json: {
            guidedTourId: tourScript.guidedTourId,
            tourId: tourScript.nextTourId,
          },
          skipErrorModal: true,
        })
        return
      }

      // clear active tour, no load conditions or next tour id
      await fbApi.delete('/my/tours/active', {
        skipErrorModal: true,
      })
    },

    /**
     * Set active tour, regardless of what is within tour scripts.  If force passed
     * in, then attempt to load first tour within the guided tour.
     */
    async startGuidedTour(tour: QueuedTourItem) {
      try {
        await fbApi.post('/my/tours/active', {
          json: {
            guidedTourId: tour.guidedTourId,
            tourId: tour.tourId,
          },
          skipErrorModal: true,
        })

        // attempt to load tour if forced, useful if starting tour from same view
        // as guided tour step 1
        if (tour.force) {
          // if initAvailableTours XHR is still loading, queue to load after
          // XHR is complete...
          if (!this.fetched) {
            this.queueTour(tour)
            return
          }

          this.loadTour(tour)
        }
      } catch (e) {
        this.setError('Error starting guided tour')
      }
    },

    /**
     * Exit tracking for single tourId (not guided tour)
     */
    async logAndExitTour(payload: ExitTourPayload) {
      try {
        // instantly close tour
        this.clearActiveTourId()

        // background XHR to log tour
        // does not clear active multi page tour
        await fbApi.post('/my/tours', {
          json: payload,
          skipErrorModal: true,
        })

        // update user seen
        this.setTourScriptSeen(payload.tourId)

        if (payload.exitUrl && payload.exitUrl !== 'close') {
          window.location.href = payload.exitUrl
        }
      } catch (e) {
        this.setError('Error logging exit tour')
      }
    },

    /**
     * Exit tracking for single tourId as well as clearing the guided tour itself
     */
    async logAndExitGuidedTour(payload: ExitTourPayload) {
      try {
        // instantly close tour
        this.clearActiveTourId()

        // background XHR to log tour and clear active multi page tour
        await Promise.all([
          fbApi.post('/my/tours', {
            json: payload,
            skipErrorModal: true,
          }),
          fbApi.delete('/my/tours/active', {
            skipErrorModal: true,
          }),
        ])

        // update user seen
        this.setTourScriptSeen(payload.tourId)
      } catch (e) {
        this.setError('Error logging exit tour')
      }
    },

    // initial XHR helpers
    fetching(availableTourIds: Array<string>) {
      this.fetched = false
      this.loading = true
      this.error = ''

      this.availableTourIds = availableTourIds
    },

    setError(error: string) {
      this.fetched = true
      this.loading = false
      this.error = error
    },

    setTourScripts(tourScripts: TourScript | null) {
      this.fetched = true
      this.loading = false
      this.error = ''
      this.tourScripts = tourScripts
    },

    setTourScriptSeen(tourId: string) {
      if (!this.tourScripts || !this.tourScripts[tourId]) {
        return
      }

      this.tourScripts[tourId] = {
        ...this.tourScripts[tourId],
        userSeenTour: true,
      }
    },

    queueTour(tour: QueuedTourItem) {
      const found = this.queuedTours.some((queuedTour) => queuedTour.tourId === tour.tourId)

      // do not add twice to queue
      if (found) {
        return
      }

      this.queuedTours = [...this.queuedTours, tour]
    },

    clearQueuedTours() {
      this.queuedTours = []
    },

    // shouldn't use this directly, go through loadTour action
    setActiveTourId(tourId: string) {
      this.activeTourId = tourId
    },

    clearActiveTourId() {
      this.activeTourId = ''
    },
  },
})

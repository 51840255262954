/**
 * Proxy to track pageviews through analytics services
 *
 * REFERRER NOTE
 * Plausible sets the referrer on the first server rendered page load,
 * for SPA pages, the "r" attribute (referrer) will not change.
 *
 * Per support ticket, this is not an issue with funnels and they have
 * light session / user identification and can tell previous page visits
 * within the session.
 */
export function trackPageView() {
  // Analytics | GA was disabled July 2023
  if (window && window.gtag) {
    window.gtag('set', 'page_path', `${window.location.pathname}${window.location.search}`)
  }

  if (window && window.plausible) {
    const spaPageView = {
      u: `${window.location.origin}${window.location.pathname}${window.location.search}`,
    }

    window.plausible('pageview', spaPageView)
  }
}

/**
 * Proxy to track event through analytics services
 */
export function trackEvent({
  action = '',
  category = '',
  label = '',
  extraData = {},
  transport = '',
  callback = null,
}) {
  if (window && window.plausible) {
    const eventPayload = {
      props: {
        ...extraData,
        label,
        action,
      },
    }

    if (callback) {
      eventPayload.callback = callback
    }

    // console.log('event', category, eventPayload)
    window.plausible(category, eventPayload)
  }
}

/**
 * Helper method that can be used to attached to future dynamic links
 * after dom loads
 */
export function trackLink(e) {
  if (window && (window.gtag || window.plausible)) {
    e.preventDefault()

    // extract data attributes
    const { dataset } = e.currentTarget
    const url = e.currentTarget.href
    // console.log('send', 'event', dataset.category, dataset.action, dataset.label, url);

    // safe handler to only trigger once
    let redirecting = false
    const redirect = () => {
      if (redirecting) {
        return
      }

      redirecting = true
      document.location = url
    }

    trackEvent({
      category: dataset.category,
      action: dataset.action,
      label: dataset.label,
      transport: 'beacon',
      callback: redirect,
    })

    // if hitCallback is not called for whatever reason, redirect w/o waiting
    setTimeout(redirect, 1000)
  }
}

/**
 * After dom loads, attach GA events to static / blade links by .js-event-link class.
 *
 * Does not attach to any dynamic links or vue links as those are created
 * after dom loads.
 */
export function trackLinkInit() {
  document.querySelectorAll('.js-event-link').forEach((link) => {
    link.addEventListener('click', trackLink)
  })
}

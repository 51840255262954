<template>
  <div class="member-badge">
    <div class="avatar">
      <img v-if="user.avatar" :src="user.avatar" :alt="`${user.displayName} profile image`" />
      <span v-else-if="!user.avatar" class="avatar-placeholder demi">
        {{ displayName.charAt(0) }}
      </span>
    </div>
    <div class="screen-name">
      {{ displayName }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { User } from '@/types/user-auth-types'

export default defineComponent({
  name: 'CommentUserName',
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },

  computed: {
    displayName() {
      // if user is null (which it shouldn't be, but technically it could via API)
      // set displayName to Unknown.  This also handles the Avatar image/first letter
      return this.user.displayName || 'Unknown'
    },
  },
})
</script>

<template>
  <div
    class="container -flex flex-justify-center loader-pad"
    role="status"
    :aria-live="accessible ? 'polite' : 'off'"
    aria-label="Loading..."
  >
    <div>
      <i aria-hidden="true" class="icon -loading" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SpinnerLoader',
  props: {
    accessible: {
      type: Boolean,
      default: true,
    },
  },
})
</script>

<template>
  <div
    :id="`comment-form-${parentId}`"
    ref="commentFormContainer"
    class="group -full -bg-01 -only -stack-space-single"
  >
    <comment-loader v-if="makingApiCall && isEditing" ref="commentFormEditLoader" />

    <div v-show="!makingApiCall">
      <div class="group -flex" :class="{ '-end': isEditing, '-between': !isEditing }">
        <comment-user-name v-if="!isEditing" :user="user" />
        <div class="comment-form-close">
          <icon-button
            icon="close"
            classes="-ico-only -no-container"
            aria-label="Close Form"
            @click-action="$emit('close-comment-form')"
          />
        </div>
      </div>

      <div class="post-comment">
        <vee-form v-slot="{ validate, errors, setFieldError }" as="">
          <form
            id="comment-form"
            :action="httpMethod"
            class="comment-form"
            @submit.prevent="submit(validate, setFieldError)"
          >
            <textarea-input
              v-model="formData.comment"
              :input-id="commentTextId"
              label="Comment"
              :show-label="false"
              placeholder="Type your comment."
              rows="5"
              :large="true"
            />
            <file-input
              ref="fileInput"
              type="file"
              :input-id="imageId"
              rules="image|size:10240"
              :accept="mimeTypes.join(',')"
              file-for="comment"
              @change="onFileChange"
            >
              <template #label>
                <span v-if="!(image && !errors[imageId])" class="image-label flex -vs-center">
                  <span class="image-ico">
                    <i
                      class="material-symbols-outlined"
                      aria-hidden="true"
                      data-icon="imagesmode"
                    ></i>
                  </span>
                  <span class="upload-inst">
                    <span class="image-title">Add an Image</span>
                    <span class="image-limit">10MB Limit</span>
                  </span>
                </span>
              </template>
              <template #remove>
                <div v-if="image && !errors[imageId]" class="file-remove">
                  <button
                    class="btn -image-remove"
                    title="Remove Image"
                    @click.prevent="removeFile"
                  >
                    <span class="iconfont-menu-close" />Remove Image
                  </button>
                  <img :src="image" alt="" />
                </div>
              </template>
            </file-input>

            <div class="comment-action">
              <button
                v-if="!isEditing"
                class="btn -dark"
                @click.prevent="submit(validate, setFieldError)"
              >
                Post
              </button>
              <button
                v-if="isEditing"
                class="btn -dark"
                @click.prevent="submit(validate, setFieldError)"
              >
                Save
              </button>
            </div>
          </form>
        </vee-form>
        <div class="discussion-policy">
          <a href="/page/discussion-policy" class="">Discussion Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { useCommentsStore } from '@/stores/comments'
import { Form as VeeForm } from 'vee-validate'
import CommentLoader from './comment-loader.vue'
import CommentUserName from './comment-user-name.vue'
import TextareaInput from '@/components/forms/textarea-input.vue'
import FileInput from '@/components/forms/file-input.vue'
import IconButton from '@/components/buttons/icon-button.vue'
import { scrollToElemTop } from '@/utils/scroll-to'
import { fbApi } from '@/utils/http-api'
import { buildComment } from '@/utils/comments'

export default defineComponent({
  components: {
    VeeForm,
    IconButton,
    CommentLoader,
    CommentUserName,
    TextareaInput,
    FileInput,
  },
  props: {
    parentId: {
      type: Number,
      default: -1,
    },
    comment: {
      type: Object,
      default: () => {},
    },
    commentableId: {
      type: Number,
      required: true,
    },
    commentableType: {
      type: String,
      required: true,
    },
  },
  emits: ['add-comment', 'edit-comment', 'close-comment-form', 'toggle-loading'],
  data() {
    return {
      // form processing state
      processing: false,
      removeImage: 0,
      // the actual image file as a string, after the file is selected
      // a string to display in img src
      imageDataString: '',
      textareaStyles: 'overflow-wrap: break-word; resize: vertical;',
      mimeTypes: ['image/png', 'image/jpeg', 'image/gif'],
      makingApiCall: false,
      formData: {
        // defaults
        id: this.comment && this.comment.id ? this.comment.id : -1,
        comment: this.comment && this.comment.comment ? this.comment.comment : '',
        commentableType: this.commentableType,
        commentableId: this.commentableId,
        parentId: this.parentId,
        removeImage: 0,
        image: '',
      },
    }
  },
  computed: {
    ...mapState(useUserAuthStore, ['user']),

    isEditing() {
      return this.formData.id !== -1
    },
    inputId() {
      return this.formData.id <= 0 ? `${this.formData.parentId}` : `${this.formData.id}`
    },
    imageId() {
      return `comment-image-${this.inputId}`
    },
    commentTextId() {
      return `comment-text-${this.inputId}`
    },
    image() {
      // if user removing image, never show this.comment?.imageUrl
      // only file input images
      if (this.formData.removeImage) {
        return this.imageDataString || ''
      }

      return this.imageDataString || this.comment?.imageUrl
    },
    httpMethod() {
      return this.isEditing ? 'put' : 'post'
    },
  },

  mounted() {
    // smooth scroll and focus the form when it is opened
    this.focusForm()
  },

  methods: {
    ...mapActions(useCommentsStore, ['increaseCommentsTotal', 'decreaseCommentsTotal']),

    // scrolls to get the form into view and then focuses on the textarea input
    focusForm() {
      this.$nextTick(() => {
        const commentFormContainer = this.$refs.commentFormContainer

        if (!commentFormContainer || !(commentFormContainer instanceof HTMLElement)) {
          return
        }

        // scroll
        const animationTime = 350
        scrollToElemTop({
          elem: commentFormContainer,
          ms: animationTime,
        })

        // focus textarea
        const textarea = commentFormContainer.querySelector('textarea')
        if (textarea && textarea instanceof HTMLTextAreaElement) {
          setTimeout(() => {
            textarea.focus()
          }, animationTime)
        }
      })
    },

    async submit(validate: Function, setFieldError: Function) {
      if (this.makingApiCall) {
        return
      }

      this.makingApiCall = true

      try {
        // must have comment OR image OR both to proceed
        if (!this.formData.comment.trim() && !this.formData.image) {
          setFieldError(this.commentTextId, 'Comment or image required')
          this.makingApiCall = false
          return
        }

        // remove comment / image error
        setFieldError(this.commentTextId, '')

        // any other validation errors
        const results = await validate()

        // invalid, stop
        if (!results.valid) {
          this.makingApiCall = false
          return
        }

        // success
        if (!this.isEditing) {
          this.$emit('toggle-loading', {
            loading: true,
            parentId: this.formData.parentId,
            amount: 1,
          })
        }

        if (!this.isEditing) {
          this.increaseCommentsTotal()
        }

        // scroll to the loader where the comment will show up
        this.$nextTick(() => {
          const loader = this.$refs.commentLoader

          if (loader && loader instanceof HTMLElement) {
            const animationTime = 500
            scrollToElemTop({
              elem: loader,
              ms: animationTime,
            })
          }
        })

        // build form data
        const formData = new window.FormData()
        formData.append('commentable_type', this.formData.commentableType)
        formData.append('commentable_id', `${this.formData.commentableId}`)
        formData.append('comment', this.formData.comment)

        if (this.formData.parentId > 0) {
          formData.append('parent_id', `${this.formData.parentId}`)
        }
        if (this.formData.image) {
          formData.append('image', this.formData.image)
        }
        if (this.formData.removeImage) {
          formData.append('image_remove', `${this.formData.removeImage}`)
        }

        // have to do this because image upload requires formData
        // and spoofing laravel http method to put
        if (this.isEditing) {
          formData.append('_method', this.httpMethod)
        }

        try {
          let url = this.isEditing ? `/comments/${this.formData.id}` : '/comments'

          // api call
          const res = await fbApi.post(url, {
            body: formData,
          })

          if (!res || !res.body || !res.body.data) {
            throw new Error(`Unable to submit comment, please try again`)
          }

          const newComment = buildComment(res.body.data)

          if (this.isEditing) {
            this.$emit('edit-comment', newComment)
          } else {
            this.$emit('add-comment', newComment)
          }

          this.$emit('close-comment-form')
        } catch (err) {
          if (!this.isEditing) {
            this.decreaseCommentsTotal()
          }
        } finally {
          this.$emit('toggle-loading', { loading: false, parentId: -1, amount: 0 })
        }
      } catch (err) {
        // error handling
      } finally {
        this.makingApiCall = false
      }
    },

    onFileChange(e: InputEvent) {
      const target = (e.target || e.dataTransfer) as HTMLInputElement
      const files = target && target.files

      if (!files || !files.length) {
        return
      }

      // do not render if not supported image
      if (!this.mimeTypes.includes(files[0].type)) {
        return
      }

      this.createImage(files[0])
    },

    // display image inline ONLY if no error
    createImage(file: any) {
      this.formData.image = file

      const reader = new window.FileReader()

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          this.imageDataString = e.target.result as string
        }
      }

      reader.readAsDataURL(file)
    },

    removeFile() {
      if (this.$refs.fileInput instanceof HTMLInputElement) {
        this.$refs.fileInput.value = ''
      }

      this.imageDataString = ''
      this.formData.image = ''

      // set a flag to tell server to remove the file.  Only do if comment on server has image
      if (this.image) {
        this.formData.removeImage = 1
      }
    },
  },
})
</script>

<template>
  <div class="group -full">
    <div class="group -bg-01 -only -stack-space-single" :class="{ '-edge': isParent }">
      <div class="member-info group flex">
        <comment-user-name :user="comment.user" />
        <comment-badge
          v-if="comment.user.roleId === 4"
          icon="admin_panel_settings"
          badge-class="badge admin"
          badge-text="Fitness blender admin"
        />
        <comment-badge
          v-if="comment.payForwardBadge"
          icon="currency_exchange"
          badge-class="badge pay-forward"
          badge-text="Pay it Forward Contributor"
          badge-link-url="/store/pay-it-forward"
        />
        <comment-badge
          v-if="comment.isCompleteBadge"
          icon="check_circle"
          :badge-text="`Verified ${formattedEntityType} complete`"
        />
      </div>

      <div class="comment-time">{{ comment.timeAgo }}</div>

      <!-- error states -->
      <callout v-if="error && !editCommentFormOpen" type="error">
        <div>{{ error }}</div>
      </callout>
      <callout v-if="comment.imageError && !editCommentFormOpen" type="info">
        <div>Comment saved, but unable to process image</div>
      </callout>

      <div v-if="image && !deleting && !editCommentFormOpen" class="comment-image">
        <div class="letterbox">
          <img class="letterbox-overlay" loading="lazy" :src="image" alt="" />
          <img class="letterbox-hold-height" loading="lazy" :src="image" alt="" />
        </div>
        <div class="original">
          <img loading="lazy" :src="image" alt="" />
        </div>
      </div>

      <div v-if="!deleting" class="comment-body">
        <!-- eslint-disable vue/no-v-html -->
        <p
          v-for="(commentParagraph, index) in displayComment"
          v-show="!editCommentFormOpen"
          :key="index"
          v-html="fbLinkify(decode(commentParagraph))"
        ></p>

        <!-- EDITING COMMENT FORM -->
        <comment-form
          v-if="editCommentFormOpen"
          :parent-id="comment.id"
          :comment="comment"
          :commentable-id="commentableId"
          :commentable-type="commentableType"
          @close-comment-form="closeEditCommentForm"
          @add-comment="addNewChildComment"
          @edit-comment="editComment"
          @toggle-loading="setLoading"
        />

        <div v-if="comment.isEdited" class="comment-edited">Edited</div>
      </div>

      <!-- showing deleting at bottom close to menu -->
      <comment-loader v-if="deleting" />

      <like-react-stats
        :like-emote-stats="likeEmoteStats"
        :selected-react-type-id="userSelectedReact"
      />

      <comment-actions-bar
        :comment-id="comment.id"
        :parent-id="isParent ? comment.id : comment.parentId"
        :owned-by-user="isOwner"
        :liked-by-user="!!userSelectedReact"
        :is-flagged="comment.isFlagged"
        :like-react-form-open="reactFormOpen"
        :comment-reply-form-open="commentReplyFormOpen"
        @react-form="toggleReactForm"
        @comment-reply-form="openCommentReplyForm"
        @flag-modal="openFlagModal"
        @edit-comment="toggleEditCommentForm"
        @delete-comment="openDeleteConfirmationModal"
      />

      <like-react-form
        v-show="reactFormOpen"
        :id="`like-react-form-${comment.id}`"
        :like-emotes="likeEmoteStats"
        :liked="comment.liked"
        :likeable-id="comment.id"
        likeable-type="comment"
        @close-react-form="toggleReactForm"
        @like-incremented="incrementLike"
        @like-decremented="decrementLike"
        @updated-like="setSelectedLike"
      />
    </div>

    <div v-if="hasChildren || commentReplyFormOpen">
      <div class="reply-group group flex -full">
        <div class="reply-marker"></div>
        <div class="replies">
          <comment
            v-for="childComment in commentChildren"
            :key="childComment.id"
            :initial-comment="childComment"
            :level="1"
            :parent-id="comment.id"
            :commentable-id="childComment.id"
            commentable-type="comment"
            @open-parent-reply-form="openCommentReplyForm"
            @delete-child-comment="deleteChildComment"
          />

          <!-- comment reply form shows in child comment list -->
          <div v-if="commentReplyFormOpen && !editCommentFormOpen" class="comment-reply">
            <comment-form
              ref="replyForm"
              :parent-id="comment.id"
              :commentable-id="commentableId"
              :commentable-type="commentableType"
              @close-comment-form="closeCommentReplyForm"
              @add-comment="addNewChildComment"
              @toggle-loading="setLoading"
            />
          </div>
        </div>
      </div>

      <div
        v-if="!loadingComments.loading && showMoreRepliesBtn"
        class="group -full -flex -vs-small -center -load-reply"
      >
        <button
          class="btn -neutral"
          @click="
            loadMoreChildComments({
              offset: comment.children.length,
              parentId: comment.id,
            })
          "
        >
          Load More Replies
        </button>
      </div>
    </div>

    <comment-loader v-if="addingComment || loadingChildren" ref="commentLoader" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { useCommentsStore } from '@/stores/comments'
import { useModalsStore } from '@/stores/modals'
import { useEntityContextStore } from '@/stores/entity-context'
import { CHILD_LOAD_AMOUNT } from '@/constants'
import { fbApi, FbApiError } from '@/utils/http-api'
import { buildComment } from '@/utils/comments'
import CommentForm from './comment-form.vue'
import CommentLoader from './comment-loader.vue'
import Callout from '../notifications/callout.vue'
import CommentUserName from './comment-user-name.vue'
import CommentBadge from './comment-badge.vue'
import likeReactStats from './like-react-stats.vue'
import likeReactForm from './like-react-form.vue'
import CommentActionsBar from './comment-actions-bar.vue'
import type { CommentType, LikeEmoteStat } from '@/types/comment-types'

export default defineComponent({
  name: 'Comment',
  components: {
    CommentForm,
    CommentLoader,
    CommentActionsBar,
    CommentBadge,
    CommentUserName,
    Callout,
    likeReactStats,
    likeReactForm,
  },

  props: {
    initialComment: {
      type: Object,
      required: true,
    },
    // truthy if loading a long child thread for a specific comment id
    loadingSpecificCommentThread: {
      type: Boolean,
      default: false,
    },
    commentableId: {
      type: Number,
      required: true,
    },
    commentableType: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  emits: ['open-parent-reply-form', 'delete-child-comment', 'delete-parent-comment'],
  data() {
    return {
      // when editing, store out the original comment text
      comment: this.copyCommentData(),
      canLoadMore: true,
      decoder: null as HTMLElement | null,
      // get FB urls.  Not 100% that this is best regex for urls, but FB urls shouldn't be too fancy
      // https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
      fbUrlRegex:
        /(\bhttps:\/\/www\.fitnessblender\.com\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi, // eslint-disable-line
      reactFormOpen: false,
      commentReplyFormOpen: false,
      editCommentFormOpen: false,
      sort: 'newest',
      loadingComments: {
        loading: false, // true/false for active
        parentId: -1, // id of comment to display loader under, for loading children
        amount: 0, // amount of comments to load
      },
      likeEmoteStats: this.copyLikeEmoteStats(),
      deleting: false,
      error: '',
    }
  },

  computed: {
    ...mapState(useUserAuthStore, ['user']),
    ...mapState(useModalsStore, ['simpleModal']),
    ...mapState(useEntityContextStore, ['entity']),

    isOwner() {
      return this.comment.user.id === this.user.id
    },
    addingComment() {
      return (
        this.loadingComments.loading &&
        this.loadingComments.parentId === this.comment.id &&
        this.loadingComments.amount === 1
      )
    },
    image() {
      return this.comment.imageUrl
    },
    displayComment() {
      return this.comment.comment.split(/\r?\n/g)
    },
    formattedEntityType() {
      if (this.entity.category) {
        return this.entity.category.replace(/_/g, ' ')
      }

      if (this.entity.type) {
        return this.entity.type.replace(/_/g, ' ')
      }

      return ''
    },
    hasChildren() {
      const children = this.comment.children
      const userAdded = this.comment.userAdded
      const hasChildren = (children && children.length > 0) || (userAdded && userAdded.length > 0)

      return this.level === 0 && hasChildren
    },
    isParent() {
      return this.level === 0
    },
    userSelectedReact() {
      return this.comment?.liked?.reactTypeId
    },
    loadingChildren() {
      return (
        this.loadingComments.loading &&
        this.loadingComments.parentId === this.comment.id &&
        this.loadingComments.amount > 1
      )
    },
    showMoreRepliesBtn() {
      const children = this.comment.children
      // Check that this comment has any children
      const hasChildren = this.hasChildren
      // Check that the response contains at least 5 otherwise there aren't any more.
      const canLoadMore = this.canLoadMore
      // Check that the length of the children minus the initial 2 is divisible by 5

      // we load 50 replies if user is looking at a single comment, otherwise
      // the default is 2 replies
      const defaultLoadedChildren = this.loadingSpecificCommentThread ? 50 : 2
      const isDivisible = (children.length - defaultLoadedChildren) % CHILD_LOAD_AMOUNT === 0

      return hasChildren && canLoadMore && isDivisible
    },
    commentChildren() {
      const children = this.comment.children
      let userAddedChildren = this.comment.userAdded

      if (userAddedChildren.length > 0) {
        const childrenIds = children.map((item: CommentType) => {
          return item.id
        })

        userAddedChildren = userAddedChildren.filter((item: CommentType) => {
          return childrenIds.indexOf(item.id) === -1
        })
      }

      return children.concat(userAddedChildren)
    },

    flagModalId() {
      return `flag-comment-${this.comment.id}-modal`
    },

    deleteModalId() {
      return `delete-comment-${this.comment.id}-modal`
    },
  },

  watch: {
    // modal actions
    'simpleModal.action'(newAction: string, prevAction: string) {
      // toggle flagged
      if (
        this.simpleModal.id === this.flagModalId &&
        newAction !== prevAction &&
        newAction === 'flagged'
      ) {
        this.comment.isFlagged = true
        return
      }

      // handle delete from modal
      if (
        this.simpleModal.id === this.deleteModalId &&
        newAction !== prevAction &&
        newAction === 'confirm'
      ) {
        this.handleDeleteComment()
        return
      }
    },
  },

  methods: {
    ...mapActions(useModalsStore, ['showLoginModal', 'openSimpleModal']),
    ...mapActions(useCommentsStore, ['increaseCommentsTotal', 'decreaseCommentsTotal']),

    /**
     * Reply form should only open for parents, so if clicked from child comment
     * emit back up to parent.
     */
    openCommentReplyForm({ parentId }: { parentId: number }) {
      if (!this.user.isLoggedIn) {
        this.showLoginModal()
        return
      }

      // if already open, re-trigger scroll / focus
      // better to use ref here and couple components than
      // prop + watcher IMO
      if (this.commentReplyFormOpen && this.$refs.replyForm instanceof CommentForm) {
        this.$refs.replyForm.focusForm()
        return
      }

      // open for parents only
      if (this.comment.id === parentId) {
        this.commentReplyFormOpen = true
        return
      }

      // emit to parent from child... so will open for parent
      this.$emit('open-parent-reply-form', { parentId })
    },

    closeCommentReplyForm() {
      this.commentReplyFormOpen = false
    },

    toggleEditCommentForm() {
      if (!this.user.isLoggedIn) {
        this.showLoginModal()
        return
      }

      this.editCommentFormOpen = true
    },

    toggleReactForm() {
      if (!this.user.isLoggedIn) {
        this.showLoginModal()
        return
      }

      this.reactFormOpen = !this.reactFormOpen
    },

    closeEditCommentForm() {
      this.editCommentFormOpen = false
    },

    // To avoid mutating props, making a deep copy of the passed in comment prop to mutate safely
    copyCommentData(): CommentType {
      // if (!this.initialComment) {
      //   return null
      // }

      return JSON.parse(JSON.stringify(this.initialComment))
    },

    // To avoid mutating props, making a deep copy of the passed comment prop likeEmoteStats to mutate safely
    copyLikeEmoteStats(): Record<number, LikeEmoteStat> {
      // if (!this.initialComment || !this.initialComment.likeEmoteStats) {
      //   return null
      // }

      return JSON.parse(JSON.stringify(this.initialComment.likeEmoteStats))
    },

    // toggles loading when submitting comment from comment-form
    setLoading({
      loading,
      parentId,
      amount,
    }: {
      loading: boolean
      parentId: number
      amount: number
    }) {
      this.loadingComments.loading = loading
      this.loadingComments.parentId = parentId
      this.loadingComments.amount = amount
    },

    fbLinkify(text: string) {
      return text.replace(this.fbUrlRegex, (url) => '<a href="' + url + '">' + url + '</a>')
    },

    // @see https://gomakethings.com/preventing-cross-site-scripting-attacks-when-using-innerhtml-in-vanilla-javascript/
    // Use this before adding our custom html (fb links)
    decode(html: string): string {
      this.decoder = this.decoder || document.createElement('div')
      this.decoder.textContent = html
      return this.decoder.innerHTML

      // old way, still had xss issues
      // Default way that Vue handles {{ }} decoding
      // https://github.com/vuejs/vue/issues/322
      // this.decoder.innerHTML = html
      // console.log(html, this.decoder.textContent);
      // return this.decoder.textContent
    },

    async loadMoreChildComments(opts: { parentId: number; offset: number }) {
      const { parentId, offset } = opts
      const url = `/comments/child/${parentId}?sort=${this.sort}&offset=${offset}`

      this.commentReplyFormOpen = false
      this.reactFormOpen = false

      this.setLoading({ loading: true, parentId: parentId, amount: CHILD_LOAD_AMOUNT })

      try {
        const res = await fbApi.get(url)

        if (!res || !res.body || !res.body.data) {
          this.canLoadMore = false
          throw new Error(`Unable to fetch child comments, please try again`)
        }

        this.addChildComments(res.body.data.map(buildComment))

        // if at least CHILD_LOAD_AMOUNT returned back, assume more comments exists
        if (res && res.body && res.body.data.length === CHILD_LOAD_AMOUNT) {
          // BUT if the comment returned are already contained in our child comment list
          // we know we are at the end (do not show more CTA)
          const firstId = res.body.data[0] && res.body.data[0].id ? res.body.data[0].id : null
          const commentExists = this.commentChildren.includes(
            (comment: CommentType) => comment.id === firstId,
          )

          this.canLoadMore = !commentExists
        }

        // if less than CHILD_LOAD_AMOUNT returned back, assume there are no more comments to load
        if (res && res.body && res.body.data.length < CHILD_LOAD_AMOUNT) {
          this.canLoadMore = false
        }
      } catch (err) {
        // error
        console.error(err)
      } finally {
        this.setLoading({ loading: false, parentId: -1, amount: 0 })
      }
    },

    // Appends newly loaded child comments onto current child comment
    addChildComments(newComments: CommentType[]) {
      if (this.loadingComments.parentId > 0) {
        this.comment.children = this.comment.children.concat(newComments)
      } else {
        // remove any new comments that already exist (will happen if a new comment
        // was added by another user after original comments were loaded)
        const uniqueNewComments = newComments.filter(
          (comment) => this.comment.map((c: CommentType) => c.id).indexOf(comment.id) < 0,
        )
        this.comment.children = this.comment.children.concat(uniqueNewComments)
      }
    },

    addNewChildComment(comment: CommentType) {
      this.comment.userAdded = this.comment.userAdded.concat([comment])
    },

    // mutate existing comment
    editComment(comment: CommentType) {
      this.comment.comment = comment.comment
      this.comment.isEdited = true
      this.comment.imageUrl = comment.imageUrl
      this.comment.imageError = comment.imageError
    },

    openDeleteConfirmationModal() {
      this.openSimpleModal({
        type: 'CONFIRM',
        id: this.deleteModalId,
        props: {
          heading: 'Remove Comment',
          subheading:
            'Are you sure you want to remove your comment?  All replies to the comment will be removed as well.',
          buttonText: 'Remove',
        },
      })
    },

    async handleDeleteComment() {
      if (this.deleting) {
        return
      }

      this.error = ''
      this.deleting = true

      const commentId = this.comment.id
      const url = `/comment/${commentId}`

      // callback for delete success
      const deleteSuccess = () => {
        if (this.comment.parentId) {
          // emit to parent comment component to delete child comment
          this.$emit('delete-child-comment', commentId)
        } else {
          // emit to comments component to delete parent comment
          this.$emit('delete-parent-comment', commentId)
        }

        this.decreaseCommentsTotal()
      }

      try {
        await fbApi.delete(url)

        deleteSuccess()
      } catch (err) {
        // if 404 on delete, have ui process successfully
        if (err && err instanceof FbApiError && err.status === 404) {
          deleteSuccess()
        } else {
          this.error = `Unable to delete comment, please try again`
        }
      }
    },

    deleteChildComment(commentId: number) {
      this.comment.children = this.comment.children.filter(
        (child: CommentType) => child.id !== commentId,
      )
      this.comment.userAdded = this.comment.userAdded.filter(
        (child: CommentType) => child.id !== commentId,
      )
    },

    incrementLike(reactTypeId: number) {
      this.likeEmoteStats[reactTypeId].total++
    },

    decrementLike(reactTypeId: number) {
      this.likeEmoteStats[reactTypeId].total--
    },

    setSelectedLike(likeObj: LikeEmoteStat) {
      this.comment.liked = likeObj
    },

    openFlagModal() {
      this.openSimpleModal({
        type: 'FLAG',
        id: this.flagModalId,
        props: {
          flaggableId: this.comment.id,
          flaggableType: 'comment',
        },
      })
    },
  },
})
</script>

/**
 * Prevent Blade Forms From Submitting Twice.  Only used on contact form.
 */
export function singleClickSubmit() {
  const submitButton = document.getElementById('single_click_submit')

  if (submitButton && submitButton instanceof HTMLButtonElement) {
    let processing = false

    submitButton.addEventListener('click', function (e: MouseEvent) {
      e.preventDefault()

      if (!processing) {
        processing = true
        submitButton.disabled = true

        // slight delay on submit to let any UI settle
        setTimeout(() => {
          if (this.form) {
            this.form.submit()
          }
        }, 100)
      }
    })
  }
}

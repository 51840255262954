import { defineStore } from 'pinia'
import type { FavoritesState, Favorites, Favorite } from '@/types/favorite-types'

export const useFavoritesStore = defineStore('favorites', {
  state: (): FavoritesState => ({
    favorites: {},
  }),

  getters: {
    isFavorite: (state: FavoritesState) => (favoritable: Favorite) => {
      const key = `${favoritable.favoritableType}_${favoritable.favoritableId}`
      return !!state.favorites[key]
    },
  },

  actions: {
    setFavorites(favorites: Favorites) {
      this.favorites = favorites
    },

    addFavorite(favoritable: Favorite) {
      const key = `${favoritable.favoritableType}_${favoritable.favoritableId}`

      this.favorites = {
        ...this.favorites,
        [key]: true,
      }
    },

    removeFavorite(favoritable: Favorite) {
      const key = `${favoritable.favoritableType}_${favoritable.favoritableId}`

      // mutate existing key
      this.favorites[key] = false
    },
  },
})

<template>
  <img
    v-if="type === 'thumb-130'"
    :src="`${defaultImgPrefix}/130/130-${image}`"
    :srcset="
      defaultImgPrefix +
      '/130/130-' +
      image +
      ' 1x, ' +
      defaultImgPrefix +
      '/320/320-' +
      image +
      ' 2x '
    "
    loading="lazy"
    width="130"
    height="73"
    :alt="title"
  />
  <img
    v-else-if="type === 'thumb-320' && image"
    :data-src="`${defaultImgPrefix}/320/320-${image}`"
    :data-srcset="`${defaultImgPrefix}/320/320-${image} 1x,
      ${defaultImgPrefix}/640/640-${image} 2x`"
    class="lazyload lazyfade"
    width="320"
    height="180"
    :class="{
      lazyload: !!reloadImage,
    }"
    :alt="title"
  />
  <img
    v-else-if="type === 'thumb-320' && !image"
    src="/assets/img/placeholder.svg"
    class=""
    width="320"
    height="180"
    alt="Content is not live placeholder image"
  />
  <img
    v-else-if="type === 'browser-select-max-800'"
    :src="`${defaultImgPrefix}/320/320-${image}`"
    :srcset="
      defaultImgPrefix +
      '/800/800-' +
      image +
      ' 800w, ' +
      defaultImgPrefix +
      '/640/640-' +
      image +
      ' 640w, ' +
      defaultImgPrefix +
      '/320/320-' +
      image +
      ' 320w '
    "
    :alt="title"
    :loading="lazyLoading"
    width="800"
    height="450"
  />
  <img
    v-else-if="type === 'browser-select-max-1020'"
    :data-src="`${defaultImgPrefix}/320/320-${image}`"
    :data-srcset="
      defaultImgPrefix +
      '/1020/1020-' +
      image +
      ' 1020w, ' +
      defaultImgPrefix +
      '/800/800-' +
      image +
      ' 800w, ' +
      defaultImgPrefix +
      '/640/640-' +
      image +
      ' 640w, ' +
      defaultImgPrefix +
      '/320/320-' +
      image +
      ' 320w '
    "
    class="lazyload lazyfade"
    :class="{
      lazyload: !!reloadImage,
    }"
    :alt="title"
  />
  <img
    v-else-if="type === 'card-640' && image"
    :data-src="`${defaultImgPrefix}/640/640-${image}`"
    class="lazyload lazyfade"
    :class="{
      lazyload: !!reloadImage,
    }"
    :alt="title"
  />
  <picture v-else-if="type === 'unit-image'" class="unit-image">
    <source
      type="image/webp"
      :data-srcset="
        defaultImgPrefix +
        '/1440/1440-' +
        imageWithWebpExt +
        ' 1440w, ' +
        defaultImgPrefix +
        '/720/720-' +
        imageWithWebpExt +
        ' 720w, '
      "
      :sizes="sizes ? sizes : '(max-width: 940px) 100vw, 50vw'"
    />
    <source
      type="image/png"
      :data-srcset="
        defaultImgPrefix +
        '/1440/1440-' +
        image +
        ' 1440w, ' +
        defaultImgPrefix +
        '/720/720-' +
        image +
        ' 720w, '
      "
      :sizes="sizes ? sizes : '(max-width: 940px) 100vw, 50vw'"
    />
    <img
      class="lazyload lazyfade"
      :class="{
        lazyload: !!reloadImage,
      }"
      :data-src="defaultImgPrefix + '/1440/1440-' + image"
      :src="defaultImgPrefix + '/1440/1440-' + image"
      alt=""
      width="720"
      height="360"
    />
  </picture>
  <picture v-else-if="type === 'unit-image-2880'" class="unit-image">
    <source
      type="image/webp"
      :data-srcset="
        defaultImgPrefix +
        '/2880/2880-' +
        imageWithWebpExt +
        ' 2880, ' +
        defaultImgPrefix +
        '/2160/2160-' +
        imageWithWebpExt +
        ' 2160w, ' +
        defaultImgPrefix +
        '/1440/1440-' +
        image +
        ' 1440w, ' +
        defaultImgPrefix +
        '/720/720-' +
        image +
        ' 720w, '
      "
      :sizes="sizes ? sizes : '(max-width: 940px) 100vw, 50vw'"
    />
    <source
      type="image/png"
      :data-srcset="
        defaultImgPrefix +
        '/2880/2880-' +
        image +
        ' 2880, ' +
        defaultImgPrefix +
        '/2160/2160-' +
        image +
        ' 2160w, ' +
        defaultImgPrefix +
        '/1440/1440-' +
        image +
        ' 1440w, ' +
        defaultImgPrefix +
        '/720/720-' +
        image +
        ' 720w, '
      "
      :sizes="sizes ? sizes : '(max-width: 940px) 100vw, 50vw'"
    />
    <img
      class="lazyload lazyfade"
      :class="{
        lazyload: !!reloadImage,
      }"
      :data-src="defaultImgPrefix + '/1440/1440-' + image"
      :src="defaultImgPrefix + '/1440/1440-' + image"
      alt=""
      width="640"
      height="360"
    />
  </picture>
  <img
    v-else-if="type === 'card-640' && !image"
    src="/assets/img/placeholder.svg"
    class=""
    width="640"
    height="360"
    alt="Content is not live placeholder image"
  />
  <img
    v-else
    :data-src="`${defaultImgPrefix}/320/320-${image}`"
    :data-srcset="
      defaultImgPrefix +
      '/1020/1020-' +
      image +
      ' 1020w, ' +
      defaultImgPrefix +
      '/800/800-' +
      image +
      ' 800w, ' +
      defaultImgPrefix +
      '/640/640-' +
      image +
      ' 640w, ' +
      defaultImgPrefix +
      '/320/320-' +
      image +
      ' 320w '
    "
    sizes="
      (min-width: 941px) 320px,
      (min-width: 601px) and (max-width: 940px) calc(50vw - 18px),
      (max-width: 600px) 100vw calc(100vw - 12px)
    "
    class="lazyload lazyfade"
    :class="{
      lazyload: !!reloadImage,
    }"
    :alt="title"
  />
</template>

<script>
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'

/**
 * This src set is based on srcset_image.blade.php "four-up-card" with lazyloading
 * - used on articles, custom workouts, and videos
 */
export default {
  name: 'CardSrcSetImage',
  components: {},
  props: {
    imgPrefix: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'standard',
    },
    sizes: {
      type: String,
      default: '',
    },
    lazyLoading: {
      type: String,
      default: 'lazy', // lazy or eager
    },
  },
  data() {
    return {
      // this is dumb, but have to do it because of the lazyload library is not
      // reactive
      reloadImage: false,
    }
  },
  computed: {
    ...mapState(useUserAuthStore, ['cdn']),

    // if custom prefix passed in, use that, otherwise fall back to siteassets
    defaultImgPrefix() {
      // custom
      if (this.imgPrefix) {
        return this.imgPrefix
      }

      // fallback / default
      // get siteassets url from page load, allows CDN url to be added
      const siteAssetsPrefix = this.cdn.siteAssetsPrefix || '/siteassets'
      return `${siteAssetsPrefix}`
    },

    imageWithWebpExt() {
      // remove extension
      const imageWithoutExt = this.image ? this.image.split('.')[0] : ''
      return `${imageWithoutExt}.webp`
    },
  },
  watch: {
    image() {
      this.forceReloadImage()
    },
  },
  methods: {
    // used to force reload the image by adding / removing lazyload class
    forceReloadImage() {
      this.reloadImage = true
      this.$nextTick(() => {
        this.reloadImage = false
      })
    },
  },
}
</script>

<template>
  <section id="comments" ref="comments" class="content-group scroll-dest">
    <div class="group -edge -flex -center">
      <div class="unit-block full -comments -bg-02 -flex -center">
        <div class="group -full">
          <h1 class="heading -secondary">
            {{ totalCommentsText }}
          </h1>
        </div>

        <div class="group -full -bg-01 -only -stack-space-single">
          <div v-if="!topCommentFormOpen" class="comment-action">
            <button
              class="btn -dark"
              aria-label="Post Comment"
              :aria-expanded="topCommentFormOpen"
              :aria-controls="'comment-form--1'"
              @click="toggleTopCommentForm"
            >
              Post a Comment
            </button>
          </div>
        </div>

        <comment-form
          v-if="user.isLoggedIn && topCommentFormOpen"
          :commentable-id="commentableId"
          :commentable-type="commentableType"
          @close-comment-form="toggleTopCommentForm"
          @add-comment="addNewParentComment"
          @toggle-loading="setLoading"
        />

        <div class="group -full">
          <header v-if="comments.length > 0" class="comments__header flex">
            <div class="comments__sort flex-right">
              <div class="form__group -inline">
                <label for="sort">Sort</label>
                <div class="select-style">
                  <form>
                    <select id="sort" v-model="sort" name="sort" @change="handleSort($event)">
                      <option value="newest">Newest First</option>
                      <option value="popular">Most Popular</option>
                      <option value="oldest">Oldest First</option>
                    </select>
                  </form>
                </div>
              </div>
            </div>
          </header>
        </div>

        <!-- initial loader -->
        <comment-loader v-if="initialLoading || addingComment" />

        <comment
          v-for="comment in comments"
          :key="comment.id"
          :initial-comment="comment"
          :commentable-id="entity.id as number"
          :commentable-type="entity.type as string"
          :loading-specific-comment-thread="comment.id === commentId"
          @delete-parent-comment="deleteParentComment"
        />

        <div v-if="canLoadMoreComments" class="group -full -flex -vs-small -center">
          <button class="btn -dark" @click="loadMoreComments">Load More Comments</button>
        </div>

        <!-- pagination loader -->
        <comment-loader v-if="loadingMore" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { useCommentsStore } from '@/stores/comments'
import { useModalsStore } from '@/stores/modals'
import { useEntityContextStore } from '@/stores/entity-context'
import { PARENT_LOAD_AMOUNT } from '@/constants'
import { fbApi } from '@/utils/http-api'
import { buildComment } from '@/utils/comments'
import Comment from './comment.vue'
import CommentLoader from './comment-loader.vue'
import CommentForm from './comment-form.vue'
import inViewport from 'in-viewport'
import type { CommentType } from '@/types/comment-types'

export default defineComponent({
  name: 'Comments',
  components: {
    Comment,
    CommentForm,
    CommentLoader,
  },
  data() {
    return {
      comments: [] as CommentType[],
      sort: 'newest',
      canLoadMore: true,
      firstLoad: true,
      topCommentFormOpen: false,
      loadingComments: {
        loading: false, // true/false for active
        parentId: -1, // id of comment to display loader under, for loading children
        amount: 0, // amount of comments to load
      },
      totalComments: 0,
    }
  },
  computed: {
    ...mapState(useUserAuthStore, ['user']),
    ...mapState(useCommentsStore, ['commentCount', 'commentId', 'totalCommentsText']),
    ...mapState(useEntityContextStore, ['entity']),

    commentableId() {
      return this.entity.id as number
    },

    commentableType() {
      return this.entity.type as string
    },

    initialLoading() {
      return (
        this.loadingComments.loading &&
        this.loadingComments.parentId < 0 &&
        this.loadingComments.amount === 1
      )
    },
    loadingMore() {
      return (
        this.loadingComments.loading &&
        this.loadingComments.parentId < 0 &&
        this.loadingComments.amount > 1
      )
    },
    addingComment() {
      return (
        this.loadingComments.loading &&
        this.loadingComments.parentId === -1 &&
        this.loadingComments.amount === 1
      )
    },
    canLoadMoreComments() {
      return !this.loadingComments.loading && this.showMoreCommentsBtn && this.comments.length > 0
    },
    currentPath() {
      return encodeURIComponent(`${window.location.pathname}${window.location.search}`)
    },
    showMoreCommentsBtn() {
      return (
        (this.commentId && this.firstLoad) ||
        (this.canLoadMore && this.comments.length > PARENT_LOAD_AMOUNT - 1)
      )
    },
  },

  // need to use mounted instead of created because of $refs
  // xhr fires when in view port
  mounted() {
    inViewport(this.$refs.comments, () => {
      this.getInitialComments()
    })
  },

  methods: {
    ...mapActions(useModalsStore, ['showLoginModal']),

    // toggles top level comment form for new parent comments
    toggleTopCommentForm() {
      if (!this.user.isLoggedIn) {
        this.showLoginModal()
        return
      }

      this.topCommentFormOpen = !this.topCommentFormOpen
    },

    // toggles loading when submitting comment from comment-form
    setLoading({
      loading,
      parentId,
      amount,
    }: {
      loading: boolean
      parentId: number
      amount: number
    }) {
      this.loadingComments.loading = loading
      this.loadingComments.parentId = parentId
      this.loadingComments.amount = amount
    },

    // called on page load and when changing sort
    async getInitialComments() {
      let url = `/comments/${this.commentableType}/${this.commentableId}?sort=${this.sort}&offset=0`

      // if a commentId is passed in, instead load that comment and 50 of its child comments
      if (this.commentId) {
        url = `/comment/${this.commentId}?sort=${this.sort}&offset=0`
      }

      this.setLoading({ loading: true, parentId: -1, amount: 1 })
      // empty comments as we're getting a brand new set in the case of new sort
      this.comments = []

      try {
        const res = await fbApi.get(url)

        if (!res || !res.body || !res.body.data) {
          throw new Error(`Unable to fetch comments, please try again`)
        }

        this.comments = res.body.data.map(buildComment)
      } catch (err) {
        // noop, user will see error modal
      } finally {
        this.setLoading({ loading: false, parentId: -1, amount: 0 })
      }
    },

    async loadMoreComments() {
      const offset = this.comments.length

      // if on page that loads just one comment, offset additional calls by one
      const adjOffset = this.commentId ? offset - 1 : offset

      // figure out starting comment to help with sort based pagination (offsets)
      // as there is an issue of comments not showing on popular discussions
      // that are getting lots of real time comments (when the CTA is clicked)
      let start = 0

      if (this.comments && this.comments.length) {
        start = this.comments[0].id

        // switch start to be from popular count
        if (this.sort === 'popular') {
          start = this.comments[0].likeCount
        }
      }
      // end start

      this.topCommentFormOpen = false

      this.loadingComments.loading = true
      this.loadingComments.amount = PARENT_LOAD_AMOUNT

      try {
        const res = await fbApi.get(
          `/comments/${this.commentableType}/${this.commentableId}?sort=${this.sort}&start=${start}&offset=${adjOffset}`,
        )

        this.firstLoad = false

        if (!res || !res.body || !res.body.data) {
          this.canLoadMore = false
          throw new Error(`Unable to load more comments`)
        }

        this.canLoadMore = false

        this.addComments(res.body.data.map(buildComment))

        this.canLoadMore = res.body.data.length === PARENT_LOAD_AMOUNT

        this.loadingComments.loading = false
        this.loadingComments.amount = 0
      } catch (err) {
        this.loadingComments.loading = false
        this.loadingComments.amount = 0
      }
    },

    addComments(newComments: CommentType[]) {
      if (this.loadingComments.parentId > 0) {
        // const parent = this.comments.find((comment) => comment.id === this.loadingComments.parentId) || {}
        const parent = this.comments.find((comment) => comment.id === this.loadingComments.parentId)

        if (parent) {
          parent.children = parent.children.concat(newComments)
        }
      } else {
        // remove any new comments that already exist (will happen if a new comment
        // was added by another user after original comments were loaded)
        const uniqueNewComments = newComments.filter(
          (comment) => this.comments.map((c) => c.id).indexOf(comment.id) < 0,
        )
        this.comments = this.comments.concat(uniqueNewComments)
      }
    },

    addNewParentComment(newComment: CommentType) {
      if (this.loadingComments.parentId < 0) {
        this.comments = [newComment].concat(this.comments)
      }
    },

    deleteParentComment(commentId: number) {
      this.comments = this.comments.filter((comment) => comment.id !== commentId)
    },

    handleSort(event: Event) {
      if (!(event.target instanceof HTMLSelectElement)) {
        return
      }

      this.sort = event.target.value
      // reload comments with new sort value
      this.getInitialComments()

      // reset as sort replaces results
      this.canLoadMore = true
    },
  },
})
</script>

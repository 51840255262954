<template>
  <div class="quick-info">
    <div v-if="isFree" class="free">Free</div>
    <div v-if="completeStatus" class="complete" aria-label="Complete">
      <i class="material-symbols-outlined" aria-hidden="true">check</i>
    </div>
    <div v-if="planInProgress" class="in-progress" aria-label="In Progress">
      <i class="material-symbols-outlined fill" aria-hidden="true">loop</i>
    </div>
    <div v-if="isPlanUpcoming" class="upcoming" aria-label="Upcoming">
      <i class="material-symbols-outlined fill" aria-hidden="true">event</i>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useCalendarStore } from '@/stores/calendar'
import { useCompletesStore } from '@/stores/completes'

/** PROPS **/
const props = defineProps({
  isExclusive: {
    type: Boolean,
    default: false,
  },
  isFree: {
    type: Boolean,
    default: false,
  },
  entityId: {
    type: Number,
    required: true,
  },
  entityType: {
    type: String,
    default: 'plan',
    validator: (val) => ['plan'].includes(val),
  },
})

/** STATE **/
const calendarStore = useCalendarStore()
const completesStore = useCompletesStore()

/** COMPUTED **/
const completeStatus = computed(() => {
  const completeCount = completesStore.userCompletes({
    schedulableType: props.entityType,
    schedulableId: props.entityId,
  })

  return !!completeCount
})

const planInProgress = computed(
  () => !!calendarStore.getScheduledPlanStats(props.entityId)?.inProgress,
)

const isPlanUpcoming = computed(
  () => !!calendarStore.getScheduledPlanStats(props.entityId)?.upcomingCount,
)
</script>

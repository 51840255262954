<template>
  <outside-click :display="'inline'" :disabled="!open" @outside-click-action="closeHelp">
    <span>
      <button
        class="chelp-text"
        :class="classes"
        :aria-describedby="id"
        @click="openHelp"
        @focus="focusOpenHelp"
        @blur="focusCloseHelp"
      >
        <i v-if="icon" class="material-symbols-outlined fill" aria-hidden="true">{{ icon }}</i>
        {{ title }}
      </button>
      <div class="chelp-container" :class="openClass">
        <div
          class="chelp-close"
          :tabindex="openedBy === 'focus' ? '-1' : '0'"
          role="button"
          aria-label="Close"
          @click="closeHelp"
          @keyup.enter="closeHelp"
        >
          <i class="material-symbols-outlined" aria-hidden="true">close</i>
        </div>
        <div :id="id" role="tooltip"><slot /></div>
      </div>
    </span>
  </outside-click>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OutsideClick from './outside-click.vue'

/**
 * Contextual help tool tips, for styling see modules/_contextual-help.scss
 */
export default defineComponent({
  name: 'ContextualHelp',
  components: {
    OutsideClick,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
  },

  // component state
  data() {
    return {
      open: false,
      openedBy: '',
    }
  },

  computed: {
    openClass() {
      if (this.open) {
        return 'chelp-open'
      }

      return ''
    },
  },

  // component methods
  methods: {
    // click events
    openHelp() {
      this.open = true
      this.openedBy = 'click'
    },

    closeHelp() {
      this.open = false
    },

    // keyboard events, separated to not auto close if user clicks
    // this let's users copy / paste, otherwise button will insta-close
    // due to blur event
    focusOpenHelp() {
      this.open = true
      this.openedBy = 'focus'
    },

    focusCloseHelp() {
      // only close if opened by keyboard focus
      if (this.openedBy !== 'focus') {
        return
      }

      this.closeHelp()
    },
  },
})
</script>

<template>
  <div class="tour">
    <div class="tour-overlay"></div>

    <div ref="highlight" class="tour-highlight"></div>

    <div
      v-show="activeStep"
      ref="chelp"
      class="tour-chelp chelp-container chelp-open"
      :class="{
        '-center': chelpAlignCenter,
      }"
      tabindex="-1"
      aria-label="Guided Tour"
      role="dialog"
      :aria-modal="true"
    >
      <div
        class="chelp-close"
        :tabindex="0"
        role="button"
        aria-label="Exit Tour"
        @click.once="exitTour()"
      >
        <i class="material-symbols-outlined" aria-hidden="true">close</i>
      </div>
      <div v-if="activeStep" class="tour-chelp-body" aria-live="polite">
        <h3 id="aria-chelp-title" class="heading -small">{{ activeStep.title }}</h3>
        <p>{{ activeStep.desc }}</p>
        <div v-if="activeStep.link" class="tour-chelp-cta">
          <button class="btn -dark -no-case" @click.once="exitTour(activeStep.link)">
            {{ activeStep.linkText }}
          </button>
        </div>
        <div v-if="activeStep.image" class="tour-chelp-image">
          <img :src="`${imageUrlPrefix}${activeStep.image}`" :alt="activeStep.imageAlt" />
        </div>
      </div>
      <div class="tour-chelp-actions">
        <div class="tour-chelp-action">
          <icon-button
            v-if="hasPrevious"
            icon="arrow_back_ios"
            classes="-ico-only -no-container"
            label="Previous Step"
            aria-live="polite"
            @click-action="previous"
          />
        </div>
        <div class="tour-chelp-action">
          <span class="tour-chelp-steps">{{ currUserStep }} / {{ steps.length }}</span>
        </div>
        <div class="tour-chelp-action">
          <icon-button
            v-if="hasNext"
            icon="arrow_forward_ios"
            classes="-ico-only -no-container"
            label="Next Step"
            aria-live="polite"
            @click-action="next"
          />
        </div>
      </div>
      <div v-show="isFirstStep" class="cancel-tour">
        <button class="btn -anchor-link" @click="exitGuidedTour">Cancel Tour</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import _debounce from 'lodash/debounce'
import IconButton from '@/components/buttons/icon-button.vue'
import { scrollToElemTop } from '@/utils/scroll-to'

/**
 * TODO: gt comments
 */
export default defineComponent({
  name: 'Tour',
  components: { IconButton },
  props: {
    tourId: {
      type: String,
      required: true,
    },
    script: {
      type: Object,
      required: true,
    },
  },
  emits: ['exit-tour', 'exit-guided-tour'],

  // component state
  data() {
    return {
      step: 0,
      isComplete: false,
      loadConditionVerified: false,
      chelpAlignCenter: false,
    }
  },

  computed: {
    ...mapState(useUserAuthStore, ['cdn']),

    steps() {
      if (this.script.steps) {
        return this.script.steps
      }

      return []
    },

    activeStep() {
      // do not show any steps if loading condition failed
      if (!this.loadConditionVerified && this.script.loadCondition) {
        return this.script.loadCondition
      }

      if (this.steps[this.step]) {
        return this.steps[this.step]
      }

      return null
    },

    // human readable
    currUserStep() {
      return this.step + 1
    },

    isFirstStep() {
      return this.currUserStep === 1
    },

    hasNext() {
      return this.loadConditionVerified && this.step + 1 < this.steps.length
    },

    hasPrevious() {
      return this.loadConditionVerified && this.step > 0 && this.steps.length > 1
    },

    imageUrlPrefix() {
      if (!this.activeStep || !this.activeStep.cdnKey) {
        return ''
      }

      const cdnKey = this.activeStep.cdnKey as keyof typeof this.cdn

      return this.cdn[cdnKey] ? this.cdn[cdnKey] : this.cdn.prefix
    },
  },

  watch: {
    // mark complete if user gets to final step, regardless if they click
    // previous and actually end on some other step
    step(newStep: number) {
      if (!this.isComplete && newStep + 1 === this.steps.length) {
        this.isComplete = true
      }
    },
  },

  /* lifecycle methods */
  mounted() {
    this.$nextTick(() => {
      this.verifyLoadCondition()

      if (this.loadConditionVerified) {
        this.highlightStep(0)
      }

      // focus chelp section for accessibility
      if (this.activeStep && this.$refs.chelp instanceof HTMLElement) {
        this.$refs.chelp.focus()
      }
    })
  },

  created() {
    window.addEventListener('resize', this.resize)
  },

  beforeUnmount() {
    // clean up
    window.removeEventListener('resize', this.resize)
  },

  // component methods
  methods: {
    resize: _debounce(function (this: any) {
      this.highlightStep(this.step)
    }, 50),

    verifyLoadCondition() {
      // no selector OR selector exist and was found
      if (
        !this.script.loadCondition?.selectorExists ||
        document.querySelector(this.script.loadCondition.selectorExists)
      ) {
        this.loadConditionVerified = true
        return
      }

      // not verified
      this.loadConditionVerified = false

      if (this.$refs && this.$refs.highlight instanceof HTMLDivElement) {
        this.$refs.highlight.removeAttribute('style')
      }

      if (this.$refs && this.$refs.chelp instanceof HTMLDivElement) {
        this.$refs.chelp.removeAttribute('style')
      }

      this.chelpAlignCenter = true
    },

    /**
     * Progress and highlight new step, could be next or previous
     */
    highlightStep(newStepIndex: number) {
      const cssAnimationMs = 250

      // if the step doesn't exist at all, exit the tour
      if (!this.steps[newStepIndex]) {
        this.exitTour()
        return
      }

      // step may not have a query selector, it could be ''
      // always center in this case
      if (!this.steps[newStepIndex].highlightSelector) {
        if (this.$refs && this.$refs.highlight instanceof HTMLDivElement) {
          this.$refs.highlight.removeAttribute('style')
        }
        if (this.$refs && this.$refs.chelp instanceof HTMLDivElement) {
          this.$refs.chelp.removeAttribute('style')
        }
        this.chelpAlignCenter = true
        this.step = newStepIndex

        // nothing to highlight, so scroll to centered chelp
        setTimeout(() => {
          scrollToElemTop({
            elem: this.$refs.chelp as HTMLElement,
            extraOffsetY: scrollOffsetY,
          })
        }, cssAnimationMs)

        return
      }
      // end center

      // make sure dom element exists before updating step
      // if element doesn't exist, try to skip this step to the next
      const highlightElem = document.querySelector(this.steps[newStepIndex].highlightSelector)

      if (!highlightElem) {
        this.highlightStep(newStepIndex + 1)
        return
      }

      // update step and ref inline styles
      this.chelpAlignCenter = false
      this.step = newStepIndex

      const rect = highlightElem.getBoundingClientRect()
      const scrollY = window.scrollY || window.pageYOffset
      const highlightPadding = 5
      const highlightTop = rect.top + scrollY - highlightPadding
      // console.log(rect, scrollY, this.$refs.chelp.offsetHeight);

      // alignment for highlight
      if (this.$refs && this.$refs.highlight instanceof HTMLDivElement) {
        this.$refs.highlight.style.top = `${highlightTop}px`
        this.$refs.highlight.style.left = `${rect.left - highlightPadding}px`
        this.$refs.highlight.style.height = `${rect.height + highlightPadding * 2}px`
        this.$refs.highlight.style.width = `${rect.width + highlightPadding * 2}px`
      }

      // determine left for chelp, make sure fits
      // if negative, need to add more space to the left
      const chelpWidth = 300
      const chelpMinLeft = 10
      const chelpTopPadding = 20
      let scrollOffsetY = 50
      let chelpLeft = rect.left + rect.width + highlightPadding - chelpWidth
      // console.log(chelpLeft, rect.x, rect.width, chelpWidth);

      if (chelpLeft < 0) {
        chelpLeft = chelpMinLeft
        // console.log('left negative, ', chelpLeft)
      }

      const refsChelp = this.$refs.chelp as HTMLDivElement

      refsChelp.style.left = `${chelpLeft}px`

      // determine top for chelp, defaults to below
      if (this.activeStep.chelpAlign === 'above') {
        this.$nextTick(() => {
          scrollOffsetY = scrollOffsetY + refsChelp.offsetHeight
          refsChelp.style.top = `${
            highlightTop - refsChelp.offsetHeight - chelpTopPadding - highlightPadding
          }px`
        })
      } else {
        refsChelp.style.top = `${highlightTop + rect.height + chelpTopPadding}px`
      }

      // scroll to ref delayed based on css animation
      setTimeout(() => {
        scrollToElemTop({
          elem: this.$refs.highlight as HTMLElement,
          extraOffsetY: scrollOffsetY,
        })
      }, cssAnimationMs)
    },

    previous() {
      this.highlightStep((this.step -= 1))
    },

    next() {
      this.highlightStep((this.step += 1))
    },

    // let parent handle
    exitTour(link: string = '') {
      this.$emit('exit-tour', {
        guidedTourId: this.script.guidedTourId || '',
        tourId: this.tourId,
        tourStepComplete: this.isComplete ? this.steps.length : this.currUserStep,
        isComplete: this.isComplete,
        exitUrl: link || '',
      })
    },

    // let parent handle
    exitGuidedTour() {
      this.$emit('exit-guided-tour', {
        guidedTourId: this.script.guidedTourId || '',
        tourId: this.tourId,
        tourStepComplete: this.isComplete ? this.steps.length : this.currUserStep,
        isComplete: this.isComplete,
      })
    },
  },
})
</script>

<template>
  <div class="complete-customization">
    <!-- error state -->
    <callout v-if="error" type="error">
      <div>{{ error }}</div>
    </callout>

    <!-- pinning will focus scroll to this heading -->
    <h3 id="tags-header" class="heading">
      Add Tags

      <icon-button
        icon="arrow_back_ios"
        classes="-ico-only -no-container help"
        label="Return to Menu"
        @click-action="$emit('close-action')"
      />
    </h3>

    <div class="extras-form">
      <!-- tag form -->
      <manage-tags
        :entity-id="entityId"
        :entity-type="entityType"
        parent-component="schedulable-complete-slider"
        :show-complete-slider-header="true"
        :start-add-open="true"
        @error-action="setError"
        @close-action="$emit('close-action')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Callout from '@/components/notifications/callout.vue'
import ManageTags from '@/components/tags/manage-tags.vue'
import IconButton from '@/components/buttons/icon-button.vue'

/**
 * Container component for Manage-Tags component for the Schedulable Complete Slider
 */
export default defineComponent({
  name: 'SliderTagsForm',
  components: {
    ManageTags,
    IconButton,
    Callout,
  },
  props: {
    entityId: {
      required: true,
      type: Number,
    },
    entityType: {
      default: 'workout',
      type: String,
      validator: (val: string) => ['workout', 'article', 'wellness_video'].includes(val),
    },
    entityCategory: {
      default: '',
      type: String,
      validator: (val: string) => ['', 'recipe'].includes(val),
    },
  },
  emits: ['close-action'],

  // component state
  data() {
    return {
      error: '' as string | Error,
    }
  },

  // component methods
  methods: {
    setError(err: Error) {
      this.error = err
    },
  },
})
</script>

<template>
  <a
    v-if="!!badgeLinkUrl"
    class="badge-group no-hover"
    :aria-label="badgeText"
    :title="badgeText"
    :href="badgeLinkUrl"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div aria-hidden="true" :class="badgeClass">
      <i class="material-symbols-outlined">{{ icon }}</i>
    </div>
  </a>
  <div
    v-else
    role="button"
    tabindex="0"
    class="badge-group"
    :aria-label="badgeText"
    @click="toggleOpen"
    @keyup.enter="toggleOpen"
  >
    <div aria-hidden="true" :class="badgeClass">
      <i class="material-symbols-outlined">{{ icon }}</i>
    </div>
    <outside-click v-if="isOpen" @outside-click-action="toggleOpen">
      <div class="badge-info -open">
        <div class="badge-info-close" aria-hidden="true">
          <i class="material-symbols-outlined">close</i>
        </div>
        <div class="badge-info-text">
          {{ badgeText }}
        </div>
      </div>
    </outside-click>
  </div>
</template>

<script setup lang="ts">
/**
 * Comment badges types
 * - click to link (new tab)
 * - click to open tooltip
 */
import OutsideClick from '@/components/tool-tips/outside-click.vue'
import { ref } from 'vue'

/** PROPS */
withDefaults(
  defineProps<{
    icon: string
    badgeText: string
    badgeClass?: string
    badgeLinkUrl?: string
  }>(),
  {
    badgeClass: 'badge verified',
    btnClasses: '-anchor-link',
    badgeText: '',
    badgeLinkUrl: '',
  },
)

/** STATE */
const isOpen = ref(false)

/** METHODS */
function toggleOpen() {
  isOpen.value = !isOpen.value
}
</script>

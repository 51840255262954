<template>
  <div
    class="form__group"
    :class="{
      error: !!errorMessage,
      '-has-label': showLabel && label,
    }"
  >
    <div
      class="file-input"
      :class="{
        'discussion-image-upload': fileFor === 'discussion',
        'comment-image': fileFor === 'comment',
      }"
    >
      <label v-if="showLabel" :for="inputId">
        {{ label }}
        <slot name="label" />
      </label>
      <input
        :id="inputId"
        type="file"
        :name="name || inputId"
        :placeholder="placeholder"
        :aria-label="!showLabel && label ? label : undefined"
        v-bind="$attrs"
        v-on="validationListeners"
      />
      <!-- for remove file button -->
      <slot name="remove" />
    </div>
    <input-error
      v-if="errorMessage && showErrorMsg"
      :error-message="errorMessage"
      :show-icon="false"
    />
    <slot name="note" />
  </div>
</template>

<script setup lang="ts">
/**
 * For single file upload.  No v-model or value is use as there is no
 * two-way binding for file inputs.
 *
 * Should use @change to handle.
 */
import { computed, nextTick } from 'vue'
import { useField } from 'vee-validate'
import InputError from '@/components/forms/input-error.vue'

/** OPTIONS **/
defineOptions({
  // allow $attrs to flow through to the input element
  inheritAttrs: false,
})

/** PROPS **/
const props = withDefaults(
  defineProps<{
    inputId: string
    name?: string
    // v-model doesn't work on input type file / no two way binding
    placeholder?: string
    label?: string
    fileFor?: 'discussion' | 'comment' | ''
    showErrorMsg?: boolean
    showLabel?: boolean
    rules?: string
    // validation label
    validateAs?: string
  }>(),
  {
    name: '',
    placeholder: '',
    label: '',
    fileFor: '',
    showLabel: true,
    showErrorMsg: true,
    validateAs: '',
    rules: '',
  },
)

/** EMITS **/
// defined, but let vee-validate handle it with syncVModel
const emit = defineEmits(['update:modelValue', 'change'])

/** COMPUTED **/
// couldn't pass props directly in, needed to be reactive
const reactiveRules = computed(() => props.rules)

/** STATE **/
const { errorMessage, handleBlur, handleChange } = useField(() => props.inputId, reactiveRules, {
  // no v-model on files
  syncVModel: false,
  label: props.validateAs || props.label || 'Field',
  validateOnValueUpdate: false,
  validateOnMount: false,
})

/** METHODS **/
const validationListeners = {
  // validate on blur
  blur: (e: Event) => {
    handleBlur(e, true)
  },
  // update value on change
  change: (e: Event) => {
    handleChange(e, true)

    // change event to parent, but after validation
    nextTick(() => emit('change', e))
  },
  // no input event on files
}
</script>

<template>
  <div
    class="form__group"
    :class="{
      error: !!errorMessage,
      '-has-label': showLabel && label,
    }"
  >
    <label v-if="showLabel" :for="inputId">
      {{ label }}
      <slot name="label" />
    </label>
    <textarea
      :id="inputId"
      v-model="value"
      class="resize-vertical"
      :class="{
        short: !large,
      }"
      :name="name || inputId"
      :placeholder="placeholder"
      :aria-label="!showLabel && label ? label : undefined"
      v-bind="$attrs"
      v-on="validationListeners"
    />
    <input-error v-if="errorMessage && showErrorMsg" :error-message="errorMessage" />
    <slot name="note" />
  </div>
</template>

<script setup lang="ts">
/**
 * Simple textarea, note that textarea needs v-model per vue
 */
import { nextTick, computed } from 'vue'
import { useField } from 'vee-validate'
import InputError from '@/components/forms/input-error.vue'

/** OPTIONS **/
defineOptions({
  // allow $attrs to flow through to the input element
  inheritAttrs: false,
})

/** PROPS **/
const props = withDefaults(
  defineProps<{
    inputId: string
    name?: string
    modelValue: string
    // allow for v-model.number
    modelModifiers?: Record<string, (value: any) => any> | undefined
    large?: boolean
    placeholder?: string
    label?: string
    showErrorMsg?: boolean
    showLabel?: boolean
    rules?: string
    // validation label
    validateAs?: string
    // disable @input validation, needed when heavy / async validators
    validateAggressive?: boolean
  }>(),
  {
    name: '',
    modelModifiers: undefined,
    large: false,
    placeholder: '',
    label: '',
    validateAs: '',
    showLabel: true,
    showErrorMsg: true,
    rules: '',
    validateAggressive: true,
  },
)

/** EMITS **/
// defined, but let vee-validate handle it with syncVModel
const emit = defineEmits(['update:modelValue', 'change', 'blur', 'input'])

/** COMPUTED **/
// couldn't pass props directly in, needed to be reactive
const reactiveRules = computed(() => props.rules)

/** STATE **/
const { value, errorMessage, handleBlur, handleChange } = useField<string>(
  () => props.inputId,
  reactiveRules,
  {
    // allow vee-validate to sync with v-model
    syncVModel: true,
    // if do not allow syncVModel, must set initialValue
    // initialValue: props.modelValue,
    label: props.validateAs || props.label || 'Field',
    validateOnValueUpdate: false,
    validateOnMount: false,
  },
)

/** METHODS **/
const validationListeners = {
  // validate on blur
  blur: (e: Event) => {
    handleBlur(e, true)

    // call parent change event after v-model updates
    nextTick(() => emit('blur', e))
  },
  // update value on change
  change: (e: Event) => {
    handleChange(e, true)

    // change event to parent, but after validation
    nextTick(() => emit('change', e))
  },
  // enable / disable validation on input
  input: (e: Event) => {
    // if error, aggressively validate as user types
    // but avoid aggressive checks for type email, as it has some heavy spellchecking
    if (props.validateAggressive) {
      handleChange(e, !!errorMessage.value)
    }

    // change event to parent, but after validation
    nextTick(() => emit('input', e))
  },
}
</script>

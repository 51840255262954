<template>
  <div
    class="avatar-container"
    :class="{ ' has-notification': user.notificationUnreadCount }"
    data-hj-suppress
  >
    <span v-if="hasAchievement" class="has-achievement"></span>
    <span v-if="!user.isLoggedIn" class="iconfont-profile-icon avatar-guest"></span>
    <img v-else-if="user.isLoggedIn && user.avatar" :alt="user.displayName" :src="user.avatar" />
    <span v-else class="avatar-placeholder demi">{{ displayNameFirstLetter }}</span>
    <i
      class="material-symbols-outlined fill nav-carrot"
      aria-hidden="true"
      data-icon="arrow_drop_down"
    ></i>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
/**
 * Powers the unread notification dot on User Avatar in Nav
 * data-hj-suppress = hide from hotjar screen recordings
 */
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'

export default defineComponent({
  name: 'MenuAvatar',
  props: {
    hasAchievement: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },

  computed: {
    ...mapState(useUserAuthStore, ['user', 'displayNameFirstLetter']),
  },
})
</script>

<template>
  <vee-form v-slot="{ validate }" as="">
    <form
      :id="!isEdit ? 'add-note-form' : `edit-note-form-${formData.id}`"
      class="note-form standard-form"
      method="post"
      @submit.prevent="handleSubmit(validate)"
    >
      <!-- error state -->
      <callout v-if="error && !forSchedulableCompleteSlider" type="error">
        <div>{{ error }}</div>
      </callout>

      <input v-if="isEdit" name="_method" type="hidden" value="PUT" />

      <textarea-input
        ref="textarea"
        v-model="formData.body"
        :input-id="`note-${formData.id}`"
        label="Note"
        :show-label="false"
        placeholder="Write a personal note or document your reps and weights!"
        rules="required"
        maxlength="100000"
        :large="true"
      />

      <div :class="forSchedulableCompleteSlider ? 'save-extras -flex -center' : 'form-actions'">
        <div v-if="!deleting" class="form-action">
          <csrf-input />
          <submit-button
            :submitting="processing"
            label="Save"
            submitting-label="Saving…"
            :classes="forSchedulableCompleteSlider ? 'btn -dark' : '-main'"
            @submit-action="handleSubmit(validate)"
          />
        </div>
        <div v-if="!deleting && !forSchedulableCompleteSlider" class="form-action">
          <button
            type="button"
            class="btn -link"
            :disabled="processing ? true : undefined"
            @click="$emit('note-cancel-action', $event)"
          >
            Cancel
          </button>
        </div>
        <div v-if="isEdit && !forSchedulableCompleteSlider" class="form-action flex-right">
          <delete-button
            v-if="isEdit"
            :modal-id="`note-${note.id}-delete-modal`"
            modal-heading="Delete Note?"
            modal-text="Are you sure you want to delete this note?  "
            label="Delete Note"
            classes="-delete -ico-only"
            :submitting="deleting"
            @delete-action="handleDelete"
          />
        </div>
      </div>
    </form>
  </vee-form>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'pinia'
import { useNotesStore } from '@/stores/notes'
import { Form as VeeForm } from 'vee-validate'
import TextareaInput from '@/components/forms/textarea-input.vue'
import CsrfInput from '@/components/forms/csrf-input.vue'
import Callout from '@/components/notifications/callout.vue'
import SubmitButton from '@/components/buttons/submit-button.vue'
import DeleteButton from '@/components/buttons/delete-button.vue'

/**
 * Handles add and edit.
 */
export default defineComponent({
  name: 'NoteForm',

  components: {
    VeeForm,
    TextareaInput,
    CsrfInput,
    SubmitButton,
    DeleteButton,
    Callout,
  },
  props: {
    notableId: {
      required: true,
      type: Number,
    },
    notableType: {
      default: 'workout',
      type: String,

      // db notable types, diff than entityType
      validator: (val) =>
        ['workout', 'custom_workout', 'article', 'wellness_video', 'calendar'].includes(val),
    },

    // used for edit
    note: {
      type: Object,
      default: () => {},
    },

    // options
    // used to tell where this component is being used, and then apply specific
    // logic or styling based on that parent component
    parentComponent: {
      type: String,
      default: '',

      validator: (val) => ['', 'schedulable-complete-slider'].includes(val),
    },

    // used for calendar notes
    calendarDate: {
      type: String,
      default: '',
    },
  },
  emits: ['note-cancel-action', 'note-saved-action', 'note-error-action'],

  // component data
  data() {
    return {
      isEdit: !!(this.note && this.note.id),
      // form processing state
      processing: false,
      deleting: false,
      error: '',
      formData: {
        // defaults
        id: '',
        body: '',
        notableType: this.notableType,
        notableId: this.notableId,
        noteDate: this.calendarDate,
        // edit
        ...this.note,
      },
    }
  },

  computed: {
    // Using the parentComponent prop and this method to manipulate various styling
    // and show/hide of some elements. Will need to possibly adjust the logic if
    // this component is added to other locations in the future.
    forSchedulableCompleteSlider() {
      return this.parentComponent && this.parentComponent === 'schedulable-complete-slider'
    },
  },

  mounted() {
    // focus text area after dom loads
    if (this.$refs.textarea && this.$refs.textarea.$el) {
      const textarea = this.$refs.textarea.$el.querySelector('textarea')

      if (textarea) {
        textarea.focus()
      }
    }
  },

  // component methods
  methods: {
    ...mapActions(useNotesStore, ['addNote', 'editNote', 'deleteNote']),

    // used by schedulable complete slider
    closeAction() {
      this.$emit('note-cancel-action')
    },

    // add / edit
    async handleSubmit(validate) {
      if (this.processing) {
        return
      }

      this.processing = true
      this.error = ''
      this.setAndEmitError(this.error)

      try {
        // any other validation errors
        const results = await validate()

        // save if no validation error
        // see pinia note.addNote for how added data is handled
        if (results.valid) {
          let note

          // add / edit toggle
          if (this.isEdit) {
            // only editing body
            note = await this.editNote({
              id: this.formData.id,
              body: this.formData.body,
              noteDate: this.formData.noteDate,
            })
          } else {
            // adding
            note = await this.addNote(this.formData)
          }

          // let parent know, could collapse form
          this.$emit('note-saved-action', note)
        }

        // reset form state
        this.processing = false
        this.error = ''
      } catch (err) {
        // failed, reset form state
        this.processing = false
        this.error = `Unable to save note, please try again`
        this.setAndEmitError(this.error)
      }
    },

    // soft delete
    async handleDelete() {
      if (this.deleting) {
        return
      }

      this.deleting = true
      this.error = ''

      try {
        await this.deleteNote(this.note)
      } catch (err) {
        this.error = `Unable to delete note, please try again`
        this.setAndEmitError(this.error)
      } finally {
        this.deleting = false
      }
    },

    setAndEmitError(err) {
      this.$emit('note-error-action', err)
    },
  },
})
</script>

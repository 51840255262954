<template>
  <div class="complete-program-progress">
    <div class="complete-program-progress-img -has-cover-info">
      <card-src-set-image
        type="browser-select-max-800"
        :img-prefix="imageUrlPrefix"
        :image="plan.image"
        :title="plan.title"
        lazy-loading="eager"
      />
      <image-icon
        v-if="plan.isRoutine"
        :image-icon-set="plan.imageIconSet!"
        :image-icon="plan.imageIcon"
        title=""
        sub-title=""
      />
    </div>
    <div class="complete-program-progress-content">
      <div class="title">{{ plan.shortName }}</div>
      <div class="day">Day {{ currentPlanDay }}</div>
      <div v-if="error" class="ready-to-complete -error" aria-live="polite">Error Saving</div>
      <div v-else-if="!readyToComplete && !isCompleteData" class="ready-to-complete">
        {{ grouping.dayPercentComplete }}% Complete
      </div>
      <div v-else-if="readyToComplete" class="ready-to-complete">Ready to complete!</div>
      <div v-else-if="isCompleteData" class="ready-to-complete" aria-live="polite" role="status">
        Complete
      </div>
    </div>
    <div v-if="grouping.canComplete && completeButton" class="complete-program-progress-action">
      <div class="complete-button">
        <icon-button
          :icon="isCompleteData ? 'check_circle' : 'circle'"
          :classes="`-ico-only -no-container ${readyToComplete ? '-ready' : ''} ${
            error ? '-error' : ''
          }`"
          :label="isCompleteData ? 'Mark Program Incomplete' : 'Mark Program Complete'"
          @click-action="toggleProgramComplete"
        />
      </div>
    </div>
    <div class="progress-container">
      <div
        class="current-progress"
        :class="{
          '-ready': readyToComplete,
        }"
        :style="`width: ${grouping.dayPercentComplete}%;`"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { fbApi } from '@/utils/http-api'
import CardSrcSetImage from '@/components/images/card-src-set-image.vue'
import ImageIcon from '@/components/images/image-icon.vue'
import IconButton from '@/components/buttons/icon-button.vue'
import type {
  ProgramComplete,
  ProgramCompleteGrouping,
  ProgramCompletePlan,
} from '@/types/calendar-types'

/**
 * Shows on slider-schedulable-complete if the schedulable / entity is
 * a part of program / challenge routine
 */
export default defineComponent({
  name: 'ProgramStatus',
  components: { IconButton, CardSrcSetImage, ImageIcon },
  props: {
    plan: {
      type: Object as PropType<ProgramCompletePlan>,
      required: true,
    },
    grouping: {
      type: Object as PropType<ProgramCompleteGrouping>,
      required: true,
    },
    completeButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['program-complete-updated'],
  data() {
    return {
      isCompleteData: this.grouping.isComplete,

      // keep initial current plan day
      // on program complete, the API returns to last day of the program
      // EX: loaded on day 18, keep day 18 after marking complete
      currentPlanDay: this.grouping.currentPlanDay,
      loading: false,
      error: '',
    }
  },
  computed: {
    ...mapState(useUserAuthStore, ['cdn']),

    readyToComplete() {
      if (this.grouping.canComplete && !this.isCompleteData) {
        return true
      }

      return false
    },

    imageUrlPrefix() {
      // get siteassets url from page load, allows CDN url to be added
      const siteAssetsPrefix = this.cdn.siteAssetsPrefix || '/siteassets'
      return `${siteAssetsPrefix}/${this.plan.imgDir}`
    },
  },
  methods: {
    /**
     * Toggles plan complete
     */
    async toggleProgramComplete() {
      // disable double submit
      if (this.loading) {
        return
      }

      // set loading and instantly toggle state (assuming API will be success)
      // for quick UI.  This will undo if error
      this.loading = true
      this.error = ''

      // keep 0 and 1 values to stay in line with calendar day view
      this.isCompleteData = this.isCompleteData ? 0 : 1

      try {
        // we have already toggled isCompleteData, so api url is backwards
        const res = await fbApi.put(
          `/calendar/programs/${this.grouping.id}/${
            this.isCompleteData ? 'complete' : 'incomplete'
          }`,
          {
            skipErrorModal: true,
          },
        )

        if (this.isCompleteData) {
          // complete includes stats

          const { grouping, stats, rewardEarned } = res.body.data.programComplete as ProgramComplete
          this.$emit('program-complete-updated', {
            grouping,
            stats,
            rewardEarned,
          })
        } else {
          // incomplete returns nothing, so just toggle isComplete status
          this.$emit('program-complete-updated', {
            grouping: {
              ...this.grouping,
              isComplete: this.isCompleteData,
            },
          })
        }
      } catch (e) {
        // undo marking complete and send it up the chain
        this.isCompleteData = this.isCompleteData ? 0 : 1

        this.$emit('program-complete-updated', {
          grouping: {
            ...this.grouping,
            isComplete: this.isCompleteData,
          },
        })
        this.error = 'Error completing program'
      } finally {
        // re-enable button action
        this.loading = false
      }
    },
  },
})
</script>

import { defineStore } from 'pinia'
import type {
  CalendarState,
  UserScheduledItem,
  SetIsCompletePayload,
  Trackers,
  CompleteSlider,
  ScheduledPlanIdStats,
  DayComplete,
  ScheduledMod,
} from '@/types/calendar-types'

export const useCalendarStore = defineStore('calendar', {
  state: (): CalendarState => ({
    // calendar day: data from window.Laravel initial page load
    // detail: mutation invoked in detail-complete-button.vue
    scheduledItems:
      window.Laravel &&
      window.Laravel.calendarInit &&
      Array.isArray(window.Laravel.calendarInit.scheduledItems)
        ? window.Laravel.calendarInit.scheduledItems
        : [],

    // for add to calender + complete at the same time (when unscheduled from detail view)
    // single scheduledItem obj or null
    unscheduledItem: null,

    // set on detail views complete button, modal calendar reacts to scheduleDate
    // when adding to calendar
    scheduleDate: null, // YYYY-MM-DD

    // allows only one workout complete XHR at once, so users don't rage
    // click all the workout complete buttons and get tons of sliders at once
    completeActionsDisabled: false,

    // used with plans-scheduled-instances.vue
    refreshScheduledInstances: false,

    // powers calendar day status in slider AND calendar day view
    dayComplete: {
      scheduleDate: null,
      scheduleDateFormatted: null,
      completeType: null,
      isComplete: false,
      scheduledCount: 0,
      completedCount: 0,
      dayPercentComplete: 0,
    },

    // schedulable complete and program complete sliders are at different
    // DOM levels, component communication via pinia state
    slider: {
      isOpen: false,
      type: '',
      completeText: '',
      scheduledId: null,
      groupingId: null,
      entityId: null,
      entityType: '',
      entityCategory: '',
      plan: null,
      grouping: null,
      stats: null,
      trackerDefaults: null,
    },

    scheduledPlansStats: {},

    // used for loading states when swapping / modifying a workout
    scheduledMods: {},
  }),

  getters: {
    // check if any completed workout within scheduledItems
    hasCompletedWorkout: (state: CalendarState) => {
      return state.scheduledItems.some((item) => {
        if (item.schedulableType === 'workout' || item.schedulableType === 'custom_workout') {
          return !!item.isComplete
        }
        return false
      })
    },

    // check if any workout is scheduled
    hasScheduledWorkout: (state: CalendarState) => {
      return state.scheduledItems.some((item) => {
        if (item.schedulableType === 'workout' || item.schedulableType === 'custom_workout') {
          return true
        }
        return false
      })
    },

    isSchedulableSliderOpen: (state: CalendarState) => {
      return state.slider.isOpen && state.slider.type === 'schedulable'
    },

    isProgramSliderOpen: (state: CalendarState) => {
      return state.slider.isOpen && state.slider.type === 'program'
    },

    getScheduledItemById:
      (state: CalendarState) =>
      (id: number): UserScheduledItem | undefined => {
        return state.scheduledItems.find((si) => si.id === id)
      },

    getScheduledPlanStats: (state: CalendarState) => (planId: number) => {
      return state.scheduledPlansStats[planId]
    },

    getScheduledMod: (state: CalendarState) => (scheduledModKey: string | number) => {
      return state.scheduledMods[scheduledModKey]
    },
  },

  actions: {
    /**
     * Adds scheduledItems and promotes unscheduled item to scheduledItems
     */
    addScheduledAndPromotedUnscheduled(scheduledItem: UserScheduledItem) {
      const combinedScheduledItems = [scheduledItem]

      if (this.unscheduledItem) {
        combinedScheduledItems.push(this.unscheduledItem)
      }

      this.addScheduledItems(combinedScheduledItems)
      this.setUnscheduledItem(null)
    },

    setScheduledItems(scheduledItems: UserScheduledItem[]) {
      this.scheduledItems = scheduledItems
    },

    addScheduledItems(scheduledItems: UserScheduledItem[]) {
      this.scheduledItems = [...this.scheduledItems, ...scheduledItems]
    },

    replaceScheduledItem(id: number, newUserScheduledItem: UserScheduledItem) {
      const filteredArray = this.scheduledItems.filter((scheduledItem) => {
        return scheduledItem.id !== id
      })

      if (filteredArray.length !== this.scheduledItems.length) {
        this.scheduledItems = [...filteredArray, newUserScheduledItem]
      }
    },

    removeScheduledItem(id: number) {
      this.scheduledItems = this.scheduledItems.filter((scheduledItem) => {
        return scheduledItem.id !== id
      })
    },

    setIsComplete(payload: SetIsCompletePayload) {
      const scheduledItem = this.scheduledItems.find(
        (scheduledItem) => scheduledItem.id === payload.id,
      )

      if (scheduledItem) {
        scheduledItem.isComplete = payload.isComplete

        // clear tracker data on incomplete
        if (!payload.isComplete) {
          scheduledItem.trackers = null
        }
      }
    },

    setTrackers(scheduledItemId: number, trackers: Trackers) {
      const scheduledItem = this.scheduledItems.find(
        (scheduledItem) => scheduledItem.id === scheduledItemId,
      )

      if (scheduledItem) {
        scheduledItem.trackers = {
          ...trackers,
        }
      }
    },

    setUnscheduledItem(item: UserScheduledItem | null) {
      this.unscheduledItem = item
    },

    setScheduleDate(date: string) {
      this.scheduleDate = date
    },

    setCompleteActionsDisabled(disabled: boolean) {
      this.completeActionsDisabled = disabled
    },

    updateSlider(slider: Partial<CompleteSlider>) {
      this.slider = {
        ...this.slider,
        ...slider,
      }
    },

    updateDayComplete(dayComplete: Partial<DayComplete>) {
      this.dayComplete = {
        ...this.dayComplete,
        ...dayComplete,
      }
    },

    setRefreshScheduledInstances(refresh: boolean) {
      this.refreshScheduledInstances = refresh
    },

    setScheduledPlansStats(results: any) {
      this.scheduledPlansStats = results
    },

    updateScheduledPlanStats(scheduledPlanIdStatsMap: ScheduledPlanIdStats) {
      this.scheduledPlansStats = {
        ...this.scheduledPlansStats,
        ...scheduledPlanIdStatsMap,
      }
    },

    initScheduledMod(scheduledModKey: string) {
      this.scheduledMods = {
        ...this.scheduledMods,
        [scheduledModKey]: {
          scheduledItemId: 0,
          fetching: false,
          fetchError: '',
          modSwapping: false,
          modRestoring: false,
          modError: '',
          activeMenuControl: '',
          resultsCustomScreen: '',
        },
      }
    },

    // assumes obj already set via initScheduleMod
    updateScheduledMod(scheduledModKey: string, mod: Partial<ScheduledMod>) {
      this.scheduledMods[scheduledModKey] = {
        ...this.scheduledMods[scheduledModKey],
        ...mod,
      }
    },

    removeScheduleMod(scheduledModKey: string) {
      delete this.scheduledMods[scheduledModKey]
    },
  },
})

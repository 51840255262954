import { defineStore } from 'pinia'
import type { EntityContextState } from '@/types/entity-context-types'

export const useEntityContextStore = defineStore('entityContext', {
  state: (): EntityContextState => ({
    // model data, generic to be re-usable
    // type: workout, customWorkout, plan, category, etc
    entity: window.Laravel && window.Laravel.entity ? window.Laravel.entity : {},
    context: window.Laravel && window.Laravel.context ? window.Laravel.context : {},
  }),

  getters: {},

  actions: {},
})

<template>
  <input name="_token" type="hidden" :value="userAuthStore.csrfToken" />
</template>

<script setup lang="ts">
import { useUserAuthStore } from '@/stores/user-auth'

/** STATE **/
const userAuthStore = useUserAuthStore()
</script>

import Callout from '@/components/notifications/callout.vue'
import DashboardToggleBar from '@/components/analytics/details/dashboard-toggle-bar.vue'
import CookieConsentButton from '@/components/buttons/cookie-consent-button.vue'
import Comments from '@/components/comments/comments.vue'
import AboutCarousel from '@/components/carousels/about-carousel.vue'
import PlanCard from '@/components/plans/plan-card.vue'
import NotificationCount from '../components/notifications/notification-count.vue'
import MenuAvatar from '../components/users/menu-avatar.vue'
import PlusFreeAccessCountdown from '@/components/notifications/plus-free-access-countdown.vue'
import type { App } from 'vue'

// code split imports
import discussionsCodeSplit from '@/common/discussions'
import miscCodeSplit from '@/common/misc-ctas'
import searchCodeSplit from '@/common/search'
import accountCodeSplit from '@/common/users'

/**
 * Registered components for all vue apps.
 */
export function loadAppComponents(app: App) {
  // code split components
  accountCodeSplit(app)
  discussionsCodeSplit(app)
  miscCodeSplit(app)
  searchCodeSplit(app)

  /* NOT CODE SPLIT */
  // used on every page so not chunked
  app.component('NotificationCount', NotificationCount)
  app.component('MenuAvatar', MenuAvatar)

  // plus free access mode
  app.component('PlusFreeAccessCountdown', PlusFreeAccessCountdown)

  // only plan card used on homepage current for all users
  app.component('PlanCard', PlanCard)

  // ctas and callouts
  app.component('Callout', Callout)
  app.component('DashboardToggleBar', DashboardToggleBar)
  app.component('CookieConsentButton', CookieConsentButton)

  // carousel
  app.component('AboutCarousel', AboutCarousel)

  // comments widget
  app.component('Comments', Comments)
}

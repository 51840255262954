<template>
  <div class="cover-info">
    <div class="cover-icon">
      <i :class="imageIconSet" aria-hidden="true">{{ imageIcon }}</i>
    </div>
    <div v-if="title" class="cover-title">
      {{ title }}
    </div>
    <div v-if="subTitle" class="cover-sub-title">
      {{ subTitle }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * Used on routines and gifts to display an icon over the background
 * image.
 */
export default defineComponent({
  name: 'ImageIcon',
  components: {},
  props: {
    imageIconSet: {
      type: String,
      required: true,
    },
    imageIcon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
</script>

// sentry error tracking
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'

/**
 * Sentry with vue integration
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/vue/usage/
 */
const errorTrackerInit = (app: App) => {
  if (window.Laravel && window.Laravel.sentryDsn) {
    Sentry.init({
      // vue specific options
      // note that App is the SPA vue instance
      app,
      attachProps: true,
      logErrors: true,

      dsn: window.Laravel.sentryDsn,
      environment: window.Laravel.env,
      integrations: [], // not using tracing or replay
      sampleRate: 0.5,
      allowUrls: [/fitnessblender\.com/],
      denyUrls: [
        // chrome extension errors
        /extensions\//i,
        /^chrome:\/\//i,
      ],
      ignoreErrors: [
        // can safely ignore this
        // @see https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
        'ResizeObserver loop limit exceeded',
      ],
      // @see https://github.com/getsentry/sentry-javascript/issues/2210
      // chrome extensions can cause errors
      beforeSend(event: any) {
        // try catch is only so you dont have to assert any null values in that long chain
        try {
          if (event.extra.__serialized__.detail.reason.message === 'Extension context invalidated.')
            return null
        } catch (sendErr) {
          return event
        }
        return event
      },
    })

    // set user based on anonymized ip and user id base64 hash
    if (window.btoa && window.navigator) {
      let userId = ''
      let userRole = 'Anonymous'

      if (window.Laravel) {
        if (window.Laravel.user && window.Laravel.user.anonymizedIp) {
          userId += `${window.Laravel.user.anonymizedIp} `
        }

        if (window.Laravel.user && window.Laravel.user.isLoggedIn && window.Laravel.user.role) {
          userRole = window.Laravel.user.role === 1 ? 'Free Member' : 'FB Plus Member'
        }
      }

      if (navigator.language) {
        userId += `${navigator.language} `
      }

      if (navigator.platform) {
        userId += `${navigator.platform} `
      }

      if (navigator.hardwareConcurrency) {
        userId += `${navigator.hardwareConcurrency} `
      }

      if (navigator.vendor) {
        userId += `${navigator.vendor} `
      }

      if (navigator.plugins && navigator.plugins.length) {
        userId += `${navigator.plugins.length} `
      }

      Sentry.setUser({
        id: window.btoa(userId),
      })

      Sentry.setTag('user_role', userRole)
      // console.log('got here', window.Laravel, navigator, userId, {
      //   id: window.btoa(userId),
      //   segment,
      // });
    }
    // end set user
  }
}
export default errorTrackerInit

/**
 * Helper method to avoid being tightly coupled with sentry
 * @param {Error} err
 */
export const captureException = (err: Error) => {
  return Sentry.captureException(err)
}

/**
 * Helper method to avoid being tightly coupled with sentry, includes context
 */
export const captureMessage = ({
  msg,
  extras,
  level,
}: {
  msg: string
  extras: {}
  level?: 'fatal' | 'error' | 'warning' | 'info'
}) => {
  return Sentry.withScope((scope) => {
    scope.setExtras(extras)
    scope.setLevel(level || 'error') //  fatal, error, warning, info

    // send error
    return Sentry.captureMessage(msg)
  })
}

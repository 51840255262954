import { HEADER_HEIGHT } from '@/constants'
import { trackPageView } from '@/utils/analytics'
import type { RouterScrollBehavior } from 'vue-router'

type PageMeta = {
  title: string
  desc?: string
  scrollTo: string
  scrollDelay: number
  focusTitle: boolean
  analyticsTrack: boolean
  analyticsSkipFromPaths?: string[]
}

type PageTitleDesc = {
  title: string
  desc?: string
}

/**
 * Manually update page title and desc, focus and scroll to the top of the page
 * - for SPA routes, will focus #spa-page-title or fall back to #app
 * @param title
 * @param desc
 * @param scrollTo
 * @param focusTitle
 * @param analyticsTrack
 */
export const spaMetaUpdateAndTrack = ({
  title = '',
  desc,
  focusTitle = true,
  analyticsTrack = false,
}: PageMeta) => {
  /* TITLE TAG UPDATES */
  updateMetaPageTitle({ title, desc })
  // end title

  // focus primary title
  if (focusTitle) {
    // delayed focus to allow for rendering
    setTimeout(() => {
      const focusEl = document.querySelector('#spa-title')

      if (focusEl && focusEl instanceof HTMLElement) {
        focusSpaTitle(focusEl, {
          preventScroll: true,
        })
      }
    }, 250)
  }

  // manually push to analytics
  // let XHRs settle before sending
  setTimeout(() => {
    if (analyticsTrack) {
      trackPageView()
    }
  }, 150)
}

/**
 * Allows for async page title updates
 */
export const updateMetaPageTitle = ({ title = '', desc }: PageTitleDesc) => {
  const titleEl = document.querySelector('head > title')

  if (title && titleEl) {
    titleEl.innerHTML = `${title} | Fitness Blender`
  }
}

/**
 * Focus SPA title but without outline
 */
export const focusSpaTitle = (elem: HTMLElement | null, focusOptions?: FocusOptions) => {
  if (elem) {
    elem.style.outline = 'none'
    elem.setAttribute('tabindex', '-1')
    elem.focus(focusOptions)
  }
}

/**
 * Handles scroll positioning as the route loads. It is called on any page
 * that includes vue (even blade only pages).
 *
 * USE CASE - hash
 * [x] - navigate forward + hash: no scroll (handled by smoothScroll lib OR vue onMounted scroll events)
 * [x] - same page hash + scrollPosition: scroll to element (otherwise page doesn't move)
 *
 * USE CASE - savedPosition (without hash)
 * [x] - same page reload
 * [x] - browse back button
 * [x] - browser forward button
 *
 * USE CASE - Opt out of scroll
 * [x] - manually disabled: (param.noScroll = 'noScroll')
 *
 * USE CASE - Scroll to element (no hash)
 * [x] - to.meta.scrollTo
 *
 * USE CASES - Scroll Top
 * [x] - navigate forward
 * [x] - any other navigation
 *
 *
 * ASYNC NOTE
 * savedPosition may not actually be on screen yet to do async loading, so we
 * give it a little time to render before scrolling.
 */
export const routeScrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  // console.log('routeScrollBehavior: invoked', to, from, savedPosition)

  // allow for async loading
  return new Promise((resolve) => {
    let interval: number | ReturnType<typeof setInterval> = 0
    let retryAttempt = 1
    const retryAttemptMax = 10
    const retryMs = 100
    const defaultScrollDelayMs = 25
    const toMeta = <PageMeta>to.meta

    // hash scrolling
    // EX: FAQs page
    if (to.hash) {
      // forward / back button used on same page with hash, scroll to element
      if (to.path === from.path && savedPosition) {
        // console.log('routeScrollBehavior: hash - same page - scroll to element')
        resolve({
          el: to.hash,
          top: HEADER_HEIGHT,
        })
        return
      }

      // smoothScroll lib handling for blade, onMounted scrolling handled for vue
      // can clean this up in the future
      // console.log('routeScrollBehavior: hash - no scroll')
      resolve(false)
      return
    }
    // end hash

    // savedPosition scrolling (no hash)
    // browser back button
    // browser forward button
    if (savedPosition) {
      // console.log(
      //   'routeScrollBehavior: savedPosition',
      //   savedPosition,
      //   document.body.scrollHeight,
      //   screen.height,
      //   screen.height - HEADER_HEIGHT + savedPosition.top,
      // )

      // wait defaultScrollDelayMs before starting to let route settle
      setTimeout(() => {
        // try to scroll first before interval (mostly for SSR pages)
        if (document.body.scrollHeight >= screen.height - HEADER_HEIGHT + savedPosition.top) {
          resolve(savedPosition)
          return
        }

        interval = setInterval(() => {
          // console.log(
          //   'routeScrollBehavior: savedPosition retryAttempt',
          //   retryAttempt,
          //   document.body.scrollHeight,
          //   screen.height - HEADER_HEIGHT + savedPosition.top,
          // )

          // stop retrying
          if (retryAttempt >= retryAttemptMax) {
            clearInterval(Number(interval))
            resolve(savedPosition)
          }

          // can scroll to scroll position
          // note if at very bottom of screen, document.body.scrollHeight may never be larger than savedPosition.top + screen.height
          // and eventually it will stop retrying
          if (document.body.scrollHeight >= screen.height - HEADER_HEIGHT + savedPosition.top) {
            clearInterval(Number(interval))
            resolve(savedPosition)
          }

          retryAttempt += 1
        }, retryMs)
      }, defaultScrollDelayMs)
      return
    }
    // end savedPosition

    // opt out of scroll
    // no scroll - manually disabled via route param
    // no scroll - if path is teh same (and only query strings are different)
    if (to.params.noScroll === 'noScroll') {
      // console.log('routeScrollBehavior: no scroll')
      resolve(false)
      return
    }
    // end opt out of scroll

    // scroll to element (no hash)
    if (toMeta.scrollTo) {
      // wait a minimum of defaultScrollDelayMs before starting to let route settle
      const scrollDelay = toMeta.scrollDelay ? Number(toMeta.scrollDelay) : defaultScrollDelayMs
      // console.log('routeScrollBehavior: scroll to element', toMeta, scrollDelay)

      return setTimeout(() => {
        const scrollEl = document.querySelector(`${toMeta.scrollTo}`)

        if (scrollEl) {
          resolve({
            el: scrollEl,
            top: HEADER_HEIGHT,
          })
        }
      }, scrollDelay)
    }
    // end scroll to element (no hash)

    // navigate forward top / catch all
    // no need to wait defaultScrollDelayMs as it is the top
    // console.log('routeScrollBehavior: catch all')
    resolve({ left: 0, top: 0 })
    // end navigate forward top
  })
}

/**
 * SAFARI BFCACHE NONSENSE
 * Capture scroll position for Safari's "back/forward cache" (bfcache). Other
 * browsers use vue-routers scrollBehavior method w/o issue, but Safari will
 * not even invoke the method when using the back/forward buttons.
 *
 * vue-router uses the 'beforeunload' event capture scroll position and replaces
 * state, but this event never fires on iOS safari (but does on desktop).
 *
 * The 'pagehide' event fires on desktop and iOS Safari, so use this to capture the
 * scrollPosition just for Safari bfcache hits.
 *
 */
export const safariCaptureScrollPosition = () => {
  // console.log('safariCaptureScrollPosition invoked', window.scrollY, window.scrollX)

  history.replaceState(
    {
      ...history.state,
      // vue-router sets scroll property in `beforeunload` event, which will not fire on iOS Safari
      // so make a new one
      safariScroll: {
        left: window.scrollX,
        top: window.scrollY,
      },
    },
    '',
  )
}

/**
 * SAFARI BFCACHE NONSENSE
 * Event.persisted is true only fires on Safari forward / back buttons (bfcache), all other browsers
 * will be false and use vue-router scrollBehavior function.
 *
 * If event.persisted and history.state.safariScroll exists, scroll to that position, which
 * should only happen on Safari forward / back button presses.
 */
export const safariRestoreScrollPosition = (event: PageTransitionEvent) => {
  if (event.persisted && history.state && history.state.safariScroll) {
    // console.log('bf cache with scroll state')
    window.scrollTo(history.state.safariScroll)
    return
  }

  // console.log('not bf cache or missing scroll state')
}

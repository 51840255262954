<template>
  <complete-slider-hero
    :complete-text="completeText"
    :entity-type="entityType"
    :show-confetti="confettiEnabled"
    @close-end="handleClose"
  >
    <div class="complete-extras">
      <!-- day status -->
      <day-status :day-complete="dayComplete" @day-complete-updated="dayCompleteUpdated" />

      <!-- program status -->
      <program-status
        v-if="showPlanStatus"
        :plan="plan!"
        :grouping="grouping!"
        @program-complete-updated="programCompleteUpdated"
      />

      <!-- menu / nav -->
      <complete-actions-menu
        v-if="!menuActionSelected"
        :schedule-date="dayComplete.scheduleDate"
        :trackers-button="hasWorkoutTrackers"
        :note-button="hasNote"
        :tag-button="hasTag"
        :calendar-day-button="true"
        :effects-button="true"
        @menu-action="setMenuAction"
      />

      <!-- tracker form -->
      <workout-tracker-form
        v-if="hasWorkoutTrackers && menuActionSelected === 'trackers'"
        :scheduled-id="scheduledId"
        :minutes="trackerDefaults!.minutes"
        :difficulty="trackerDefaults!.difficulty"
        @stats-updated="$emit('stats-updated', $event)"
        @close-action="clearMenuAction"
      />

      <!-- note form -->
      <slider-note-form
        v-if="hasNote && menuActionSelected === 'note'"
        :entity-id="entityId"
        :entity-type="entityType"
        :entity-category="entityCategory"
        :note="note"
        @saved-action="setNote"
        @close-action="clearMenuAction"
      />

      <!-- tag form - v-if to not fire XHR fore free user -->
      <!-- v-show to not fire it a second time for plus user -->
      <slider-tags-form
        v-if="hasTag"
        v-show="menuActionSelected === 'tag'"
        :entity-id="entityId"
        :entity-type="entityType"
        :entity-category="entityCategory"
        @close-action="clearMenuAction"
      />
    </div>
  </complete-slider-hero>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import CompleteSliderHero from '@/components/modals/sliders/complete-slider-hero.vue'
import WorkoutTrackerForm from '@/components/modals/sliders/workout-tracker-form.vue'
import SliderNoteForm from '@/components/modals/sliders/slider-note-form.vue'
import SliderTagsForm from '@/components/modals/sliders/slider-tags-form.vue'
import ProgramStatus from '@/components/modals/sliders/program-status.vue'
import DayStatus from '@/components/modals/sliders/day-status.vue'
import CompleteActionsMenu from '@/components/modals/sliders/complete-actions-menu.vue'
import type {
  DayComplete,
  ProgramComplete,
  ProgramCompleteGrouping,
  ProgramCompletePlan,
  Trackers,
} from '@/types/calendar-types'

/**
 * Slide up modal for schedulable item completes (not program complete).
 *
 * Workouts and custom workouts have trackers
 */
export default defineComponent({
  name: 'SchedulableCompleteSlider',
  components: {
    WorkoutTrackerForm,
    SliderNoteForm,
    SliderTagsForm,
    CompleteSliderHero,
    ProgramStatus,
    DayStatus,
    CompleteActionsMenu,
  },
  props: {
    scheduledId: {
      type: Number,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    entityType: {
      default: 'workout',
      type: String,
      validator: (val: string) =>
        ['workout', 'custom_workout', 'article', 'wellness_video'].includes(val),
    },
    entityCategory: {
      default: '',
      validator: (val: string) => ['', 'recipe'].includes(val),
    },

    // should mirror *-complete-button.vue
    completeText: {
      type: String,
      default: '',
    },

    // see pinia
    dayComplete: {
      type: Object as PropType<DayComplete>,
      required: true,
    },

    // workouts { minutes, difficulty }
    // other trackers in the future
    trackerDefaults: {
      type: Object as PropType<Trackers | null>,
      default: () => null,
    },

    // optional, only pass in if entity is a part of a program
    plan: {
      type: Object as PropType<ProgramCompletePlan | null>,
      default: () => null,
    },

    // optional, only pass in if entity is a part of a program
    grouping: {
      type: Object as PropType<ProgramCompleteGrouping | null>,
      default: () => null,
    },
  },
  emits: ['close', 'stats-updated', 'program-complete-updated', 'day-complete-updated'],
  data() {
    return {
      showProgramCompleteSlider: false,
      menuActionSelected: '',
      note: {},
    }
  },
  computed: {
    ...mapState(useUserAuthStore, ['features', 'confettiEnabled']),

    hasWorkoutTrackers() {
      if (
        this.features.WORKOUT_COMPLETE_TRACKING &&
        this.trackerDefaults &&
        ['workout', 'custom_workout'].includes(this.entityType)
      ) {
        return true
      }

      return false
    },

    hasNote() {
      if (this.features.NOTES && !['plan'].includes(this.entityType)) {
        return true
      }

      return false
    },

    hasTag() {
      if (this.features.TAGS && !['custom_workout', 'plan'].includes(this.entityType)) {
        return true
      }

      return false
    },

    showPlanStatus() {
      return this.plan?.id && this.grouping?.id
    },
  },

  methods: {
    /**
     * Pass up the foodchain
     */
    handleClose() {
      this.$emit('close', {
        showProgramCompleteSlider: this.showProgramCompleteSlider,
      })
    },

    /**
     * pass up food chain
     * event data { grouping, stats, plan }
     */
    programCompleteUpdated(programComplete: ProgramComplete) {
      // if program was just completed from schedulable slider,
      // let parent component know so we auto-show program complete slider on close
      if (programComplete?.grouping?.id) {
        this.showProgramCompleteSlider = !!programComplete.grouping.isComplete
      }
      this.$emit('program-complete-updated', programComplete)
    },

    /**
     * pass up food chain
     */
    dayCompleteUpdated(dayComplete: DayComplete) {
      this.$emit('day-complete-updated', dayComplete)
    },

    setMenuAction(selectedAction: string) {
      this.menuActionSelected = selectedAction
    },

    clearMenuAction() {
      this.menuActionSelected = ''
    },

    setNote(note: string) {
      this.note = note
      this.clearMenuAction()
    },
  },
})
</script>

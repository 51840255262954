<template>
  <complete-slider-hero
    :complete-text="completeText"
    entity-type="plan"
    :entity-category="plan.brand.name.toLowerCase()"
    :show-confetti="confettiEnabled"
    :reward-earned="rewardEarned"
    @close-end="handleClose"
  >
    <div class="complete-extras">
      <program-status :plan="plan" :grouping="grouping" :complete-button="false" />
      <program-stats :plan="plan" :stats="stats" />
    </div>
  </complete-slider-hero>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import CompleteSliderHero from '@/components/modals/sliders/complete-slider-hero.vue'
import ProgramStatus from './program-status.vue'
import ProgramStats from './program-stats.vue'
import type {
  ProgramCompleteGrouping,
  ProgramCompletePlan,
  ProgramCompleteStats,
} from '@/types/calendar-types'

/**
 * Slide up modal for program complete.
 *
 * TODO: complete phase 2 trackers / feedback
 *
 * $emits the following actions
 * - close
 */
export default defineComponent({
  name: 'ProgramCompleteSlider',
  components: { CompleteSliderHero, ProgramStatus, ProgramStats },
  props: {
    groupingId: {
      type: Number,
      required: true,
    },

    // should mirror *-complete-button.vue
    completeText: {
      type: String,
      default: '',
    },

    plan: {
      type: Object as PropType<ProgramCompletePlan>,
      required: true,
    },

    grouping: {
      type: Object as PropType<ProgramCompleteGrouping>,
      required: true,
    },

    stats: {
      type: Object as PropType<ProgramCompleteStats>,
      required: true,
    },

    // TODO: complete not in use until phase 2
    // other trackers in the future
    trackerDefaults: {
      type: Object,
      default: () => ({}),
    },

    rewardEarned: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {}
  },
  computed: {
    ...mapState(useUserAuthStore, ['features', 'confettiEnabled', 'cdn']),
  },
  methods: {
    /**
     * Pass up the foodchain
     */
    handleClose() {
      this.$emit('close')
    },
  },
})
</script>

export const pad = (num: any, size = 2) => {
  let s = num + ''
  while (s.length < size) {
    s = '0' + s
  }
  return s
}

// add [] to arrays to work with PHP for router
export const addArraySyntax = (params: Record<string, any>): Record<string, any> => {
  // make a copy of our object that we can mutate
  const paramsWithArray = JSON.parse(JSON.stringify(params))
  Object.entries(paramsWithArray).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      paramsWithArray[key + '[]'] = val
      delete paramsWithArray[key]
    }
  })

  return paramsWithArray
}

// remove [] from keys in params {} like this.$route.query
export const removeArraySyntax = (params: Record<string, any>): Record<string, any> => {
  // make a copy of our object that we can mutate
  const paramsRemoveArray = JSON.parse(JSON.stringify(params))

  Object.keys(params)
    .filter((key) => key.endsWith('[]'))
    .forEach((key) => {
      const newKey = key.replace('[]', '')

      // this.$route.query is [] if multiple values but not if just 1
      paramsRemoveArray[newKey] = Array.isArray(params[key]) ? params[key] : [params[key]]
      delete paramsRemoveArray[key]
    })

  return paramsRemoveArray
}

/**
 * Used when comparing strings that are actually floats.
 * @see https://stackoverflow.com/questions/21690070/javascript-float-comparison/55164784#55164784
 */
export const compareFloats = (amount1: string, operator: string, amount2: string): boolean => {
  const realAmount1 = Math.round(Number.parseFloat(amount1) * 100000)
  const realAmount2 = Math.round(Number.parseFloat(amount2) * 100000)

  if (operator === '=') {
    return realAmount1 === realAmount2
  }

  if (operator === '>') {
    return realAmount1 > realAmount2
  }

  if (operator === '>=') {
    return realAmount1 >= realAmount2
  }

  if (operator === '<') {
    return realAmount1 < realAmount2
  }

  if (operator === '<=') {
    return realAmount1 <= realAmount2
  }

  return false
}

/**
 * TODO: test
 * This helper is really for Math.floor usage for things like
 * Math.floor(20.97 / 6.99) which should be 3, but in JS is 2.9999996
 *
 * So convert to ints and then do division
 * @param amount1
 * @param amount2
 * @returns {number}
 */
export const divideFloatsAsInts = (amount1: string, amount2: string): number => {
  const realAmount1 = Math.round(Number.parseFloat(amount1) * 100000)
  const realAmount2 = Math.round(Number.parseFloat(amount2) * 100000)

  return realAmount1 / realAmount2
}

export const moneyFormat = (val: string): string => {
  return Number.parseFloat(val).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export const numberFormat = (val: string): string => {
  return Number.parseFloat(val).toLocaleString('en-US')
}

<template>
  <div
    class="form__group"
    :class="[
      {
        error: !!errorMessage,
        '-has-label': showLabel && label,
      },
      containerClasses,
    ]"
  >
    <label v-if="showLabel" :for="inputId">
      {{ label }}
      <slot name="label" />
    </label>
    <input
      :id="inputId"
      :type="type"
      :name="name || inputId"
      :placeholder="placeholder"
      :value="value"
      :aria-label="!showLabel && label ? label : undefined"
      v-bind="$attrs"
      v-on="validationListeners"
    />
    <input-error v-if="errorMessage && showErrorMsg" :error-message="errorMessage" />
    <email-check
      v-if="type === 'email' && modelValue"
      :email="`${modelValue}`"
      @update-email="updateEmail"
    />
    <slot name="note" />
  </div>
</template>

<script setup lang="ts">
/**
 * Single text, email, password, or number input.  Any $attrs will pass to input.
 *
 * Regression testing after vue3 upgrade
 * [x] - vee validate setup
 * [x] - simple input (text / email / password / number)
 * [x] - data-hj-suppress
 * [x] - server side initial error messaging (via prop initial-errors)
 * [x] - global rule init on app boot
 * [x] - select input
 * [x] - file input
 * [x] - single checkbox item
 * [x] - how to handle v-model numbers
 * [x] - update custom validators
 * [x] - lazy load common password rule
 * [x] - lazy load mailcheck suggestions
 * [x] - textarea input
 * [x] - slotted / multiselect
 * [x] - checkbox list
 * [x] - visual input list
 */
import { nextTick, computed } from 'vue'
import { useField } from 'vee-validate'
import InputError from '@/components/forms/input-error.vue'
import EmailCheck from '@/components/forms/email-check.vue'

/** OPTIONS **/
defineOptions({
  // allow $attrs to flow through to the input element
  inheritAttrs: false,
})

/** PROPS **/
const props = withDefaults(
  defineProps<{
    containerClasses?: string
    inputId: string
    name?: string
    type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'search'
    modelValue: string | number | boolean | undefined
    // allow for v-model.number
    modelModifiers?: Record<string, (value: any) => any> | undefined
    placeholder?: string
    label?: string
    showErrorMsg?: boolean
    showLabel?: boolean
    rules?: string
    // validation label
    validateAs?: string
    // disable @input validation, needed when heavy / async validators
    validateAggressive?: boolean
  }>(),
  {
    containerClasses: '',
    name: '',
    type: 'text',
    modelModifiers: undefined,
    placeholder: '',
    label: '',
    validateAs: '',
    showLabel: true,
    showErrorMsg: true,
    rules: '',
    validateAggressive: true,
  },
)

/** EMITS **/
// defined, but let vee-validate handle it with syncVModel
const emit = defineEmits(['update:modelValue', 'change', 'blur', 'input'])

/** COMPUTED **/
// couldn't pass props directly in, needed to be reactive
const reactiveRules = computed(() => props.rules)

/** STATE **/
const { value, errorMessage, handleBlur, handleChange } = useField(
  () => props.inputId,
  reactiveRules,
  {
    // allow vee-validate to sync with v-model
    syncVModel: true,
    // if do not allow syncVModel, must set initialValue
    // initialValue: props.modelValue,
    label: props.validateAs || props.label || 'Field',
    validateOnValueUpdate: false,
    validateOnMount: false,
  },
)

/** METHODS **/
const validationListeners = {
  // validate on blur
  blur: (e: Event) => {
    handleBlur(e, true)

    // call parent change event after v-model updates
    nextTick(() => emit('blur', e))
  },
  // update value on change
  change: (e: Event) => {
    handleChange(e, true)

    // change event to parent, but after validation
    nextTick(() => emit('change', e))
  },
  // enable / disable validation on input
  input: (e: Event) => {
    // if error, aggressively validate as user types
    // but avoid aggressive checks for type email, as it has some heavier backend checks
    if (props.type !== 'email' && props.validateAggressive) {
      handleChange(e, !!errorMessage.value)
    }

    // change event to parent, but after validation
    nextTick(() => emit('input', e))
  },
}

/**
 * Only for email inputs to handle mail suggestion fixes.
 */
function updateEmail(email: string) {
  // trigger validation by updating value
  // this will also update v-model
  handleChange(email, true)

  // call parent change event after v-model updates
  nextTick(() => emit('change', email))
}
</script>

// polyfills up top
import './polyfills'

// import sass for webpack build process
import '../scss/styles.scss'

import 'what-input'

// eslint-disable-next-line
import Modernizr from '@/.modernizrrc'

// TODO: chrome has lazyloading build in @see https://scotch.io/bar-talk/native-lazy-loading-launched-on-chrome-76
import 'lazysizes'

// SPA
import { createApp, nextTick } from 'vue'
import { pinia } from '@/stores'
import { createSpaRoutes } from './router'
import { loadAppComponents } from './common'
import { initVeeValidate } from '@/utils/forms'
import errorTrackerInit from '@/utils/error-tracker'
import RouterView from './components/router/router-view.vue'
import HeaderModals from '@/components/modals/header-modals.vue'

// non-vue functionality
import { Header } from '@/utils/header-init'
import { fbAnnouncement } from '@/utils/announcement'
import { scrollToInit } from '@/utils/scroll-to'
import { trackLinkInit, trackEvent } from '@/utils/analytics'
import { userStoresInit } from '@/utils/user-stores'
import { resizePointerType } from '@/utils/device'
import { singleClickSubmit } from '@/utils/blade-submit'
import { safariRestoreScrollPosition, safariCaptureScrollPosition } from '@/utils/head'

// global form validation rules
initVeeValidate()

const primarySpaApp = createApp(RouterView)
const router = createSpaRoutes()
primarySpaApp.use(router)
primarySpaApp.use(pinia)

// app.components
loadAppComponents(primarySpaApp)
errorTrackerInit(primarySpaApp)

// init modals app
const modalsApp = createApp(HeaderModals)
modalsApp.use(router)
modalsApp.use(pinia)

// window attachments to avoid imports
window.createApp = createApp
window.pinia = pinia
window.fbTrackEvent = trackEvent // kinda hacky, want to remove, used on subscription-thanks.blade.php

// non SPA routes (new - 2019/08)
// use id="vue" or class="vue" in the blade template and this will pick up on any components
// registered above and ideally avoid "vue not loaded"
const vueEl = document.querySelector('#vue')

if (vueEl) {
  // load and mount id app
  const vueIdApp = createApp({
    name: vueEl instanceof HTMLElement && vueEl.dataset.app ? vueEl.dataset.app : undefined,
  })
  vueIdApp.use(router)
  vueIdApp.use(pinia)
  loadAppComponents(vueIdApp)
  vueIdApp.mount(vueEl)
}

// load and mount className app
const vueClasses = document.querySelectorAll('.vue')
if (vueClasses.length) {
  for (let i = 0; i < vueClasses.length; ++i) {
    const el = vueClasses[i]

    const vueClassApp = createApp({
      name: el instanceof HTMLElement && el.dataset.app ? el.dataset.app : undefined,
    })
    vueClassApp.use(router)
    vueClassApp.use(pinia)
    loadAppComponents(vueClassApp)
    vueClassApp.mount(el)
  }
}
// end non-SPA routes

/**
 * Safari specific "back/forward cache" (bfcache) restoring of scroll positions.
 * See methods for more details
 */
window.addEventListener('pagehide', safariCaptureScrollPosition, {
  passive: true,
})

window.addEventListener('pageshow', safariRestoreScrollPosition, {
  passive: true,
})

/**
 * Initialize non-vue items
 */
document.addEventListener('DOMContentLoaded', () => {
  // primary SPA
  if (document.querySelector('#app')) {
    primarySpaApp.mount('#app')
  }

  // modals app
  const header = document.querySelector('#header')

  if (header && header instanceof HTMLElement && header.parentNode) {
    const modalEl = document.createElement('div')
    header.parentNode.insertBefore(modalEl, header)
    modalsApp.mount(modalEl)
  }

  // announcement bar, not vue
  if (document.querySelector('#announcement')) {
    fbAnnouncement()
  }

  // header
  new Header().init()

  // anchor scrolling
  scrollToInit()

  // js-event-link analytics tracking
  trackLinkInit()

  // disable double submits from blade forms (just contact form currently)
  singleClickSubmit()

  // change device on resize, must be invoked after pinia
  resizePointerType()

  // lots of stuff going on during app boot, so allow XHR to
  // init state happen after DOM renders
  nextTick(() => {
    userStoresInit()
  })
})

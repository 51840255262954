<template>
  <div class="complete-sliders">
    <!-- users can disable schedulable complete slider -->
    <schedulable-complete-slider
      v-if="completeEffectsEnabled && isSchedulableSliderOpen"
      :scheduled-id="slider.scheduledId!"
      :entity-id="slider.entityId!"
      :entity-type="slider.entityType"
      :entity-category="slider.entityCategory"
      :complete-text="slider.completeText"
      :tracker-defaults="slider.trackerDefaults"
      :plan="slider.plan"
      :grouping="slider.grouping"
      :day-complete="dayComplete"
      @close="handleSchedulableClose"
      @stats-updated="handleStatsUpdated"
      @program-complete-updated="handleProgramCompleteUpdated"
      @day-complete-updated="handleDayCompleteUpdated"
    />

    <!-- program complete slider always shows, regardless of user.completeEffects -->
    <program-complete-slider
      v-if="isProgramSliderOpen"
      :grouping-id="slider.groupingId!"
      :complete-text="slider.completeText"
      :plan="slider.plan!"
      :grouping="slider.grouping!"
      :stats="slider.stats!"
      :reward-earned="slider.rewardEarned"
      @close="handleProgramClose"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { useCalendarStore } from '@/stores/calendar'
import SchedulableCompleteSlider from '@/components/modals/sliders/schedulable-complete-slider.vue'
import ProgramCompleteSlider from '@/components/modals/sliders/program-complete-slider.vue'
import { useModalsStore } from '@/stores/modals'
import type { DayComplete, ProgramComplete, ProgramCompleteStats } from '@/types/calendar-types'

/**
 * Manages the complete sliders and updates pinia.
 */
export default defineComponent({
  name: 'CompleteSliders',
  components: { SchedulableCompleteSlider, ProgramCompleteSlider },

  data() {
    return {}
  },

  computed: {
    ...mapState(useCalendarStore, [
      'slider',
      'dayComplete',
      'isSchedulableSliderOpen',
      'isProgramSliderOpen',
    ]),
    ...mapState(useUserAuthStore, ['completeEffectsEnabled', 'features']),
  },

  methods: {
    ...mapActions(useCalendarStore, ['updateSlider', 'updateDayComplete']),
    ...mapActions(useModalsStore, ['openSimpleModal']),

    handleSchedulableClose({ showProgramCompleteSlider }: { showProgramCompleteSlider: boolean }) {
      // open program complete slider
      if (showProgramCompleteSlider && this.slider.plan && this.slider.grouping) {
        this.updateSlider({
          isOpen: true,
          type: 'program',
          completeText: `${this.slider.plan.brand.name} Complete`,
          scheduledId: null,
          groupingId: this.slider.grouping.id,
        })
        return
      }

      // close current slider
      this.updateSlider({ isOpen: false })
    },

    handleProgramClose() {
      this.updateSlider({ isOpen: false })

      // SAVE A COPY MODAL
      // if complete sliders are disabled allow user to save a copy of their
      // program if it has been modified
      if (
        this.features.PLAN_COPY &&
        this.slider.plan &&
        this.slider.grouping &&
        this.slider.grouping.hasPlanDayScheduleMods
      ) {
        this.$nextTick(() => {
          this.openSimpleModal({
            type: 'PLAN_COPY',
            id: `copy-plan-${this.slider.grouping!.id}`,
            props: {
              copyType: 'grouping',
              planShortName: this.slider.plan!.shortName,
              planId: this.slider.plan!.id,
              groupingId: this.slider.grouping!.id,
            },
          })
        })
      }
      // end save a copy modal
    },

    handleStatsUpdated(stats: ProgramCompleteStats) {
      this.updateSlider({ stats })
    },

    /**
     * event data { grouping, stats, plan }
     */
    handleProgramCompleteUpdated(programComplete: ProgramComplete) {
      this.updateSlider(programComplete)
    },

    /**
     * day complete data
     */
    handleDayCompleteUpdated(dayComplete: DayComplete) {
      this.updateDayComplete(dayComplete)
    },
  },
})
</script>

<template>
  <modal
    :show-header="false"
    :show-footer="false"
    modal-class="-purchase"
    body-class=""
    @close="handleCloseClick"
  >
    <div class="purchase-body">
      <!-- closer -->
      <button class="close-modal-edge -dark" aria-label="Close" @click="handleCloseClick">
        <span class="material-symbols-outlined" aria-hidden="true">close</span>
      </button>

      <!-- image -->
      <div class="purchase-image">
        <card-src-set-image
          type="browser-select-max-800"
          :img-prefix="imageUrlPrefix"
          :image="image"
          :title="plusPass.title"
          lazy-loading="eager"
        />
      </div>

      <div class="purchase-options -two">
        <div class="purchase-option">
          <div class="purchase-info">
            <h2 class="heading -secondary">
              <span class="fbplus">
                <span class="fb">FB</span> <span class="plus">Plus</span>
              </span>
            </h2>
            <ul class="purchase-features">
              <li>
                Get access to all exclusive content and features starting as low as ${{
                  cheapestMonthlyPrice
                }}
                per month.
              </li>
            </ul>
          </div>

          <div class="purchase-action">
            <div class="purchase-btn">
              <a
                class="btn -material -txt-only -no-hover -dark -modal-btn"
                :href="joinLink"
                data-action="Join FB Plus"
                :data-category="eventCategory"
                :data-label="eventLabel"
                @click="trackClick"
              >
                <span class="btn-txt">Join FB Plus</span>
              </a>
            </div>
            <div class="purchase-price">
              <span class="normal-price">
                <a
                  class="link"
                  href="/fb-plus"
                  data-action="Learn More About FB Plus"
                  :data-category="eventCategory"
                  :data-label="eventLabel"
                  @click="trackClick"
                >
                  Learn More
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="purchase-option">
          <div class="purchase-info">
            <h2 class="heading -secondary">
              <span class="fbplus">
                <span class="fb">FB</span> <span class="plus">Plus</span>
                <span class="aa">Pass</span>
              </span>
            </h2>

            <ul class="purchase-features">
              <li>
                {{ plusPass.access_days }} day of access to FB Plus for the selected
                {{ entityTypeFormatted }}
              </li>
              <li>Includes access to all FB Plus programs, content and features</li>
            </ul>
          </div>

          <div class="purchase-action">
            <div class="purchase-btn">
              <add-to-cart-button
                button-classes="-txt-only -no-hover -p-01 -modal-btn"
                button-label="Buy Pass"
                item-type="product"
                :item-id="plusPass.id"
                :meta-item-from-type="entityType"
                :meta-item-from-id="entityId"
                :track-event="true"
                :event-category="eventCategory"
                event-action="Add Pass to Cart"
                :event-label="eventLabel"
              />
            </div>
            <div class="purchase-price">
              <span v-if="plusPass.is_sale" class="sale-price"> ${{ plusPass.sale_price }} </span>
              <span
                class="normal-price"
                :class="{
                  '-strike': plusPass.is_sale,
                }"
              >
                ${{ plusPass.price }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import Modal from './modal.vue'
import CardSrcSetImage from '@/components/images/card-src-set-image.vue'
import AddToCartButton from '@/components/cart/add-to-cart-button.vue'
import { trackEvent, trackLink } from '@/utils/analytics'

/**
 * Custom modal to show plus pass purchase option only.
 */
export default {
  name: 'ModalPlusPassPurchase',
  components: { CardSrcSetImage, Modal, AddToCartButton },
  props: {
    imageUrlPrefix: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    plusPass: {
      type: Object,
      default: null,
    },
    cheapestMonthlyPrice: {
      type: String,
      default: null,
    },
    entityType: {
      type: String,
      default: 'workout',
      validator: (val) => ['workout', 'article', 'wellness_video'].includes(val),
    },
    entityCategory: {
      ype: String,
      default: '',
      validator: (val) => ['recipe', ''].includes(val),
    },
    entityId: {
      required: true,
      type: Number,
    },
  },
  emits: ['close'],

  // component state
  data() {
    return {}
  },

  computed: {
    ...mapState(useUserAuthStore, ['user']),

    entityTypeFormatted() {
      return this.entityType.replace('_', ' ')
    },

    // for ga event tracking
    eventLabel() {
      if (this.entityCategory === 'recipe') {
        return this.entityCategory
      }

      return this.entityType
    },

    eventCategory() {
      return 'Plus Pass Modal'
    },

    joinLink() {
      if (this.user.isLoggedIn) {
        return '/subscription/checkout'
      }

      return '/join?from=%2Fsubscription%2Fcheckout'
    },
  },

  methods: {
    handleCloseClick() {
      trackEvent({
        category: this.eventCategory,
        action: 'Close',
        label: this.eventLabel,
      })

      this.$emit('close')
    },

    trackClick(e) {
      return trackLink(e)
    },
  },
}
</script>

/**
 * Used for shared apexchart configuration to help keep charts consistent
 */
export const DARK_WHITE = '#f0f4f6'
export const TEAL = '#91efda'
export const DARK_TEAL = '#3cdbb8'

export const GRADIENT_TEAL = '#00F095'
export const BRAND_BLUE_100 = '#4296cb'
export const LIGHT_BLUE = '#7ddaff'
export const ORANGE = '#ffa23b'
export const DARK_ORANGE = '#ff6c19'
export const RED = '#ff505f'
export const ROSE = '#b80646'
export const PURPLE = '#9c6bff'

export const GRADIENT_PURPLE = '#6100FF'
export const DARK_PURPLE = '#6313FF'
export const LAVENDER = '#6714ef'
export const GREEN = '#26bc35'
export const GRAY_50 = '#a7aabb'

export const chartOptions = {
  fontFamily: '"Maison Neue", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  toolbar: {
    show: false,
  },
}

export const tooltipOptions = {
  enabled: true,
  theme: 'dark',
  fillSeriesColor: false,
  style: {
    fontSize: '14px',
  },
}

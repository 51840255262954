<template>
  <modal
    :heading="modalHeading"
    modal-class=""
    body-class="modal__body -tight"
    :show-footer="false"
    @close="$emit('close')"
  >
    <vee-form v-slot="{ validate }" as="">
      <form
        v-if="step === 1"
        ref="activeForm"
        class="modal__form"
        name="statWidgetStep1"
        tabindex="-1"
        @submit.prevent="handleSubmitStep1(validate)"
      >
        <form-fieldset-slot input-name="widgetType" :tight="true">
          <radio-input
            v-for="type in Object.keys(types)"
            :key="type"
            v-model="formData.type"
            :input-id="`type_${type}`"
            name="widgetType"
            :checked-value="type"
            :label="types[type].title"
            rules="required"
            validate-as="Data type"
            :show-error-msg="false"
            :disabled="types[type].disabled"
          />
        </form-fieldset-slot>

        <div class="action__group form-actions -flex -center">
          <div class="form-action">
            <button class="btn -dark" @click.prevent="handleSubmitStep1(validate)">Next</button>
          </div>
        </div>
      </form>
    </vee-form>
    <vee-form v-slot="{ validate }" as="">
      <form
        v-if="step === 2"
        ref="activeForm"
        class="modal__form"
        name="statWidgetStep2"
        tabindex="-1"
        @submit.prevent="handleSubmitStep2(validate)"
      >
        <form-fieldset-slot input-name="statWidget" :tight="true">
          <radio-input
            v-for="statWidget in statWidgetsByType"
            :key="statWidget.id"
            v-model="formData.statWidget"
            :input-id="`statWidget_${statWidget.id}`"
            name="statWidget"
            :checked-value="statWidget.id"
            :label="`${statWidget.title} ${statWidget.subtitle}`"
            rules="required"
            validate-as="Activity chart"
            :show-error-msg="false"
            :disabled="statWidget.disabled"
          />
        </form-fieldset-slot>

        <div class="action__group form-actions -flex -center">
          <div class="form-action">
            <button class="btn -neutral" @click.prevent="returnToStep(1)">Previous</button>
          </div>
          <div class="form-action">
            <button class="btn -dark" @click.prevent="handleSubmitStep2(validate)">Add</button>
          </div>
        </div>
      </form>
    </vee-form>
  </modal>
</template>

<script>
import { defineComponent } from 'vue'
import { Form as VeeForm } from 'vee-validate'
import Modal from './modal.vue'
import FormFieldsetSlot from '@/components/forms/form-fieldset-slot.vue'
import RadioInput from '@/components/forms/radio-input.vue'

export default defineComponent({
  name: 'ModalStatWidgetAdd',
  components: { VeeForm, Modal, FormFieldsetSlot, RadioInput },
  props: {
    statWidgets: {
      type: Array,
      required: true,
    },
  },
  emits: ['add', 'close'],
  data() {
    return {
      step: 1,
      formData: {
        type: '',
        statWidget: '',
      },
    }
  },
  computed: {
    modalHeading() {
      if (this.step === 2 && this.formData.type) {
        return `Select ${this.types[this.formData.type].title}`
      }

      // step 1 default
      return 'Select Data to Add'
    },
    types() {
      const types = this.statWidgets.reduce((acc, statWidget) => {
        if (acc[statWidget.type]) {
          return acc
        }

        const titleCase = `${statWidget.type.charAt(0).toUpperCase()}${statWidget.type.slice(
          1,
        )}`.replace(/_/g, ' ')

        acc[statWidget.type] = {
          title: titleCase,
          disabled: false,
        }

        return acc
      }, {})

      // disable types if all widgets for the type are also disabled
      Object.keys(types).forEach((type) => {
        types[type].disabled = this.statWidgets
          .filter((sw) => sw.type === type)
          .every((sw) => sw.disabled)
      })

      return types
    },

    statWidgetsByType() {
      return this.statWidgets.filter((sw) => sw.type === this.formData.type)
    },
  },
  mounted() {
    // focus step 1
    this.$nextTick(() => {
      if (this.$refs.activeForm) {
        this.$refs.activeForm.focus()
      }
    })
  },
  methods: {
    returnToStep(step) {
      this.step = step

      // reset form
      this.formData.type = ''
      this.formData.statWidget = ''
    },

    async handleSubmitStep1(validate) {
      try {
        const results = await validate()

        if (results.valid) {
          this.step = 2

          this.$nextTick(() => {
            if (this.$refs.activeForm) {
              this.$refs.activeForm.focus()
            }
          })
        }
      } catch (e) {
        console.error(e)
      }
    },

    async handleSubmitStep2(validate) {
      try {
        const results = await validate()

        if (results.valid) {
          this.$emit('add', this.formData.statWidget)
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
})
</script>

import { defineStore } from 'pinia'
import type { CommentState } from '@/types/comment-types'

export const useCommentsStore = defineStore('comments', {
  state: (): CommentState => ({
    commentCount: window.Laravel?.comments?.commentCount ? window.Laravel.comments.commentCount : 0,
    commentId: window.Laravel?.comments?.commentId ? window.Laravel.comments.commentId : 0,
  }),

  getters: {
    totalCommentsText: (state: CommentState) => {
      const count = state.commentCount
      return `${count} ${count === 0 || count > 1 ? 'Comments' : 'Comment'}`
    },
  },

  actions: {
    setCommentTotal(n: number = 0) {
      this.commentCount = n
    },

    increaseCommentsTotal(n: number = 1) {
      this.commentCount += n
    },

    decreaseCommentsTotal(n: number = 1) {
      this.commentCount -= n
    },
  },
})

import { defineStore } from 'pinia'
import type { CompletesState, Completes, UserSchedulableComplete } from '@/types/complete-types'

export const useCompletesStore = defineStore('completes', {
  state: (): CompletesState => ({
    completes: {},
  }),

  getters: {
    userCompletes: (state: CompletesState) => (schedulable: Partial<UserSchedulableComplete>) => {
      const completeKey = `${schedulable.schedulableType}_${schedulable.schedulableId}`
      // if complete count is somehow less than 0, set to 0
      const completeCount = state.completes[completeKey] < 0 ? 0 : state.completes[completeKey]
      return completeCount || 0
    },
  },

  actions: {
    addComplete(schedulable: UserSchedulableComplete) {
      const completeKey = `${schedulable.schedulableType}_${schedulable.schedulableId}`

      // mutate if exists
      if (this.completes[completeKey]) {
        this.completes[completeKey] += schedulable.completes
        return
      }
      // if new key, have to return new object to be reactive
      this.completes = {
        ...this.completes,
        [completeKey]: schedulable.completes,
      }
    },

    removeComplete(schedulable: UserSchedulableComplete) {
      const completeKey = `${schedulable.schedulableType}_${schedulable.schedulableId}`
      // mutate if exists
      if (this.completes[completeKey]) {
        this.completes[completeKey] -= schedulable.completes
      }
    },

    setCompletes(completes: Completes) {
      this.completes = completes
    },
  },
})

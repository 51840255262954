<template>
  <carousel
    :per-page="1"
    :navigation-enabled="false"
    pagination-color="#7B7F92"
    pagination-active-color="#CED0DB"
    :pagination-padding="7"
    :pagination-size="6"
  >
    <slide>
      <div class="container -medium">
        <h2 class="heading -huge -light">
          A little bit about who we are and how it all got started
        </h2>
        <p class="no-pad">
          Fitness Blender was created by just two people; a husband and wife team of personal
          trainers who thought fitness should be accessible to everyone, regardless of their income.
          Both Kelli and Daniel felt like there was a lack of reliable health and fitness
          information on the web, and too many people in the industry were more focused on monetary
          gain or appearance than they were on good health.
        </p>
      </div>
    </slide>
    <slide>
      <div class="container -medium">
        <h2 class="heading -huge -light">How we got started</h2>
        <p class="no-pad">
          Fitness Blender was started off a meager $5000 budget. Planning for Fitness Blender began
          in 2009 and the site officially launched in 2010. Daniel and Kelli worked countless long
          hours outside of their regular day jobs to painstakingly create one piece of content at a
          time. Both left their other jobs in the summer of 2012 to work on Fitness Blender full
          time, and carefully built a small team around themselves who share the same mission. With
          a lot of hard work, good intentions, and dedication, Fitness Blender has since become one
          of the most popular fitness websites in the world.
        </p>
      </div>
    </slide>
  </carousel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'

export default defineComponent({
  name: 'AboutCarousel',
  components: {
    Carousel,
    Slide,
  },
  props: {},
})
</script>

<template>
  <div class="guided-tours">
    <tour
      v-if="activeTourScript"
      :tour-id="activeTourId"
      :script="activeTourScript"
      @exit-tour="logAndExitTour"
      @exit-guided-tour="logAndExitGuidedTour"
    />

    <!-- never launch with debug mode on -->
    <div v-if="debugMode" class="group -flex">
      <div class="select-style -bordered -bigger filter-select">
        <select v-model="debugTourId">
          <option value="">Select One</option>
          <option
            v-for="availableTourId in availableTourIds"
            :key="availableTourId"
            :value="availableTourId"
          >
            {{ availableTourId }}
          </option>
        </select>
      </div>
      <button class="btn -link" @click="debugTour">Debug Tour</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Tour from './tour.vue'
import { mapState, mapActions } from 'pinia'
import { useToursStore } from '@/stores/tours'
/**
 * Guide users through new UI or complex capability.
 *
 * Pass in window.Laravel.tours.availableTourIds into pinia
 * on relevant pages.
 *
 * @see https://fitnessblender.atlassian.net/wiki/spaces/DEV/pages/1551106050/Creating+a+Guided+Tour
 */
export default defineComponent({
  name: 'GuidedTours',
  components: { Tour },
  props: {},

  // component state
  data() {
    return {
      // manually toggle to enable drop down
      debugMode: false,
      debugTourId: '',
    }
  },

  computed: {
    ...mapState(useToursStore, ['availableTourIds', 'activeTourId', 'activeTourScript']),
  },

  // lifecycle methods
  created() {
    // fetch tour scripts
    this.initAvailableTours(this.availableTourIds)
  },

  async mounted() {
    // load specific tours that do not have view components
    await this.$nextTick()

    // tour archived
    // if (window && window.location && window.location.pathname === '/my/wallet/passes') {
    //   this.loadTour({ tourId: 'walletPass2022', force: false })
    // }
  },

  // component methods
  methods: {
    ...mapActions(useToursStore, [
      'initAvailableTours',
      'logAndExitTour',
      'logAndExitGuidedTour',
      'loadTour',
    ]),

    debugTour() {
      this.loadTour({
        guidedTourId: '',
        tourId: this.debugTourId,
        force: true,
      })
    },
  },
})
</script>

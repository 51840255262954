import { defineStore } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import type { ModalsState, SimpleModalMutation } from '@/types/modal-types'

export const useModalsStore = defineStore('modals', {
  state: (): ModalsState => ({
    errorModal: {
      active: false,
      type: 'generic',
    },
    loginModal: {
      active: false,
    },
    simpleModal: {
      active: false,
      type: '',
      id: '',
      action: '',
      actionPayload: null,
      props: {},
    },
  }),

  getters: {
    // noop
  },

  actions: {
    /**
     * Error modal methods
     */
    showErrorModal(type = 'generic') {
      this.errorModal.active = true
      this.errorModal.type = type
    },

    hideErrorModal() {
      this.errorModal.active = false
      this.errorModal.type = 'generic'
    },

    /**
     * Login modal methods
     */
    showLoginModal() {
      this.loginModal.active = true

      // auto log out the user
      const userAuthStore = useUserAuthStore()

      if (userAuthStore.user.isLoggedIn) {
        userAuthStore.logoutUser()
      }
    },

    hideLoginModal() {
      this.loginModal.active = false
    },

    showWasLoggedInModal() {
      // Note: this modal really should say something about being
      // logged out and probably no need for creating new account
      // Currently just calls the same Login Modal
      this.showLoginModal()
    },

    /**
     * Generic simple modal methods
     */
    openSimpleModal({ type, id, props }: SimpleModalMutation) {
      this.simpleModal = {
        active: true,
        type,
        id: id || 'simple-modal',
        action: '',
        actionPayload: null,
        props,
      }
    },

    // reset and close
    closeSimpleModal() {
      this.simpleModal = {
        active: false,
        type: '',
        id: '',
        action: '',
        actionPayload: null,
        props: {},
      }
    },

    /**
     * Used for confirm modals and must have id property set to differentiate modals.
     * Component should handle the action watcher and any required code.
     */
    closeSimpleModalWithAction(action: string, payload: any = null) {
      this.simpleModal.active = false
      this.simpleModal.action = action
      this.simpleModal.actionPayload = payload
    },

    /**
     * Only set the simple modal action, keep modal active state as is.
     * Use to keep the modal open.
     */
    setSimpleModalAction(action: string, payload: any = null) {
      this.simpleModal.action = action
      this.simpleModal.actionPayload = payload
    },
  },
})

<template>
  <div v-if="isPurchasable" class="card-cta">
    <div class="content-actions">
      <div class="content-actions-msg">{{ purchasableMessage }}</div>
      <div class="">
        <span v-if="lowestPrice.is_sale" class="price sale">
          <span class="strike">${{ lowestPrice.price }}</span>
          ${{ lowestPrice.sale_price }}
        </span>
        <span v-else class="price">${{ lowestPrice.price }}</span>
      </div>
    </div>
    <div>
      <icon-button
        type="button"
        icon="shopping_bag"
        classes="-ico-txt -no-hover -p-01"
        :icons-outlined="true"
        :reduce="false"
        label="Add To Bag"
        @click-action="openPurchaseModal"
      />
    </div>
  </div>
  <div v-else class="card-cta">
    <div class="content-actions">
      <div class="content-actions-msg">
        {{ notPurchasableMessage }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useUserAuthStore } from '@/stores/user-auth'
import IconButton from '@/components/buttons/icon-button.vue'
import * as planUtils from '@/utils/plans'
import { useModalsStore } from '@/stores/modals'
import { computed } from 'vue'

/**
 * Handles plan card messaging which could be one of the following use cases
 *
 * MY PROGRAMS PAGE
 * 1) Show schedule CTAs (my programs page), no message
 *
 * SALES PAGE
 * 1) Show schedule CTAs, no message
 * 2) Included for Free (Anonymous user + free program)
 * 3) Exclusive access only message (cannot purchase)
 * 4) Can be purchased (to own) or accessible via fb plus pass
 *
 * EMITS
 * purchase-action
 */

/** PROPS **/
const props = defineProps({
  plan: {
    type: Object,
    required: true,
  },
  plusPass: {
    type: Object,
    default: null,
  },
  imageUrlPrefix: {
    type: String,
    default: '',
  },
})

/** STATE **/
const modalsStore = useModalsStore()
const userAuthStore = useUserAuthStore()

/** COMPUTED **/
const isPurchased = computed(() => userAuthStore.isPlanPurchased(props.plan.id))

const isPurchasable = computed(() => {
  return planUtils.isPurchasable({
    plan: props.plan,
    plusPass: props.plusPass,
    isPurchased: isPurchased.value,
    isSubscriber: !!userAuthStore.features.EXCLUSIVE_PLANS,
  })
})

// lowestPrice assumes plan is isPurchasable
const lowestPrice = computed(() => {
  if (!isPurchasable.value) {
    return {}
  }

  return planUtils.lowestPrice({
    plan: props.plan,
    plusPass: props.plusPass,
  })
})

const purchasableMessage = computed(() => {
  return planUtils.purchasableMessage({
    plan: props.plan,
    plusPass: props.plusPass,
    isSubscriber: !!userAuthStore.features.EXCLUSIVE_PLANS,
  })
})

const notPurchasableMessage = computed(() => {
  return planUtils.notPurchasableMessage({
    plan: props.plan,
  })
})

/** METHODS **/
function openPurchaseModal() {
  modalsStore.openSimpleModal({
    type: 'PLAN_PURCHASE',
    id: `purchase-plan-${props.plan.id}-modal`,
    props: {
      plan: props.plan,
      plusPass: props.plusPass,
      imageUrlPrefix: props.imageUrlPrefix,
    },
  })
}
</script>

<template>
  <div>
    <!-- error state -->
    <callout v-if="error && !forSchedulableCompleteSlider" type="error">
      <div>{{ error }}</div>
    </callout>

    <!-- loading -->
    <spinner-loader v-if="loading" />

    <!-- add tag form -->
    <div
      v-if="isAddOpen && !loading"
      :class="{
        filter: parentComponent === '',
        form__group: forModal || forSchedulableCompleteSlider,
      }"
    >
      <multiselect
        v-model="selectedTags"
        :loading="multiSelectLoading"
        :options="tags"
        :close-on-select="false"
        :multiple="true"
        :taggable="true"
        :open-direction="forSchedulableCompleteSlider ? 'bottom' : ''"
        tag-placeholder="Add this as new tag"
        select-label="Select"
        deselect-label="Remove"
        placeholder="Search or add a tag"
        @open="tagDropDownOpen"
        @select="saveTag"
        @tag="addTag"
        @remove="removeTag"
      >
        <!-- custom tags -->
        <template #tag="{ option, remove }">
          <tag-button
            :label="option"
            :aria-label="`Remove ${option}`"
            @click-action="remove(option)"
          />
        </template>
      </multiselect>
    </div>

    <!-- no results, show simple message -->
    <div v-if="noResults">
      <div class="all-user-tags">
        <div class="user-tags-list">You do not have any tags yet</div>
      </div>
    </div>

    <!-- results view -->
    <div v-if="resultsReady" class="all-user-tags">
      <div v-show="!isAddOpen && selectedTags && selectedTags.length" class="user-tags-list">
        <tag-button
          v-for="tag in selectedTags"
          :key="tag"
          :label="tag"
          icon=""
          :aria-label="`View videos tagged ${tag}`"
          @click-action="searchByTag(tag)"
        />
      </div>
      <div v-if="forSchedulableCompleteSlider" class="save-extras -flex -center">
        <submit-button
          label="Save"
          submitting-label="Saving…"
          :classes="forSchedulableCompleteSlider ? 'btn -dark' : '-main'"
          @submit-action="closeAddForm"
        />
      </div>
      <div v-else class="view-all">
        <icon-button
          :icon="isAddOpen ? 'save_alt' : 'label'"
          classes="-add -ico-txt"
          :label="isAddOpen ? 'Save Tags' : 'Manage Tags'"
          :reduce="!forModal"
          aria-live="polite"
          :aria-expanded="isAddOpen ? 'true' : 'false'"
          aria-controls="add-tag-form"
          @click-action="toggleAddForm"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import Multiselect from 'vue-multiselect'
import { fbApi, FbApiError, objToSearchParams } from '@/utils/http-api'
import Callout from '@/components/notifications/callout.vue'
import SpinnerLoader from '@/components/loaders/spinner-loader.vue'
import IconButton from '@/components/buttons/icon-button.vue'
import SubmitButton from '@/components/buttons/submit-button.vue'
import TagButton from '@/components/buttons/tag-button.vue'

/**
 * Self contained Tags component.
 * TODO: accessibility on tapping to open drop down
 *
 * This component toggles classes / sections / event emitters based on
 * parentComponent passed in.
 */
export default defineComponent({
  name: 'ManageTags',
  components: {
    Callout,
    SpinnerLoader,
    IconButton,
    SubmitButton,
    TagButton,
    Multiselect,
  },
  props: {
    entityId: {
      required: true,
      type: Number,
    },
    entityType: {
      default: 'workout',
      type: String,
      validator: (val: string) =>
        ['workout', 'custom_workout', 'article', 'wellness_video'].includes(val),
    },
    entityCategory: {
      default: '',
      type: String,
      validator: (val: string) => ['', 'recipe'].includes(val),
    },

    // Using the parentComponent prop and this method to manipulate various styling
    // and show/hide of some elements. Will need to possibly adjust the logic if
    // this component is added to other locations in the future.
    parentComponent: {
      type: String,
      default: '',

      validator: (val: string) => ['', 'schedulable-complete-slider', 'modal'].includes(val),
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showTutorialButton: {
      type: Boolean,
      default: false,
    },
    startAddOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['error-action', 'close-action'],

  // component state
  data() {
    return {
      // used for XHR for all tag options
      loading: false,
      error: '' as string | Error,
      tags: [] as string[],

      // use with multiselect and only selected tags
      multiSelectLoading: false,
      selectedTags: [] as string[],
      tagDetails: [] as any[],

      // add notes
      isAddOpen: !!this.startAddOpen,
    }
  },

  computed: {
    ...mapState(useUserAuthStore, ['features', 'refreshTagsInstances']),

    resultsReady() {
      return !this.error && !this.loading && Array.isArray(this.selectedTags)
    },

    noResults() {
      return (
        !this.error &&
        !this.loading &&
        Array.isArray(this.selectedTags) &&
        this.selectedTags.length === 0 &&
        !this.isAddOpen
      )
    },

    searchUrl() {
      if (this.entityType === 'workout') {
        return '/videos'
      } else if (this.entityType === 'custom_workout') {
        return '/my/custom-workouts'
      } else if (this.entityType === 'wellness_video') {
        return '/wellness-videos'
      } else if (this.entityType === 'article' && this.entityCategory === 'recipe') {
        return '/healthy-living/healthy-recipes'
      } else if (this.entityType === 'article') {
        return '/articles'
      }

      // missing
      return ''
    },

    // hook to customize when form is in workout complete slider
    forSchedulableCompleteSlider() {
      return this.parentComponent && this.parentComponent === 'schedulable-complete-slider'
    },

    // hook to customize when form is in modal
    forModal() {
      return this.parentComponent && this.parentComponent === 'modal'
    },
  },

  // watchers
  watch: {
    // when pinia store changes for refreshTagsInstances
    // and it is true, re-fetch the schedule and flip the flag back to false
    refreshTagsInstances(newVal: boolean, oldVal: boolean) {
      if (newVal !== oldVal && newVal === true && this.features.TAGS) {
        this.fetchTags({
          taggableType: this.entityType,
          taggableId: this.entityId,
        })
        this.setRefreshTagsInstances(false)
      }
    },
  },

  // lifecycle methods
  created() {
    // fetch all tags
    this.fetchTags({
      taggableType: this.entityType,
      taggableId: this.entityId,
    })
  },

  // component methods
  methods: {
    ...mapActions(useUserAuthStore, ['setRefreshTagsInstances']),

    // this is a hack if the dropdown is open within the parent complete slider
    // or modal
    //
    // 1. the dropdown will show inline (see css) instead of absolute
    // 2. always shows below the input (see component prop)
    // 3. override the style maxHeight that the component sets to force tags
    //    to load below the bottom edge of the screen.
    tagDropDownOpen() {
      if (this.forSchedulableCompleteSlider || this.forModal) {
        this.$nextTick(() => {
          const wrapper = document.querySelector('.multiselect__content-wrapper')

          if (wrapper && wrapper instanceof HTMLElement) {
            wrapper.style.maxHeight = '200px'
          }
        })
      }
    },

    // in workout slider mode, keep in add mode and just change
    closeAddForm() {
      // flag other Tag components to refresh after Tags are saved
      this.setRefreshTagsInstances(true)
      this.$emit('close-action')
    },

    async toggleAddForm() {
      if (this.isAddOpen && this.parentComponent !== '') {
        // flag other Tag components to refresh after Tags are saved
        this.setRefreshTagsInstances(true)
      }

      this.isAddOpen = !this.isAddOpen

      // if open, manually add maxlength to the search input on the next render
      if (this.isAddOpen) {
        await this.$nextTick()
        const selectInput = document.querySelector('.multiselect__input')

        if (selectInput) {
          selectInput.setAttribute('maxlength', '50')
        }
      }
    },

    // fetch all user tags and specific tags for this entityType and id
    async fetchTags(params: Object = {}) {
      this.loading = true
      this.error = ''

      try {
        // get all unique tags the users has created (regardless of type)
        const uniquePromise = fbApi.get(`/tags/unique`)

        // tags selected for this entity
        const detailPromise = fbApi.get(`/tags`, {
          searchParams: objToSearchParams(params),
        })

        // run requests in parallel
        const [resUnique, resDetail] = await Promise.all([uniquePromise, detailPromise])

        // make sure both are returned okay
        if (
          !resUnique ||
          !resUnique.body ||
          !resUnique.body.data ||
          !resDetail ||
          !resDetail.body ||
          !resDetail.body.data
        ) {
          throw Error('missing tag response data')
        }

        // set tags
        this.loading = false

        // these are distinct user tags
        this.tags = resUnique.body.data.tags

        // track full details (need id to delete)
        this.tagDetails = resDetail.body.data.tags

        // keep multiselect simple with only array of strings
        this.selectedTags = resDetail.body.data.tags.map((tag: any) => tag.name)
      } catch (err) {
        // console.error(err)
        this.loading = false
        this.error = `Unable to fetch tags, please try again`
        this.setAndEmitError(this.error)
      }
    },

    // @see https://vue-multiselect.js.org/#sub-tagging
    // proxy for adding a brand NEW tag (for entity)
    // add to v-model, this only is needed for addTag
    // if adding an existing tag, v-model update happens automatically
    async addTag(tagName: string) {
      // if over 50 chars, truncate...
      if (tagName.length > 50) {
        tagName = tagName.substring(0, 49)
      }

      await this.saveTag(tagName)
      this.selectedTags.push(tagName)
    },

    // save an
    async saveTag(tagName: string) {
      this.multiSelectLoading = true

      // not tracking loading state, just error
      this.error = ''
      this.setAndEmitError(this.error)

      try {
        const formData = {
          taggableType: this.entityType,
          taggableId: this.entityId,
          name: tagName,
        }

        const res = await fbApi.post(`/tags`, {
          json: formData,
        })

        // handled in catch
        if (!res || !res.body || !res.body.data) {
          throw Error('missing add tagresponse data')
        }

        // add to all tags if it doesn't already exist
        const exists = this.tags.some((existingTag) => existingTag === tagName)

        if (!exists) {
          this.tags.push(tagName)
        }
        // end add new tag

        // add full tag details to existing list (needed for delete)
        this.tagDetails.push(res.body.data)

        // stop loading
        this.multiSelectLoading = false

        return tagName
      } catch (err) {
        // console.error(err)
        this.multiSelectLoading = false
        this.error = `Unable to add tag, please try again`
        this.setAndEmitError(this.error)
      }
    },

    // delete tag
    async removeTag(tagName: string) {
      this.multiSelectLoading = true
      this.error = ''
      this.setAndEmitError(this.error)

      // instantly remove from selectedTags to feel responsive
      this.selectedTags = this.selectedTags.filter((selectedTag) => selectedTag !== tagName)

      try {
        // find tag in tagDetails to delete
        const tag = this.tagDetails.find((tagDetail) => tagDetail.name === tagName)

        const res = await fbApi.delete(`/tags/${tag.id}`)

        // handled in catch
        if (!res || !res.body || !res.body.data) {
          throw Error('missing add tagresponse data')
        }

        // remove from tag details if successful (selectedTags already removed)
        this.tagDetails = this.tagDetails.filter((tagDetail) => tagDetail.id !== tag.id)
      } catch (err) {
        // only show error message if not 404 on delete
        if (err && err instanceof FbApiError && err.status !== 404) {
          this.error = `Unable to remove tag, please try again`
          this.setAndEmitError(this.error)
        }
      } finally {
        this.multiSelectLoading = false
      }
    },

    // not currently in use
    async removeAll() {
      this.multiSelectLoading = true
      this.error = ''
      this.setAndEmitError(this.error)

      // instantly remove from selectedTags to feel responsive
      this.selectedTags = []

      try {
        const res = await fbApi.delete(`/tags/${this.entityType}/${this.entityId}`)

        // handled in catch
        if (!res || !res.body || !res.body.data) {
          throw Error('missing add tag response data')
        }

        // remove from tag details if successful (selectedTags already removed)
        this.tagDetails = []
        this.multiSelectLoading = false
      } catch (err) {
        // console.error(err)
        this.multiSelectLoading = false
        this.error = `Unable to remove all tags, please try again`
        this.setAndEmitError(this.error)
      }
    },

    // go to search by tag
    searchByTag(tag: string) {
      window.location.href = `${this.searchUrl}?tags[]=${encodeURIComponent(tag)}`
    },

    setAndEmitError(err: Error | string) {
      this.$emit('error-action', err)
    },
  },
})
</script>

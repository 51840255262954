import { defineAsyncComponent } from 'vue'
import type { App } from 'vue'

/**
 * Require and code split / lazy load search related components
 *
 * Also contains
 * - custom workout form components
 * - routine builder components
 * - plans scheduling
 * - product cards (gift card)
 *
 * This is because there is a lot of overlap and both routines / custom workouts / plans
 * that leverage "search" capabilities, so made sense to bundle here vs tons of smaller
 * code splitting.
 *
 * If they grow, we can split into separate chunks.
 */
const searchCodeSplit = (app: App) => {
  /* CARDS */
  app.component(
    'VideoCard',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "search" */ '../components/videos/video-card.vue'),
    ),
  )
  app.component(
    'ArticleCard',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "search" */ '../components/articles/article-card.vue'),
    ),
  )
  app.component(
    'WellnessVideoCard',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "search" */ '../components/wellness-videos/wellness-video-card.vue'
        ),
    ),
  )
  // plan-card in main app.js

  /* ROUTINES */
  // routines are apart of the search chunk as they use video / favorites pages
  app.component(
    'RoutineInfoForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "search" */ '../components/routines/routine-info-form.vue'),
    ),
  )
  app.component(
    'RoutineMetaData',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "search" */ '../components/routines/routine-meta-data.vue'),
    ),
  )
  app.component(
    'RoutineInstructionsCarousel',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "search" */ '../components/routines/routine-instructions-carousel.vue'
        ),
    ),
  )

  /* PLANS */
  app.component(
    'PlanScheduleGrid',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "search" */ '../components/plans/plan-schedule/plan-schedule-grid.vue'
        ),
    ),
  )
  app.component(
    'EnergyCard',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "search" */ '../components/users/settings/energy/energy-card.vue'
        ),
    ),
  )
  app.component(
    'ShoppingList',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "search" */ '../components/plans/shopping-list.vue'),
    ),
  )
  app.component(
    'MyProgramCarousel',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "search" */ '../components/carousels/my-program-carousel.vue'),
    ),
  )
  app.component(
    'MealPlansCarousel',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "search" */ '../components/carousels/meal-plans-carousel.vue'),
    ),
  )

  /* CUSTOM WORKOUTS */
  app.component(
    'CustomWorkoutForm',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "search" */ '../components/custom-workouts/custom-workout-form.vue'
        ),
    ),
  )

  /* ARTICLES */
  app.component(
    'RecipeMetaData',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "search" */ '../components/articles/recipe-meta-data.vue'),
    ),
  )
  app.component(
    'RecipeEnergyCard',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "search" */ '../components/articles/recipe-energy-card.vue'),
    ),
  )

  /* PRODUCTS */
  app.component(
    'ProductCard',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "search" */ '../components/products/product-card.vue'),
    ),
  )

  /* TEAM MEMBER */
  app.component(
    'TeamMemberCard',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "search" */ '../components/team-members/team-member-card.vue'),
    ),
  )
  app.component(
    'RecentArticleWidget',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "search" */ '../components/team-members/recent-article-widget.vue'
        ),
    ),
  )
  app.component(
    'RecentWorkoutWidget',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "search" */ '../components/team-members/recent-workout-widget.vue'
        ),
    ),
  )
  app.component(
    'RecentWellnessVideoWidget',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "search" */ '../components/team-members/recent-wellness-video-widget.vue'
        ),
    ),
  )
}

export default searchCodeSplit

<template>
  <button
    :disabled="submitting || disabled ? true : undefined"
    :type="type"
    class="btn -material"
    :class="[{ submitting: submitting }, classes]"
    :aria-label="submitting ? submittingLabel : label"
    @click.prevent="handleClick"
  >
    <i v-show="showIcon" :class="materialFontClasses" aria-hidden="true">{{ icon }}</i>
    <span v-if="submitting" class="btn-txt" :class="{ '-reduce': reduce }">{{
      submittingLabel
    }}</span>
    <span v-else class="btn-txt" :class="{ '-reduce': reduce }">{{ label }}</span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'IconButton',
  props: {
    submitting: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'button' | 'submit' | 'reset'>,
      default: 'button',
      validator: (value: string) => ['button', 'submit', 'reset'].includes(value),
    },
    submittingLabel: {
      type: String,
      default: 'Saving...',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '',
    },
    reduce: {
      type: Boolean,
      default: true,
    },
    rotateIcon: {
      type: Number,
      default: 0,
    },
    classes: {
      type: String,
      default: '',
    },

    // default to outlined font (not filled)
    iconsOutlined: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click-action'],

  computed: {
    materialFontClasses() {
      const fill = !this.iconsOutlined ? 'fill' : ''

      // font rotation
      let rotateClass = ''

      if (this.rotateIcon && this.rotateIcon === 90) {
        rotateClass = `-rotate-${this.rotateIcon}`
      }
      // end font rotation

      return `material-symbols-outlined ${fill} btn-ico ${rotateClass}`
    },
  },

  // instance methods
  methods: {
    handleClick(event: MouseEvent) {
      this.$emit('click-action', event)
    },
  },
})
</script>

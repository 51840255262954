import { defineAsyncComponent } from 'vue'
import type { App } from 'vue'

/**
 * USER PROFILE SPLIT
 **/
export const accountCodeSplit = (app: App) => {
  // profile card
  app.component(
    'ProfileHeader',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/profile/profile-header.vue'),
    ),
  )

  // plus free access mode
  app.component(
    'PlusFreeAccessCallout',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/notifications/plus-free-access-callout.vue'
        ),
    ),
  )

  app.component(
    'OptOutForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/opt-out-form.vue'),
    ),
  )

  app.component(
    'ProfileForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/profile-form.vue'),
    ),
  )

  app.component(
    'BlenderRegisterForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/blender-register-form.vue'),
    ),
  )

  app.component(
    'EditLoginForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/edit-login-form.vue'),
    ),
  )

  app.component(
    'LoginForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/login-form.vue'),
    ),
  )

  app.component(
    'ForgotPasswordForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/forgot-password-form.vue'),
    ),
  )

  app.component(
    'SocialLoginButtons',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/social-login-buttons.vue'),
    ),
  )

  app.component(
    'RedeemGiftCode',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/redeem-gift-code.vue'),
    ),
  )

  app.component(
    'DeleteAccountForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/delete-account-form.vue'),
    ),
  )

  app.component(
    'ExportDataForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/export-data-form.vue'),
    ),
  )

  /* CART */
  app.component(
    'GiftMessageForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/cart/gift-message-form.vue'),
    ),
  )

  app.component(
    'FreeGiftProduct',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/cart/free-gift-product.vue'),
    ),
  )

  app.component(
    'CartCouponCodeForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/cart/cart-coupon-code-form.vue'),
    ),
  )

  app.component(
    'CheckoutButton',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/cart/checkout-button.vue'),
    ),
  )

  app.component(
    'CheckoutPaymentForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/cart/checkout-payment-form.vue'),
    ),
  )

  app.component(
    'ResendGiftMessage',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/cart/resend-gift-message.vue'),
    ),
  )

  app.component(
    'ActivatePlusPassButton',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/buttons/activate-plus-pass-button.vue'
        ),
    ),
  )

  /* ACHIEVEMENT */
  // needed for fbadmin preview
  app.component(
    'Achievement',
    defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "user" */ '../components/achievements/achievement-card.vue'),
    ),
  )

  /* NOTIFICATION */
  // Only used for FB Admin preview
  app.component(
    'Notification',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/notifications/notification.vue'),
    ),
  )

  /* DASHBOARD */
  app.component(
    'DashboardCarousel',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/carousels/dashboard-carousel.vue'),
    ),
  )

  app.component(
    'PopularDiscussionsPanel',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/discussions/popular-discussions-panel.vue'
        ),
    ),
  )

  /* SUBSCRIPTIONS */
  app.component(
    'SubscriptionCancel',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/subscriptions/subscription-cancel.vue'
        ),
    ),
  )

  app.component(
    'SubscriptionResume',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/subscriptions/subscription-resume.vue'
        ),
    ),
  )

  app.component(
    'SubscriptionCheckoutForm',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/subscriptions/subscription-checkout-form.vue'
        ),
    ),
  )

  app.component(
    'SubscriptionUpdatePayment',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/subscriptions/subscription-update-payment.vue'
        ),
    ),
  )

  app.component(
    'SubscriptionAddWalletDiscount',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/subscriptions/subscription-add-wallet-discount.vue'
        ),
    ),
  )

  /* ORGANIZATION REGISTRATION */
  app.component(
    'OrganizationJoinForm',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/organizations/organization-join-form.vue'
        ),
    ),
  )

  app.component(
    'AddBlenderAuthForm',
    defineAsyncComponent(
      () => import(/* webpackChunkName: "user" */ '../components/users/add-blender-auth-form.vue'),
    ),
  )

  app.component(
    'SubscriptionOrganizationCancel',
    defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "user" */ '../components/subscriptions/subscription-organization-cancel.vue'
        ),
    ),
  )
}

export default accountCodeSplit

<template>
  <div
    class="form__group -has-checkbox"
    :class="{
      error: !!errorMessage,
    }"
  >
    <input
      :id="inputId"
      ref="actualInput"
      :name="name || inputId"
      :type="type"
      :checked="checked"
      :value="checkedValue"
      :aria-label="label ? label : undefined"
      class="large"
      v-bind="$attrs"
      v-on="validationListeners"
    />
    <span class="visual-checkbox-input" @click="handleVisualClick">
      <slot />
    </span>
  </div>
</template>

<script setup lang="ts">
/**
 * Checkbox (or Radio) input that will take a custom slot for the "visual checkbox
 * - can use v-model
 * - can use without v-model with initialValue and @change event
 * - must have a label (will be set as aria-label)
 */
import { nextTick, computed, ref } from 'vue'
import { useField } from 'vee-validate'
import type { FieldOptions } from 'vee-validate'

/** OPTIONS **/
defineOptions({
  // allow $attrs to flow through to the input element
  inheritAttrs: false,
})

/** PROPS **/
const props = withDefaults(
  defineProps<{
    inputId: string
    name?: string
    type: 'checkbox' | 'radio'
    label: string
    checkedValue?: string | number | boolean
    uncheckedValue?: string | number | boolean | undefined
    modelValue?: string | number | boolean | undefined
    // allow for v-model.number
    modelModifiers?: Record<string, (value: any) => any> | undefined
    // use v-model or initial-value, not both
    initialValue?: string | number | boolean | undefined
    rules?: string
    // validation label
    validateAs?: string
  }>(),
  {
    name: '',
    checkedValue: true,
    uncheckedValue: undefined,
    modelValue: undefined,
    initialValue: undefined,
    modelModifiers: undefined,
    validateAs: '',
    rules: '',
  },
)

/** EMITS **/
// defined, but let vee-validate handle it with syncVModel
const emit = defineEmits(['update:modelValue', 'change'])

/** COMPUTED **/
// couldn't pass props directly in, needed to be reactive
const reactiveRules = computed(() => props.rules)

/** STATE **/
const actualInput = ref<HTMLInputElement>()

// toggle v-model support
const veeFieldOpts = {
  type: props.type,
  checkedValue: props.checkedValue,
  uncheckedValue: props.uncheckedValue,
  label: props.validateAs || props.label || 'Field',
  syncVModel: false,
  validateOnValueUpdate: false,
  validateOnMount: false,
} as Partial<FieldOptions>

if (props.initialValue !== undefined) {
  // no v-model support
  veeFieldOpts.initialValue = props.initialValue
} else {
  // v-model support
  veeFieldOpts.syncVModel = true
}
// end options

const { checked, errorMessage, handleBlur, handleChange } = useField(
  // props.name for multiple, props.inputId for single
  () => props.name || props.inputId,
  reactiveRules,
  veeFieldOpts,
)

/** METHODS **/
const validationListeners = {
  // validate on blur
  blur: (e: Event) => {
    handleBlur(e, true)
  },
  // update value and validate on change
  change: (e: Event) => {
    handleChange(e, true)

    // change event to parent, but after validation
    nextTick(() => emit('change', e))
  },
}

function handleVisualClick() {
  // only emit if not disabled
  if (actualInput.value instanceof HTMLInputElement && !actualInput.value.disabled) {
    const value = actualInput.value.checked ? props.checkedValue : props.uncheckedValue

    handleChange(value, true)

    // change event to parent, but after validation
    nextTick(() => emit('change', value))
  }
}
</script>

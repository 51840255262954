<template>
  <div
    class="form__group"
    :class="[
      {
        error: !!errorMessage,
        '-has-label': showLabel && label,
      },
      containerClasses,
    ]"
  >
    <label v-if="showLabel" :for="inputId">
      {{ label }}
      <slot name="label" />
    </label>
    <div class="select-style">
      <select
        :id="inputId"
        :name="name || inputId"
        :value="value"
        :aria-label="!showLabel && label ? label : undefined"
        v-bind="$attrs"
        v-on="validationListeners"
      >
        <option v-for="option in options" :key="`${option.value}`" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>
    <input-error v-if="errorMessage && showErrorMsg" :error-message="errorMessage" />
    <slot name="note" />
  </div>
</template>

<script setup lang="ts">
/**
 * Select with options
 */
import { nextTick, computed } from 'vue'
import { useField } from 'vee-validate'
import InputError from '@/components/forms/input-error.vue'

/** OPTIONS **/
defineOptions({
  // allow $attrs to flow through to the input element
  inheritAttrs: false,
})

/** PROPS **/
type SelectOption = {
  value: string | number | boolean
  label: string
}

const props = withDefaults(
  defineProps<{
    containerClasses?: string
    inputId: string
    name?: string
    modelValue: string | number | boolean | undefined
    // allow for v-model.number
    modelModifiers?: Record<string, (value: any) => any> | undefined
    options: SelectOption[]
    label?: string
    showErrorMsg?: boolean
    showLabel?: boolean
    rules?: string
    // validation label
    validateAs?: string
  }>(),
  {
    containerClasses: '',
    name: '',
    modelModifiers: undefined,
    label: '',
    validateAs: '',
    showLabel: true,
    showErrorMsg: true,
    rules: '',
  },
)

/** EMITS **/
// defined, but let vee-validate handle it with syncVModel
const emit = defineEmits(['update:modelValue', 'change'])

/** COMPUTED **/
// couldn't pass props directly in, needed to be reactive
const reactiveRules = computed(() => props.rules)

/** STATE **/
const { value, errorMessage, handleBlur, handleChange } = useField(
  () => props.inputId,
  reactiveRules,
  {
    // allow vee-validate to sync with v-model
    syncVModel: true,
    // if do not allow syncVModel, must set initialValue
    // initialValue: props.modelValue,
    label: props.validateAs || props.label || 'Field',
    validateOnValueUpdate: false,
    validateOnMount: false,
  },
)

/** METHODS **/
const validationListeners = {
  // validate on blur
  blur: (e: Event) => {
    handleBlur(e, true)
  },
  // update value and validate on change
  change: (e: Event) => {
    handleChange(e, true)

    // change event to parent, but after validation
    nextTick(() => emit('change', e))
  },
}
</script>

<template>
  <div class="complete-customization">
    <!-- error state -->
    <callout v-if="error" type="error">
      <div>{{ error }}</div>
    </callout>

    <h2 class="heading">
      Custom Stats

      <icon-button
        icon="arrow_back_ios"
        classes="-ico-only -no-container help"
        label="Return to Menu"
        @click-action="closeAction"
      />
    </h2>
    <p>Customize the difficulty and duration of your workout.</p>
    <div class="extras-form">
      <vee-form v-slot="{ validate }" as="">
        <form
          class="standard-form group"
          method="post"
          novalidate="novalidate"
          @submit.prevent="handleSubmit(validate)"
        >
          <div class="-flex -between">
            <select-input
              v-model="formData.difficulty"
              input-id="difficulty"
              label="Today's Difficulty"
              rules="required"
              validate-as="Difficulty"
              :options="difficultyOptions"
              container-classes="-half"
            />

            <text-input
              v-model="formData.minutes"
              input-id="minutes"
              type="number"
              label="Duration"
              placeholder="Number of minutes. EX: 60"
              rules="required|numeric|min_value:1"
              validate-as="Minutes"
              min="1"
              step="1"
              container-classes="-half"
            />
          </div>

          <div class="save-extras -flex -center">
            <submit-button
              :submitting="loading"
              label="Save"
              submitting-label="Processing…"
              class="btn -dark"
              @submit-action="handleSubmit(validate)"
            />
          </div>
        </form>
      </vee-form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'pinia'
import { useCalendarStore } from '@/stores/calendar'
import { Form as VeeForm } from 'vee-validate'
import TextInput from '@/components/forms/text-input.vue'
import SelectInput from '@/components/forms/select-input.vue'
import SubmitButton from '@/components/buttons/submit-button.vue'
import IconButton from '@/components/buttons/icon-button.vue'
import Callout from '@/components/notifications/callout.vue'
import { fbApi } from '@/utils/http-api'

/**
 * Custom workout complete form for user created custom workouts.
 * Allows user to enter minutes.
 *
 * $emits the following actions
 * - stats-updated
 */
export default defineComponent({
  name: 'WorkoutTrackerForm',
  components: {
    VeeForm,
    SubmitButton,
    IconButton,
    TextInput,
    SelectInput,
    Callout,
  },
  props: {
    scheduledId: {
      type: Number,
      required: true,
    },
    minutes: {
      type: Number,
      required: true,
    },
    difficulty: {
      type: Number,
      required: true,
    },
  },
  emits: ['close-action', 'stats-updated'],
  data() {
    return {
      formData: {
        minutes: this.minutes,
        difficulty: this.difficulty,
      },
      difficultyOptions: [
        { value: '', label: 'Select One' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
      ],
      loading: false,
      error: '',
    }
  },

  methods: {
    ...mapActions(useCalendarStore, ['setTrackers']),

    closeAction() {
      this.$emit('close-action')
    },

    // allow parent component to handle submission
    async handleSubmit(validate) {
      // disable double submit
      if (this.loading) {
        return
      }

      this.loading = true
      this.error = ''

      try {
        // form validation
        const results = await validate()

        if (!results.valid) {
          this.loading = false
          return
        }
        // end validation

        // we have already toggled isCompleteData, so api url is backwards
        const res = await fbApi.post(`/calendar/schedule/${this.scheduledId}/trackers`, {
          skipErrorModal: true,
          json: {
            scheduled_id: this.scheduledId,
            minutes: this.formData.minutes,
            difficulty: this.formData.difficulty,
          },
        })

        // emit program complete stats update to parent
        if (res?.body?.data?.programComplete) {
          const { stats } = res.body.data.programComplete
          this.$emit('stats-updated', stats)
        }

        // update calendar store tracker data
        this.setTrackers(this.scheduledId, {
          minutes: this.formData.minutes,
          difficulty: this.formData.difficulty,
        })

        this.$nextTick(() => {
          this.closeAction()
        })
      } catch (e) {
        this.error = 'Error saving trackers, please try again'
      } finally {
        this.loading = false
      }
    },
  },
})
</script>

<template>
  <modal :show-header="false" modal-class="-calendar" body-class="" @close="handleCloseClick">
    <div :class="{ 'mini-calendar': !successScreen && !errorScreen }">
      <button class="close-mini-calendar" @click="handleCloseClick">
        <span class="iconfont-menu-close" />
      </button>
      <div v-if="!successScreen && !errorScreen" class="calendar-holder">
        <div class="calendar-header">
          <div class="calendar-header">
            <div class="calendar-header-nav">
              <a href="#" class="no-hover" @click.prevent="decreaseMonth"
                ><span class="iconfont-arrow-back"
              /></a>
              <h5 class="heading -medium">{{ currentMonthText }}, {{ year }}</h5>
              <a
                href="#"
                class="no-hover"
                :class="{ disabled: !allowNextMonth }"
                @click.prevent="increaseMonth"
                ><span class="iconfont-arrow-forward"
              /></a>
            </div>
          </div>
        </div>
        <div class="calendar-block">
          <div class="calendar-days-header">
            <!-- eslint-disable-next-line -->
            <div class="calendar-day" v-for="n in daysOfWeek">
              <span class="calendar-day-label">{{ n }}</span>
            </div>
          </div>
          <div class="calendar">
            <div class="calendar-row">
              <!-- eslint-disable-next-line -->
              <div class="calendar-day disabled" v-for="count in previousMonthDays">
                <div class="calendar-day-wrap">
                  <div class="calendar-day-contents">
                    <div class="calendar-date">
                      <span class="calendar-date-label" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="day in daysOfMonth"
                :key="day.dayNumber"
                :class="{ 'has-activity': day.hasWorkout, today: isToday(day.dayNumber) }"
                class="calendar-day"
              >
                <div class="calendar-day-wrap" @click.prevent="handleAddToCalendar(day.dayNumber)">
                  <div class="calendar-day-contents">
                    <div class="calendar-date">
                      <span class="calendar-date-label">{{ day.dayNumber }}</span>
                    </div>
                    <span class="calendar-label">Add</span>
                  </div>
                </div>
              </div>
              <!-- eslint-disable-next-line -->
              <div class="calendar-day disabled" v-for="count in nextMonthDays">
                <div class="calendar-day-wrap">
                  <div class="calendar-day-contents">
                    <div class="calendar-date">
                      <span class="calendar-date-label" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="successScreen">
        <header class="modal__header">
          <h2 class="heading -medium">Success!</h2>
        </header>
        <callout v-if="passSubExpiresBeforeEndDate" :closable="false" type="error">
          <div class="small">
            Your FB Plus access will expire before the last day of the program. Purchase additional
            <a class="link-reversed" href="/store">FB Plus Passes</a> for continued access.
          </div>
        </callout>
        <div class="modal__body">
          <!-- eslint-disable vue/no-v-html -->
          <p v-html="displaySuccessMessage" />
          <ul class="modal__link-list">
            <li v-if="entityType === 'plan' && entityCategory !== 'routine'">
              <a :href="`/my/programs/${entityId}#guide`" @click="handleCloseClick">
                View Program Guide
              </a>
            </li>
            <li v-if="entityType === 'plan' && entityCategory === 'routine'">
              <a :href="`/my/routines/${entityId}#scheduled-instances`" @click="handleCloseClick">
                View Scheduled
              </a>
            </li>
            <li v-if="entityType === 'plan'">
              <a :href="`/my/calendar/day/${yyyymmdd}`">View First Day</a>
            </li>
            <li v-if="entityType !== 'plan'">
              <button type="button" @click="successScreen = false">
                Schedule Again On Another Day
              </button>
            </li>
            <li><a :href="`/my/calendar/${month}/${year}`">View Your Calendar</a></li>
            <li v-if="entityType !== 'plan'">
              <a :href="`/my/calendar/day/${yyyymmdd}`">View Day on Calendar</a>
            </li>
          </ul>
        </div>
        <footer class="modal__footer">
          <button type="button" @click="handleCloseClick">Close Window</button>
        </footer>
      </div>

      <div v-else-if="errorScreen">
        <header class="modal__header">
          <h2 class="heading -medium">Error!</h2>
          <p>Sorry, we could not add this {{ displayType }} to your calendar.</p>
        </header>
        <div class="modal__body">
          <callout :closable="false" type="error">
            <div>{{ errorMessage }}</div>
          </callout>
          <ul class="modal__link-list">
            <li>
              <button type="button" @click="errorScreen = false">Try Another Day</button>
            </li>
            <li><a :href="`/my/calendar/${month}/${year}`">View Your Calendar</a></li>
          </ul>
        </div>
        <footer class="modal__footer">
          <button type="button" @click="handleCloseClick">Close Window</button>
        </footer>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { useCalendarStore } from '@/stores/calendar'
import { pad } from '@/utils/helpers'
import Modal from './modal.vue'
import Callout from '../notifications/callout.vue'
import { fbApi } from '@/utils/http-api'

const monthsText = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const daysOfWeekText = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export default {
  name: 'ModalCalendar',
  components: { Modal, Callout },
  props: {
    entityId: {
      required: true,
      type: Number,
    },
    entityType: {
      default: 'workout',
      type: String,
      validator: (val) =>
        ['plan', 'workout', 'custom_workout', 'article', 'wellness_video'].includes(val),
    },
    // only used for plans and to show different content / links on success
    // for routines
    // intended to be value from plan type taxonomy
    entityCategory: {
      default: '',
      validator: (val) => ['', 'program', 'challenge', 'routine'].includes(val),
    },
    entityTitle: {
      // currently only used by workout and custom_workout on success
      default: '',
      type: String,
    },
  },
  emits: ['close', 'schedule-success'],
  data() {
    return {
      canAddToCalendar: true,
      daySelected: -1,
      errorMessage: '',
      errorScreen: false,
      successMessage: null,
      passSubExpiresBeforeEndDate: false,
      successScreen: false,
      // using local today
      today: new Date(),
      // month is 1-12 based (not 0-11 like in wacko JS world)
      month: 1,
      year: 2018,
      daysOfMonth: null,
      // for each year-month store days in month and if user has workout on that day
      months: {},
    }
  },

  computed: {
    ...mapState(useUserAuthStore, ['user']),
    ...mapState(useCalendarStore, ['scheduleDate']),

    allowNextMonth() {
      // up to 12 months in future today
      const yearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      const selectedMonth = new Date(this.year, this.month, 0)
      return yearFromNow > selectedMonth
    },
    selectedDayOfWeek() {
      return daysOfWeekText[new Date(this.year, this.month - 1, this.daySelected).getDay()]
    },
    currentMonthText() {
      return monthsText[this.month - 1]
    },
    daysInMonth() {
      // month param is 1-12, while js is 0-11, so this is really next month
      return new Date(this.year, this.month, 0).getDate()
    },

    // YYYY-MM-DD format, only works with daySelected
    yyyymmdd() {
      return `${this.year}-${pad(this.month)}-${pad(this.daySelected)}`
    },

    daysOfWeek() {
      if (this.user.startMonday) {
        return ['M', 'T', 'W', 'T', 'F', 'S', 'S']
      } else {
        return ['S', 'M', 'T', 'W', 'T', 'F', 'S']
      }
    },

    // day in previous month to set first day of month in correct column, 0 = Sun 7 = Sat
    // startMonday is 0 or 1 and specifies if calendar starts on S or M
    previousMonthDays() {
      const currMonthDayIndex = new Date(this.year, this.month - 1, 1).getDay()

      // if user month starts on monday, but the 1st is on a sunday, account
      // for adding additional days to pad (as subtracting this.user.startMonday will be negative)
      if (this.user.startMonday && this.user.startMonday > currMonthDayIndex) {
        const maxDay = 7 // day index is 0-6, so with startMonday being 1, should return 6 days to be filled
        return maxDay - this.user.startMonday
      }

      // all other months should work with starting on monday
      return currMonthDayIndex - this.user.startMonday
    },

    // # of blank days after end of month on calendar
    nextMonthDays() {
      const totalBlocks = this.daysInMonth + this.previousMonthDays
      const remainder = 7 - (totalBlocks % 7)
      return remainder === 7 ? 0 : remainder
    },

    // used for error message
    displayType() {
      switch (this.entityType) {
        case 'plan':
          return this.entityCategory
        case 'custom_workout':
          return 'custom workout'
        case 'wellness_video':
          return 'wellness video'

        // no title for article error messages, should say "this"
        case 'article':
          return ''
        default:
          return 'workout'
      }
    },

    // toggle between plan specific message and default workout / custom workout message
    displaySuccessMessage() {
      return (
        this.successMessage ||
        `You’ve scheduled <strong>${this.entityTitle}</strong> on ${this.selectedDayOfWeek}, ${this.currentMonthText} ${this.daySelected}, ${this.year}.`
      )
    },
  },

  // lifecycle methods
  beforeMount() {
    // set the default month and year of calendar based on today (1-12 based, not 0-11 based)
    this.month = this.today.getMonth() + 1
    this.year = this.today.getFullYear()
    this.changeMonth()
  },

  methods: {
    ...mapActions(useCalendarStore, [
      'addScheduledAndPromotedUnscheduled',
      'updateScheduledPlanStats',
    ]),

    // changed to async await
    async changeMonth() {
      // calc yearMonth as const here so api callback can use even if this.month has changed
      // (user clicking through months quicker than api responds)
      const yearMonth = `${this.year}-${pad(this.month)}`

      // if this month has already been loaded
      if (yearMonth in this.months) {
        this.daysOfMonth = this.months[yearMonth]
        return
      }

      this.months[yearMonth] = this.createCalendarMonth()
      this.daysOfMonth = this.months[yearMonth]

      // get busy days to mark with teal dot
      try {
        const res = await fbApi.get(
          `/calendar/scheduled-days-in-month?month=${this.month}&year=${this.year}`,
        )

        const dayNumbers = res.body.data.map((date) => {
          const parts = date.split('-')
          return parseInt(parts[2])
        })

        dayNumbers.forEach((n) => {
          this.setDayHasWorkout(yearMonth, n)
        })
      } catch (e) {
        // if some sort of error, close modal
        // the called function will have displayed another modal (login or general error)
        this.handleCloseClick()
      }
    },

    increaseMonth() {
      if (this.month === 12) {
        this.month = 1
        this.year++
      } else {
        this.month++
      }

      this.changeMonth()
    },

    decreaseMonth() {
      if (this.month === 1) {
        this.month = 12
        this.year--
      } else {
        this.month--
      }

      this.changeMonth()
    },

    // day parameter is day of month starting at 1
    isToday(day) {
      return (
        this.today.getDate() === day &&
        this.today.getMonth() === this.month - 1 &&
        this.today.getFullYear() === this.year
      )
    },

    async handleAddToCalendar(day) {
      this.daySelected = day

      if (!this.canAddToCalendar) {
        return
      }

      // Disable calendar click
      this.canAddToCalendar = false

      // independent schedulable items
      let apiPath = 'schedule'
      let postData = {
        scheduleDate: this.yyyymmdd,
        schedulableId: this.entityId,
        schedulableType: this.entityType,
      }

      // program item, separate API endpoint
      if (this.entityType === 'plan') {
        apiPath = 'programs'

        postData = {
          planId: this.entityId,
          scheduleDate: this.yyyymmdd,
        }
      }

      try {
        // test validation errors
        const res = await fbApi.post(`/calendar/${apiPath}`, {
          json: postData,
          skipErrorModal: true,
        })

        const { data, status, message } = res.body

        // enable calendar click
        this.canAddToCalendar = true

        if (status !== 'error') {
          this.successScreen = true

          // emit success event
          this.$emit('schedule-success')

          // custom message just for program plans
          if (this.entityType === 'plan') {
            // update scheduledPlans state with updated stats for the newly scheduled plan
            this.updateScheduledPlanStats(data.scheduledPlanStats)
            this.successMessage = message
          }

          // show plus pass expiration message
          if (data?.passSubExpiresBeforeEndDate) {
            this.passSubExpiresBeforeEndDate = data.passSubExpiresBeforeEndDate
          }

          // probably should be called only for workouts, not plans, but as currently used not an issue
          this.setDayHasWorkout(`${this.year}-${pad(this.month)}`, day)

          this.updateCalendarStore(data)
        } else {
          // "expected" error like already scheduled for that day
          // note: will also show some "unexpected" errors like "Workout ID is invalid"
          this.errorScreen = true
          this.errorMessage = message
        }
      } catch (e) {
        // enable calendar click
        this.canAddToCalendar = true

        // default message
        let message = 'Please refresh the page and try again.'

        // message from error response, but do not show auth messages
        if (
          e &&
          e.body &&
          e.body.message &&
          e.body.message !== 'Unauthenticated' &&
          e.body.message !== 'Unauthorized'
        ) {
          message = e.body.message
        }

        // "expected" error like already scheduled for that day
        // note: will also show some "unexpected" errors like "Workout ID is invalid"
        this.errorScreen = true
        this.errorMessage = message
      }
    },

    createCalendarMonth() {
      const days = []

      for (let i = 0; i < this.daysInMonth; i++) {
        days[i] = {
          dayNumber: i + 1,
          hasWorkout: false,
        }
      }
      return days
    },

    setDayHasWorkout(yearMonth, dayNumber) {
      const selectedDay = this.months[yearMonth].filter((d) => {
        return d.dayNumber === dayNumber
      })
      selectedDay[0].hasWorkout = true
    },

    // only update calendar pinia store if calendar.scheduleDate is set
    // and exactly equal to the date the user selected
    updateCalendarStore(scheduleItem) {
      if (!this.scheduleDate) {
        return
      }

      // no action if plans currently, only independent schedulable items
      if (this.entityType === 'plan') {
        return
      }

      if (this.scheduleDate === this.yyyymmdd) {
        this.addScheduledAndPromotedUnscheduled(scheduleItem)
      }
    },

    handleCloseClick() {
      this.$emit('close')
    },
  },
}
</script>

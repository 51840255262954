<template>
  <div class="comment-actions">
    <div class="react">
      <icon-button
        icon="add_reaction"
        classes="-ico-only -no-container"
        aria-label="Add Like Reaction"
        :aria-expanded="likeReactFormOpen.toString()"
        :aria-controls="`like-react-form-${commentId}`"
        @click-action="$emit('react-form')"
      />
    </div>
    <div class="reply">
      <icon-button
        icon="reply"
        classes="-ico-only -no-container"
        aria-label="Reply"
        :aria-expanded="commentReplyFormOpen.toString()"
        :aria-controls="`comment-form-${commentId}`"
        @click-action="$emit('comment-reply-form', { parentId })"
      />
    </div>
    <div v-if="!ownedByUser" class="flag" :class="{ '-flagged': isFlagged }">
      <icon-button
        icon="flag"
        classes="-ico-only -no-container"
        aria-label="Flag"
        :icons-outlined="!isFlagged"
        @click-action="toggleFlagModal"
      />
    </div>

    <comment-actions-menu
      v-if="ownedByUser"
      :comment-id="commentId"
      @edit-comment="$emit('edit-comment')"
      @delete-comment="$emit('delete-comment')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useUserAuthStore } from '@/stores/user-auth'
import { useModalsStore } from '@/stores/modals'
import IconButton from '@/components/buttons/icon-button.vue'
import CommentActionsMenu from './comment-actions-menu.vue'

export default defineComponent({
  name: 'CommentActionsBar',
  components: {
    IconButton,
    CommentActionsMenu,
  },
  props: {
    commentId: {
      type: Number,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
    ownedByUser: {
      type: Boolean,
      required: true,
    },
    likedByUser: {
      type: Boolean,
      default: false,
    },
    isFlagged: {
      type: Boolean,
      default: false,
    },
    likeReactFormOpen: {
      type: Boolean,
      default: false,
    },
    commentReplyFormOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['react-form', 'comment-reply-form', 'edit-comment', 'delete-comment', 'flag-modal'],
  data() {
    return {}
  },

  computed: {
    ...mapState(useUserAuthStore, ['user']),
  },

  methods: {
    ...mapActions(useModalsStore, ['showLoginModal']),

    toggleFlagModal() {
      if (!this.user.isLoggedIn) {
        this.showLoginModal()
        return
      }

      this.$emit('flag-modal')
    },
  },
})
</script>

<template>
  <div ref="outsideClick" :class="classes" :style="{ display: display }">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * Helper to handle outside clicks
 *
 * Emits
 * - outside-click-action
 */
export default defineComponent({
  name: 'OutsideClick',
  props: {
    // set display for parent tag
    display: {
      type: String,
      default: 'block',
      validator: (val: string) =>
        ['block', 'flex', 'inline', 'inline-block', 'contents'].includes(val),
    },
    classes: {
      type: String,
      default: '',
    },

    // outside-click-action will not emit if disabled
    disabled: {
      type: Boolean,
      default: false,
    },

    // extra query selector (not ref) can be passed in (just one) to also omit emitting
    // event if event.target is contained within the selector
    // use case is "close on all outside clicks EXCEPT self AND one other element)
    ignoreQuerySelector: {
      type: String,
      default: '',
    },
  },
  emits: ['outside-click-action'],

  // component state
  data() {
    return {}
  },

  // lifecycle
  mounted() {
    // add outside click event listener
    document.addEventListener('touchstart', this.handleClickOutside)
    document.addEventListener('mousedown', this.handleClickOutside)
    // document.addEventListener('keypress', this.handleClickOutside);
  },

  beforeUnmount() {
    // remove outside click event listener
    document.removeEventListener('touchstart', this.handleClickOutside)
    document.removeEventListener('mousedown', this.handleClickOutside)
    // document.removeEventListener('keypress', this.handleClickOutside);
  },

  // component methods
  methods: {
    /**
     * older iOS was not listening to mousedown
     * @see https://github.com/airbnb/react-outside-click-handler/issues/11
     * @see https://stackoverflow.com/questions/13655919/how-to-bind-both-mousedown-and-touchstart-but-not-respond-to-both-android-jqu
     */
    handleClickOutside(event: Event) {
      if (this.disabled) {
        return
      }

      if (typeof window.ontouchstart != 'undefined' && event.type === 'mousedown') {
        return
      }

      const outsideClick = this.$refs.outsideClick as HTMLElement

      let secondaryElem = this.ignoreQuerySelector
        ? document.querySelector(this.ignoreQuerySelector)
        : null

      // only emit if not within the outside click ref
      if (outsideClick && event.target instanceof Node && !outsideClick.contains(event.target)) {
        // if no secondary element check, then just emit
        // if secondary element AND event target is not contained within it, also emit
        if (!secondaryElem || (secondaryElem && !secondaryElem.contains(event.target))) {
          this.$emit('outside-click-action')
        }
        // end secondary element check
      }
      // end outside click ref check
    },
  },
})
</script>

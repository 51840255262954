<template>
  <div>
    <ul class="complete-actions-list">
      <!-- tracker buttons -->
      <li v-if="trackersButton" class="content-action">
        <button class="btn" @click="$emit('menu-action', 'trackers')">
          <i class="material-symbols-outlined" aria-hidden="true">fitness_center</i>
          Customize Your Workout
        </button>
      </li>

      <!-- note button -->
      <li v-if="noteButton" class="content-action">
        <button class="btn" @click="$emit('menu-action', 'note')">
          <i class="material-symbols-outlined" aria-hidden="true">edit_note</i>
          Add Note
        </button>
      </li>

      <!-- tag button -->
      <li v-if="tagButton" class="content-action">
        <button class="btn" @click="$emit('menu-action', 'tag')">
          <i class="material-symbols-outlined" aria-hidden="true">label</i>
          Add Tags
        </button>
      </li>

      <li v-if="calendarDayButton && scheduleDate" class="content-action -secondary-action">
        <a class="btn" :href="`/my/calendar/day/${scheduleDate}`">
          <i class="material-symbols-outlined" aria-hidden="true">today</i>
          View Your Calendar Day
        </a>
      </li>
      <!-- custom buttons -->
      <slot />
    </ul>

    <p v-if="effectsButton" class="complete-actions-settings">
      If you don't want to see the complete pop-up <br />
      <a class="link" href="/my/account#complete_effects"> Manage Complete Effects </a>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'CompleteActionsMenu',
  props: {
    scheduleDate: {
      type: String as PropType<string | null>,
      default: '',
    },
    entityType: {
      type: String,
      default: 'workout',
      validator: (val: string) =>
        ['workout', 'custom_workout', 'plan', 'article', 'wellness_video'].includes(val),
    },
    trackersButton: {
      type: Boolean,
      default: false,
    },
    noteButton: {
      type: Boolean,
      default: false,
    },
    tagButton: {
      type: Boolean,
      default: false,
    },
    calendarDayButton: {
      type: Boolean,
      default: false,
    },
    effectsButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['menu-action'],

  data() {
    return {}
  },
})
</script>

<template>
  <modal :heading="header" :show-footer="true" @close="emit('close')">
    <p>
      {{ content }}
      If the issue continues to occur, please
      <a href="/page/contact-us">contact us</a> or email
      <a href="mailto:service@fitnessblender.com">service@fitnessblender.com</a>
    </p>
  </modal>
</template>

<script setup>
import { computed } from 'vue'
import Modal from './modal.vue'

/** PROPS **/
const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (val) => ['generic', 'throttled'].includes(val),
  },
})

/** EMITS **/
const emit = defineEmits(['close'])

/** COMPUTED **/
const header = computed(() => {
  if (props.type === 'throttled') {
    return 'Too many requests'
  }

  return 'Uh oh, something went wrong.'
})

const content = computed(() => {
  if (props.type === 'throttled') {
    return 'Please wait a minute and then try again.'
  }

  return 'Please try reloading the page.'
})
</script>
